import React, { useState } from 'react';
import LeftSidebar from '../LeftSidebar/LeftSidebar';

import Navbar from '../MainContent/Navbar';
import '../../../Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import ListOfRuns from '../MainContent/ListOfRuns';
import Calendar from '../PopUpUtils/Calender';
import RunApplication from '../PopUpUtils/Run_application';
import MinimizeLeftBar from '../LeftSidebar/MinimizeLeftBar';
import ChoosePreference from '../PopUpUtils/ChoosePreference';

const FlowLevelDashboard = ({ onLogout, onRefreshToken, minimize, setMinimize }) => {
    const [showCalender, setShowCalender] = useState(false);
    const [runApplication, setRunApplication] = useState(false);
    const [openForm,setOpenForm]=useState(false);

    const toggleRunButton = () => {
        setRunApplication(prevState => !prevState);
    }

    const togglecalender = () => {
        setShowCalender(prevState => !prevState)
    }
    return (
        <div className='appDashboard'>
            <div className='sidebar'>
                {minimize ? <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}
            </div>
            <div className='content'>
                <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                    <Navbar />
                </div>
                <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
                    <ListOfRuns togglecalender={togglecalender} toggleRunButton={toggleRunButton} onRefreshToken={onRefreshToken} setOpenForm={setOpenForm}/>
                </div>
                {showCalender ? <Calendar showCalender={showCalender} setShowCalender={setShowCalender} /> : ''}
                {runApplication ? <RunApplication runApplication={runApplication} setRunApplication={setRunApplication} /> : ''}
                {openForm ? <ChoosePreference setOpenForm={setOpenForm} /> : ""}
            </div>
        </div>
    );
}

export default FlowLevelDashboard;