import React, { useState } from 'react';
import ApplicationLeftBar from './ApplicationLeftBar';
import ApplicationNavbar from './ApplicatonNavbar';
import '../../../Styles/DeveloperMode/AppDashboard/AddApplication.css';
import VerticalStepper from '../../CisoMode/VerticalStepper';
import ApplicationMinimizeLeftBar from '../LeftSidebar/ApplicationMinimizeLeftBar';


const AddApplication = ({ onLogout, onRefreshToken, accessToken,allAppNames,minimize,setMinimize }) => {
    const [openForm, setOpenForm] = useState(false);

    const handleAddApp = () => {
        setOpenForm(true);
    }
    return (
        <div>
            {!accessToken ? <h1>This page is not found</h1>:
                (<div className='appDashboard'>
                    <div className='sidebar'>
                    {minimize ?  <ApplicationLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <ApplicationMinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}

                    </div>
                    <div className='content'>
                    <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                            <ApplicationNavbar />
                        </div>

                        <div>
                        <div className={`mainContentnew ${!minimize ? 'minimized' : ''}`}>
                            <div className={`applevel21 ${!minimize ? 'img' : ''}`}>
                                <div id='addnewApp_d11'>To see Organisational Security Landscape please add Applications</div>
                                <div className='addnewApp1'>
                                    <button className='add_app_btn1' onClick={handleAddApp}>Add New Application</button>
                                </div>

                            </div>
                            </div>
                            <div>
                                {openForm && <VerticalStepper setOpenForm={setOpenForm} allAppNames={allAppNames} />}
                            </div>
                        </div>
                    </div>
                </div>)
            }
        </div>
    );
};

export default AddApplication;







