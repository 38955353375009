// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cweMatric{
    display: flex;
    justify-content: space-between;
    font-family:'DM Sans' ;
    font-weight: 500;
    font-size: 20px;
    padding-top: 5px;
    line-height: 26.04px;
    letter-spacing: -0.2px;
    /* border: 1px solid black; */

}

.cweMatric1{
    display: flex;
    justify-content: space-between;
    font-family:'DM Sans' ;
    font-weight: 500;
    font-size: 20px;
    padding-top: 5px;
    padding-left: 10px;
    line-height: 26.04px;
    letter-spacing: -0.2px; 
    
}
.cweMatriApp_CWE_matricc1{
    padding: 5px;
}

@media(min-width: 1536px){
    .cweMatric{
          width: 580px;

    }

 }


 @media(min-width: 1536px){
    .cweMatric1{
          width: 580px;

    }

 }`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/Graph_content/CWEmatrix.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,6BAA6B;;AAEjC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;IACpB,sBAAsB;;AAE1B;AACA;IACI,YAAY;AAChB;;AAEA;IACI;UACM,YAAY;;IAElB;;CAEH;;;CAGA;IACG;UACM,YAAY;;IAElB;;CAEH","sourcesContent":[".cweMatric{\n    display: flex;\n    justify-content: space-between;\n    font-family:'DM Sans' ;\n    font-weight: 500;\n    font-size: 20px;\n    padding-top: 5px;\n    line-height: 26.04px;\n    letter-spacing: -0.2px;\n    /* border: 1px solid black; */\n\n}\n\n.cweMatric1{\n    display: flex;\n    justify-content: space-between;\n    font-family:'DM Sans' ;\n    font-weight: 500;\n    font-size: 20px;\n    padding-top: 5px;\n    padding-left: 10px;\n    line-height: 26.04px;\n    letter-spacing: -0.2px; \n    \n}\n.cweMatriApp_CWE_matricc1{\n    padding: 5px;\n}\n\n@media(min-width: 1536px){\n    .cweMatric{\n          width: 580px;\n\n    }\n\n }\n\n\n @media(min-width: 1536px){\n    .cweMatric1{\n          width: 580px;\n\n    }\n\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
