import '../../../Styles/DeveloperMode/FlowDB/ListOfRuns.css';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import sortingSvg from '../../../Images/Sorting (1).svg';

import '@fontsource/dm-sans'
import Top10CWE from '../Graph_content/Top10CWE';
import CVSS_Trends from '../Graph_content/CVSS_Trends';
import frame from '../../../Images/frame.svg'
import refresh from '../../../Images/refresh.svg'
import calender from '../../../Images/calendar_clock.svg'
import vector from '../../../Images/Vector.svg';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
import PaginationDots from '../../Pagination/PaginationDots';
import dataNotFound from '../../../Images/notDatafound.svg';
import bell_icon from '../../../Images/bell_icon.svg'


const ListOfRuns = ({ togglecalender, toggleRunButton, onRefreshToken, setOpenForm }) => {
    const { appId } = useParams();
    const [activeItem, setActiveItem] = useState('Total Threats');
    const navigate = useNavigate();
    const [runs, setRuns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // const [selectedRunId, setSelectedRunId] = useState(null);
    const [ottInfoMap, setOttInfoMap] = useState(new Map());
    const { flowId, setFlowId } = useWorkflowDetails();
    const { setRunId } = useWorkflowDetails();
    const { flowName, setFlowName } = useWorkflowDetails();
    const { setRunName } = useWorkflowDetails();
    const [viewAll, setViewAll] = useState(false)
    const { app_id, setApp_id } = useWorkflowDetails();
    const [idsCount, setIdsCount] = useState()
    const [token, setToken] = useState()
    const [threatCount, setThreatCount] = useState()
    const runsPerPage = 3;
    const itemsPerPage = 3;
    const [displayItems, setDisplayItems] = useState(false);
    const [displayItems1, setDisplayItems1] = useState(false);
    const apiUrl = process.env.REACT_APP_URL;
    const [accessTokenUpdated, setAccessTokenUpdated] = useState('');


    useEffect(() => {
        const storedApp_id = localStorage.getItem('app_id');
        if (storedApp_id) {
            setApp_id(storedApp_id);
        }
    }, [setApp_id]);

    useEffect(() => {
        const storedFlowId = localStorage.getItem('flowId');
        if (storedFlowId) {
            setFlowId(storedFlowId);
        }
    }, [setFlowId]);


    useEffect(() => {
        if (accessTokenUpdated) {
            flowLevelDashboard();
        }
    }, [accessTokenUpdated]);

    useEffect(() => {
        flowLevelDashboard();
    }, [app_id, flowId]);

    if (!app_id && !flowId) {
        navigate('/')
    }
    const flowLevelDashboard = async () => {

        try {
            const localToken = localStorage.getItem('accessToken');
            const url = apiUrl + "/resources/apps/" + app_id + "/flows/" + flowId + "/runs"

            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            });

            if (response.status === 400) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                }
                else if (!app_id && !flowId) {
                    navigate('/');
                }
                else {
                    navigate('/');
                }
            }else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            }
            const data = await response.json();

            setRuns(data);


            //data sort according to creation time
            const latestRun = data.reduce((latest, current) => {
                return (latest.creation_time > current.creation_time) ? latest : current;
            });


            setThreatCount(latestRun.threat_count);
            setIdsCount(latestRun.id_count);
            setToken(latestRun.token_count)

            if (data && data.length > 0) {
                const hasRunname = data.some(workflow => !!workflow.run_name);

                setDisplayItems1(hasRunname);
            }
        }
        catch (error) {
            console.error("error in fetching workflows level dashboard", error)
        }
    };


    const localToken = localStorage.getItem('accessToken');


    useEffect(() => {
        fetchFlow();
    }, [app_id]);

    useEffect(() => {
        if (accessTokenUpdated) {
            fetchFlow();
        }
    }, [accessTokenUpdated])

    const fetchFlow = async () => {
        try {
            const response = await fetch(`${apiUrl}/resources/apps/${app_id}/flows`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            });

            if (response.status === 400) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                }
                else if (response.status === 404) {
                    navigate('/');
                }
                else {
                    navigate('/');
                }
            }

            const data = await response.json();
            const flowData = data.find(flow => flow._id === flowId);

            if (flowData) {
                const ottInfo = aggregateOTTInfo(flowData.OWASP_top_10_info);

                setOttInfoMap(ottInfo);
                setFlowName(flowData.flow_name)
            }
            if (data && data.length > 0) {
                const hasFlowName = data.some(workflow => !!workflow._id);
                setDisplayItems(hasFlowName);
            }
        }
        catch (error) {
            console.error("error in fetching workflows of application", error)
        }
    }


    const aggregateOTTInfo = (ottInfo) => {
        let ottInfoMap = new Map();

        Object.entries(ottInfo).forEach(([ottKey, ottValue]) => {
            ottInfoMap.set(ottKey, ottValue);
        });
        return ottInfoMap;
    };


    useEffect(() => {
        const storedFlowName = localStorage.getItem('flowName');

        if (storedFlowName) {
            setFlowName(storedFlowName);
        }
    }, [setFlowName]);


    const handleRunClick = (runId, runName, flowId) => {
        setFlowId(flowId)
        // setSelectedRunId(runId);
        setRunId(runId);
        setRunName(runName);

        navigate(`/application/${appId}/${flowName}/${runName}`);
    };



    const handleItemClick = (item) => {
        setActiveItem(item);
    }

    const handleViewAll = () => {
        setViewAll(true);
    }
    const addNewRun = () => {
        setOpenForm(true);
    }

    const renderRunsNames = () => {
        if (!runs || runs.length === 0) {
            return <div>Loading...</div>; // Or any placeholder you prefer
        }
        const visibleRuns = viewAll ? runs : runs && runs.length > 0 && runs.slice(0, runsPerPage);
        return visibleRuns && visibleRuns.length > 0 && visibleRuns.map((run, index) => {
            // Formatting the creation_time to the desired format
            const formattedTime = new Date(run.creation_time).toLocaleString('en-US', {
                weekday:'short',
                year:'numeric',
                month:'short',
                day:'numeric',
                hour:'numeric',
                minute:'numeric'
            });
            let part1 = run.run_name;
            let part2 = ' ';
            const hyphenCount = (run.run_name.match(/-/g) || []).length;
            // Check if the run_name contains '-'
            if (hyphenCount === 2) {
                // Split into two parts: everything before the last '-' and everything after
                const lastHyphenIndex = run.run_name.lastIndexOf('-');
                part1 = run.run_name.substring(0, lastHyphenIndex);
                part2 = run.run_name.substring(lastHyphenIndex + 1);
            }
        return (
            <li key={run._id} onClick={() => handleRunClick(run._id, run.run_name, run.flow_id)} style={{ cursor: 'pointer' }} >
                <div className='runNames'>
                        <div className='column'>
                            <div>{part1}</div>
                            <pre>Manual / New filter added</pre>
                        </div>
                        <div className='column'>{part2}</div>
                        <div className='column'>
                           
                            <div id='timestamp'>{formattedTime} </div>{/* Displaying the formatted time */}
                            {/*  <span>Ran: creationTime({run.creation_time}) </span> */}
                            {/* <div className='cvss-score' >
                                <span id='cvss-score'>CVSS score</span>
                            </div> */}
                        </div>
                        <div>
                            <img id='vector' src={vector} alt='sorting' className='key' />
                        </div>
                    </div>
               </li>
            );
        });
    };

    const fetchData = [
        { id: 1, text1: "Access Control", text2: "Ensure proper mechanisms are in place to prevent unauthorized access." },
        { id: 2, text1: "Data Encryption", text2: "Encrypt sensitive data both at rest and in transit for enhanced security." },
        { id: 3, text1: "Dependency Management", text2: "Regularly update and patch third-party libraries to avoid vulnerabilities." },
        { id: 4, text1: "Input Validation", text2: "Implement strong validation to prevent injection attacks and other threats." },
        { id: 5, text1: "Secure Design", text2: "Apply secure design principles to avoid weaknesses in the application architecture." },
        { id: 6, text1: "Security Configurations", text2: "Ensure security settings are applied consistently across all environments." },
        { id: 7, text1: "Security Testing", text2: "Perform regular security tests, including static and dynamic analysis, to identify risks." },
        { id: 8, text1: "Logging and Monitoring", text2: "Implement proper logging and monitoring to detect and respond to security incidents." },
        { id: 9, text1: "Security Awareness", text2: "Educate your team on security best practices, including awareness of common risks." },
    ];
    useEffect(() => {
        setCurrentPage(fetchData.slice(0, itemsPerPage));
    }, []);

    const handlePageChange = (page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setCurrentPage(fetchData.slice(startIndex, endIndex));
    };

    const handleListOfRuns = async () => {
        try {
            await flowLevelDashboard();

            setTimeout(() => {
                setDisplayItems1(false);
            }, 1000);
        }
        catch (error) {
            console.error("Error in capturing the list of runs", error);
        }
    };


    return (
        <div className={`grid-con`}>
            <div className={`overview-bar`}>
                <div className={`overview-bar1`}>Overview</div>
                <div className={`last-scan`}>Previous Scan _ hours ago</div>
                {/* <div className='updateButton' >
                    <div id="updateRefresh">
                        <button type='submit' className='updateRefresh'>Update</button>
                        <img src={refresh} alt='refresh' />
                    </div>
                    <div id="updateRefresh">
                        <button type='submit' className='updateRefresh'>Report</button>
                        <img src={frame} alt='report' />
                    </div>
                </div> */}
            </div>
            <div className={`score `}>
                <div className={`score-box1  ${activeItem === 'Total Threats' ? 'active' : ''}`} onClick={() => handleItemClick('Total Threats')}>
                    {!displayItems1 ?
                        <div className='threatCount-not-found'>
                            <div className='num1'>{"0"} </div>
                        </div> :
                        <div className='num1'>{threatCount} </div>
                    }
                    <div className='total-score'>Total Occurrences</div>
                </div>
                <div className={`score-box ${activeItem === 'Authentication' ? 'active' : ''} `} onClick={() => handleItemClick('Authentication')}  >
                    <div className={`num `}>Authentication <div className='previous-num'>Previously: _</div></div>
                    {!displayItems1 ?
                        <div className='threatCount-not-found'>
                            <div className='num1'>{"0"} </div>
                        </div> :
                        <div className={`num2 `}> {token} <div className='previous-stats'>0%</div></div>
                    }
                </div>

                <div className={`score-box ${activeItem === 'Identity' ? 'active' : ''} `} onClick={() => handleItemClick('Identity')}>
                    <div className={`num `}>Identity <div className='previous-num'>Previously: _</div></div>
                    {!displayItems1 ?
                        <div className='threatCount-not-found'>
                            <div className='num1'>{"0"} </div>
                        </div> :
                        <div className={`num2 `}>{idsCount}  <div className='previous-stats'>0%</div></div>
                    }
                </div>

                {/* <div className={`score-box ${activeItem === 'Session' ? 'active' : ''} `} onClick={() => handleItemClick('Session')}>
                    <div className={`num `}>Session <div className='previous-num' >Previously: 16 </div></div>
                    <div className={`num2 `}>12  <div className='previous-stats'>-16.4%</div></div>
                </div> */}

            </div>
            <div className='Landscape '>
                <div id='landscape'>
                    Landscape
                </div>
                {/* <div className='fuzzButton'>
                    <div>
                        <button id='fuzzButton'>Fuzz</button>
                    </div>
                    <div>
                        <button id='runButton' onClick={() => { toggleRunButton() }}>Run</button>
                    </div>
                    <div >
                        <button id='calender' onClick={() => { togglecalender() }}><img src={calender} alt='calender' /></button>
                    </div>
                </div> */}
            </div>

            <div className={`list-con1 `}>
                <div className={`trend `}>
                    {!displayItems ?
                        <div className='cwedata-not-found'>
                            <p>Threat Metric</p>
                            <img src={dataNotFound} alt='data not found' id='cwe-data-found' />
                        </div> :
                        <CVSS_Trends />
                    }
                </div>
                <div className={`recommendation `}>
                    <div id='recommendation' style={{ marginTop: '5px' }}>Recommendations </div>
                    {!displayItems ?
                        <div className='bell_icon1'>
                            <img src={bell_icon} alt='currently data not available' id='cwe-data-found1' />
                        </div> :
                        <div>
                            {currentPage && currentPage.length > 0 && currentPage.map((currentPage, idx) => (
                                <div className={`suggestion `} key={idx}>
                                    {currentPage.text1}
                                    <p className={`suggestion-box `}>{currentPage.text2}</p>
                                </div>
                            ))}

                            <PaginationDots currentPage1={currentPage} totalPages={Math.ceil(fetchData.length / itemsPerPage)} onPageChange={handlePageChange} />
                        </div>}
                </div>
            </div>
            <div className={`list-con2 `}>
                <div className={`listOfRuns `}>
                    <div className={`list-head`} >
                        <div>Runs</div>
                        <div >
                            <button className='add-new-run' onClick={addNewRun}>Add New Run</button>
                            <img src={sortingSvg} alt='sorting' className='img12' />
                        </div>
                    </div>
                    <div className={`listOfRuns1 `}>
                        {!displayItems1 ?
                            <div className='rundata-not-found'>
                                <img src={dataNotFound} alt='data not found' />
                                <div>
                                    <button onClick={handleListOfRuns}>Run Now</button>
                                </div>
                            </div> :
                            <ul className='con3' style={{ marginTop: '4px' }}>
                                {renderRunsNames()}
                            </ul>
                        }
                    </div>
                    {!viewAll && runs.length > runsPerPage && (
                        <div className='viewbutton1'>
                            <div></div>
                            <button className='viewAllRun' onClick={handleViewAll}>View All</button>
                        </div>
                    )}
                </div>
                <div >
                    <div className={`Top10 `}>
                        {!displayItems ?
                            <div className='cwedata-not-found'>
                                <p>CWE Metric</p>
                                <img src={dataNotFound} alt='data not found' id='cwe-data-found' />
                            </div> :
                            <Top10CWE ottInfoMap={ottInfoMap} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListOfRuns;
