import React, { useEffect, useRef, useState } from 'react';
import '../../../Styles/DeveloperMode/PopUpUtils/UploadHAR.css';
import upload from "../../../Images/export.svg";
import Image from "../../../Images/File.svg";
import { useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../StateManagement/SnackbarContext';
import "../../../Styles/DeveloperMode/Notification/SnackbarMUI.css";

const UploadHAR = ({ setCloseScriptForm,newflow ,setOpenForm}) => {
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const [nextClicked, setNextClicked] = useState(false);
    const apiUrl = process.env.REACT_APP_URL;
    const localToken = localStorage.getItem('accessToken');
    const [file, setFile] = useState(null);
    const [isFlowNameReadOnly, setIsFlowNameReadOnly] = useState(false);
    const [formData, setFormData] = useState({
        app_name: '',
        flow_name: '',
        run_name: '',
        privilege_value: '',
    });
    const [uploadImage, setUploadImage] = useState(upload); 
    const [flag, setFlag] = useState(false); 
    const navigate = useNavigate();
    const {showSnackbar}=useSnackbarContext();
console.log(newflow)
    useEffect(() => {
        const application_name = localStorage.getItem('appname');
        const workflow_name = localStorage.getItem('flowName');
        console.log("Fetched app_name from localStorage:", workflow_name);
       
        if (workflow_name && workflow_name !== 'undefined' && workflow_name.trim() !== '') {
            setFormData(prevData => ({
                ...prevData,
                app_name: application_name,
                flow_name: workflow_name,
            }));
            setIsFlowNameReadOnly(true);
        } else {
            setFormData(prevData => ({
                ...prevData,
                app_name: application_name,
                flow_name: '',
            }));
            setIsFlowNameReadOnly(false);
        }
    }, []);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        handleFiles(event.dataTransfer.files);
    };

    const handleFiles = (files) => {
        if (files.length > 0) {
            const file = files[0];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const validExtensions = ['har'];

            // File name must have .har extension
            if (!validExtensions.includes(fileExtension)) {
                showSnackbar('Please upload a .har file.',{variant:'warning',className:'snackbar-warning'});
                return;
            }

            // Do not accept an empty file
            if (file.size === 0) {
                showSnackbar('The file size should not be 0 bytes.',{variant:'warning',className:'snackbar-warning'});
                return;
            }

            // File must be in valid JSON format
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const json = JSON.parse(e.target.result);
                    if (!json) {
                        throw new Error('Invalid JSON format');
                    }
                    // File is valid, 
                    setFileName(file.name);
                    setFile(file);
                    setUploadImage(Image);
                    setFlag(true);
                } catch (error) {
                    showSnackbar('The file is not in valid JSON format.',{variant:'warning',className:'snackbar-warning'});
                }
            };
            reader.onerror = () => {
                showSnackbar('Error reading file.',{variant:'warning',className:'snackbar-warning'});
            };
            reader.readAsText(file);
        }
    };

    const trimFormData = (data) => {
        const trimmedData = {};
        for (const key in data) {
            if (typeof data[key] === 'string') {
                if(key==='flow_name'){
                    trimmedData[key] = data[key].toUpperCase().trim();
                }else{
                    trimmedData[key] = data[key].trim();
                }
            } else {
                trimmedData[key] = data[key];
            }
        }
        return trimmedData;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form data being sent:", formData);
        if (!fileName) {
            showSnackbar("Please upload a file.",{variant:'warning',className:'snackbar-warning'});
            return;
        }

        if (!file) {
            showSnackbar("No file selected.",{variant:'warning',className:'snackbar-warning'});
            return;
        }

        const trimmedFormData = trimFormData(formData);
        const formDataToSend = new FormData();
        formDataToSend.append('har_data', JSON.stringify(trimmedFormData));
        formDataToSend.append('file', file);

        try {
            showSnackbar('Uploading...',{variant:'info',persist:true,className:'snackbar-info'})
            const response = await fetch(`${apiUrl}/resources/har-upload`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
                method: 'POST',
                body: formDataToSend,
            });

            const responseBody = await response.json();
            console.log("Response body:", responseBody);
            if (response.ok) {
                showSnackbar('File uploaded successfully. Information will be ready in just a few moments.',{variant:'success',className:'snackbar-success'});
                setTimeout(() => {
                    // window.location.reload();
                    // navigate(0);
                    setOpenForm(false)
                },1000);
                setTimeout(() => {
                    navigate(0);

                }, 3000);
                console.log(responseBody);

            } else {
                showSnackbar('Failed to upload file',{variant:'error',className:'snackbar-error'});
                setTimeout(() => {
                    // window.location.reload();
                    setOpenForm(false)
                }, 2000);
                console.error('Upload error:', responseBody);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const validateRunName = (value) => {
        // Regular expression for alphanumeric values with no whitespace
        const alphanumericRegex = /^[a-zA-Z0-9]*$/;
        return alphanumericRegex.test(value);
    };

    const handleNextClick = (e) => {
        e.preventDefault();  //&& formData.privilege_value  ---> remove compulsion of privilege_value
        if (fileName && formData.app_name && formData.flow_name ) {
            setNextClicked(true);
            handleSubmit(e);
            setCloseScriptForm(false);
            if(newflow){
                navigate(`/dashboard`);
                showSnackbar('File uploaded successfully. Information will be ready in just a few moments.',{variant:'success',className:'snackbar-success'});
                setTimeout(() => {
                    navigate(0);

                }, 2000);
            }
        } else {
            showSnackbar('Please provide the required inputs.',{variant:'warning',className:'snackbar-warning'});
        }
    };

    const validateLength = (value, minLength, maxLength) => {
        return value.length >= minLength && value.length <= maxLength;
    };

    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id === 'run_name' && !validateRunName(value)) {
            showSnackbar('Run name must be alphanumeric with no whitespace.',{variant:'warning',className:'snackbar-warning'});
            return;
        }

        if (id === 'flow_name' ) {
            if (!validateLength(value, 0, 50)) {
                showSnackbar('Flow name must be between 0 and 50 characters.',{variant:'warning',className:'snackbar-warning'});
                return;
            }
        }
        if (id === 'run_name') {
            if (!validateLength(value, 0, 50)) {
                showSnackbar('Run name must be between 0 and 50 characters.',{variant:'warning',className:'snackbar-warning'});
                return;
            }
        }


        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    return (
        <div className='uploadHAR'>
            <div className='extractHAR'>
                <div id="uploadHR">Upload HTTP Archive file</div>
                <div
                    className="upload-boxHAR"
                    onClick={handleClick}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <img src={uploadImage} alt="Upload Icon" />
                    {flag ? <p style={{ color: "#595D62" }}>{fileName}</p> : <p style={{ color: "#595D62" }}>Click or drag to upload file</p>}
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".har"
                        onChange={(e) => handleFiles(e.target.files)}
                        required
                    />
                </div>

                <div className='form-fields'>
                    <div>
                        <label htmlFor="app_name">Application *</label>
                        <input
                            type="text"
                            id="app_name"
                            value={formData.app_name}
                            onChange={handleChange}
                            required
                            readOnly
                        />
                    </div>
                    <div>
                        <label htmlFor="flow_name">Workflow *</label>
                        <input
                            type="text"
                            id="flow_name"
                            value={formData.flow_name}
                            onChange={handleChange}
                            readOnly={isFlowNameReadOnly}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="run_name">Run</label>
                        <input
                            type="text"
                            id="run_name"
                            value={formData.run_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="privilege_value">User Privilege </label>
                        <select
                            id="privilege_value"
                            value={formData.privilege_value}
                            onChange={handleChange}
                            // required
                        >
                            <option value="" disabled>Select Privilege</option>
                            <option value="User">User</option>
                            <option value="Admin">Admin </option>
                        </select>
                    </div>
                </div>

                <div className='choose-btn'>
                    <button className='choose-back' style={{ backgroundColor: 'initial', color: 'initial', border: '1px solid', cursor: "pointer" }} onClick={() => setCloseScriptForm(false)}>
                        Back
                    </button>
                    <button className='choose-nxt' style={{ border: nextClicked ? 'none' : 'none', backgroundColor: nextClicked ? '#FFD042' : '#FFD042', color: nextClicked ? 'black' : 'initial', cursor: "pointer" }} onClick={handleNextClick}>
                        Extract Flow
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UploadHAR;
