import React, { useState, useEffect } from 'react';
import '../../../Styles/DeveloperMode/ReportGeneration/CustomDrpDown.css'
import filter from '../../../Images/blackSetting.svg';
const CustomDropdown = ({ options, selectedOption, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        onSelect(option);
        setIsOpen(false);
    };

   
    const convertedString = (option) => {
        const convertedOption = option.replace('_', ' ').toUpperCase();
        return convertedOption.length > 15 ? convertedOption.substring(0, 14) + '...' : convertedOption;

    }
    return (
        <div className="custom-dropdown">
            <div className="dropdown-toggle" onClick={() => setIsOpen(!isOpen)}>
                <div >
                    {selectedOption ? convertedString(selectedOption) : 'Select Vulnerability Type'}
                </div>
                
                <img src={filter} alt='down' className='filterVul' />

            </div>
            {isOpen && (
                <div className="dropdown-menu">
                    {options.map((option) => (
                        <div key={option} className="dropdown-item"  onClick={() => handleOptionClick(option)} >
                            {/* <label>
                                <input
                                    type="checkbox"
                                    checked={selectedOption === option}
                                    style={{cursor:'pointer'}}
                                />
                            </label> */}
                            <div className='optVul' > {option.replace('_', ' ').toUpperCase()}</div>
                        </div>
                    ))}
                  
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;




// import React, { useState } from 'react';
// import '../../../Styles/DeveloperMode/ReportGeneration/CustomDrpDown.css';
// import filter from '../../../Images/blackSetting.svg';

// const CustomDropdown = ({ options, selectedOption, onSelect }) => {
//     const [isOpen, setIsOpen] = useState(false);
//     const [tempSelectedOptions, setTempSelectedOptions] = useState([...selectedOption]); // Temp storage for selected options before applying

//     const handleOptionClick = (option) => {
//         if (option === 'Select All') {
//             // If "Select All" is clicked, select/deselect all options
//             if (tempSelectedOptions.includes('Select All')) {
//                 setTempSelectedOptions([]); // Deselect all
//             } else {
//                 setTempSelectedOptions(['Select All', ...options.filter(opt => opt !== 'Select All')]); // Select all
//             }
//         } else {
//             // For individual options
//             setTempSelectedOptions((prevSelected) => {
//                 const newSelectedOptions = prevSelected.includes(option)
//                     ? prevSelected.filter((item) => item !== option) // Remove option if already selected
//                     : [...prevSelected.filter((item) => item !== 'Select All'), option]; // Add option if not selected, also deselect "Select All"

//                 // If any option is deselected, "Select All" should be deselected
//                 if (newSelectedOptions.length < options.length) {
//                     return newSelectedOptions.filter((item) => item !== 'Select All');
//                 }

//                 return newSelectedOptions;
//             });
//         }
//     };

//     const handleApplyClick = () => {
//         onSelect(tempSelectedOptions); // Pass the selected options back to parent
//         setIsOpen(false); // Close dropdown after applying
//     };

//        const convertedString = (option) => {
//         const convertedOption = option.replace('_', ' ').toUpperCase();
//         return convertedOption.length > 15 ? convertedOption.substring(0, 14) + '...' : convertedOption;

//     }
//     return (
//         <div className="custom-dropdown">
//               <div className="dropdown-toggle" onClick={() => setIsOpen(!isOpen)}>
//                  <div >
//                    Select Vulnerability 
//                  </div>
                
//                 <img src={filter} alt='down' className='filterVul' />

//              </div>
//             {isOpen && (
//                 <div className="dropdown-menu">
//                     {options.map((option) => (
//                         <div key={option} className="dropdown-item" onClick={() => handleOptionClick(option)} >
//                             <label>
//                                 <input
//                                     type="checkbox"
//                                     checked={tempSelectedOptions.includes(option)}
//                                     // onChange={() => handleOptionClick(option)}
//                                     style={{cursor:'pointer'}}
//                                 />
//                             </label>
//                             <div className="optVul">{option.replace('_', ' ').toUpperCase()}</div>
//                         </div>
//                     ))}
//                     <button className="apply-button" onClick={handleApplyClick} style={{padding:'5px', color: '#000', backgroundColor: '#FFCB2D', border: '1px solid #FFCB2D', borderRadius: '5px' }}>
//                         Apply
//                     </button>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default CustomDropdown;
