import React, { useState } from 'react';
import '../../../Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import Navbar from '../MainContent/Navbar';
import MinimizeLeftBar from '../LeftSidebar/MinimizeLeftBar';
import PreProcessFuzzer from './PreProcessFuzzer';
import ConfirmFuzz_Modal from '../PopUpUtils/ConfirmFuzz_Modal';
import ConfirmAll_modal from '../PopUpUtils/ConfirmAll_modal';

const PreFuzzing = ({ onLogout, onRefreshToken, accessToken, minimize, setMinimize }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [CWEName, setCWEName] = useState('');
    const [CWEDesc, setCWEDesc] = useState('');
    const [CWEUrl, setCWEUrl] = useState('');
    const [collectRawDataId, setCollectRawDataId] = useState([]);
    const [combineCWE, setCombineCWE] = useState('');
    const [OWASPName, setOWAPSName] = useState([]);
    const [collectAPI, setCollectAPI] = useState([]);
    const [allThreats, setAllThreats] = useState([]);
    const [viewReport,setViewReport] =useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
    const openModal1 = () => {
        setIsModalOpen1(true);
    }
 
    return (
        <div className='appDashboard'>
            <div className='sidebar'>
                {minimize ?
                    <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />
                    : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}
            </div>
            <div className='content'>
                <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                    <Navbar minimize={minimize} />
                </div>
                <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
                    <PreProcessFuzzer
                        onRefreshToken={onRefreshToken}
                        accessToken={accessToken}
                        minimize={minimize}
                        openModal={openModal}
                        setCWEName={setCWEName}
                        setCWEDesc={setCWEDesc}
                        setUrl={setCWEUrl}
                        setCollectRawDataId={setCollectRawDataId}
                        setCombineCWE={setCombineCWE}
                        openModal1={openModal1}
                        collectAPI={setCollectAPI}
                        setAllThreats={setAllThreats}
                        setOWAPSName={setOWAPSName} 
                        viewReport={viewReport}
                        setViewReport={setViewReport}
                        />
                </div>
                {isModalOpen ?
                    <ConfirmFuzz_Modal
                        isOpen={isModalOpen}
                        setIsOpen={setIsModalOpen}
                        CWEName={CWEName}
                        CWEDesc={CWEDesc}
                        CWEUrl={CWEUrl}
                        collectRawDataId={collectRawDataId}
                        combineCWE={combineCWE}
                        collectAPI={collectAPI}
                        OWASPName={OWASPName} 
                        setViewReport={setViewReport}
                      />
                    : ''}
                {isModalOpen1 ?
                    <ConfirmAll_modal
                        isOpen={isModalOpen1}
                        setIsOpen={setIsModalOpen1} 
                        OWASPName={OWASPName}
                        onRefreshToken={onRefreshToken} 
                        setViewReport={setViewReport}
                        />
                    : ''}
            </div>
        </div>
    )
}

export default PreFuzzing
