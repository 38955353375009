import React, { useState } from 'react'
import '../../../Styles/DeveloperMode/AppDashboard/FlowsContainer.css';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import '../../../Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import Navbar from '../MainContent/Navbar';
import MinimizeLeftBar from '../LeftSidebar/MinimizeLeftBar';
import CWEReport from './CWEReport';
import All_reports from './All_reports';
import ShowReport from './ShowReport';
import close from '../../../Images/close.svg';

const ReportTab = ({ onLogout, onRefreshToken, minimize, setMinimize }) => {
    const [cweInfoName, setCWEInfoName] = useState('');
    const [showReport, setShowReport] = useState(false);
    const [reportID, setReportID] = useState(null);
   
    // Function to go back to All_reports
    const handleShowAllReports = () => {
        setShowReport(false);
    };

    const popup = {
        cwePopUp: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        },
        cwePopUpcontent: {
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
            width: '90%',
            position: 'relative',
        },
        closeButton: {
            display: 'flex',
            justifyContent: 'flex-end', 
            marginRight:'5px'
        },
        closeImage: {
            cursor: 'pointer',
            width: '13px', 
            height: '15px',
        },
    }
    return (

        <div className='appDashboard'>
            <div className='sidebar'>
                {minimize ? <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}
            </div>
            <div className='content'>
                <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                    <Navbar minimize={minimize} cweInfoName={cweInfoName} />
                </div>
                <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>

                    <All_reports
                        onRefreshToken={onRefreshToken}
                        setCWEInfoName={setCWEInfoName}
                        setReportID={setReportID}
                        setShowReport={setShowReport}
                    />

                    {showReport && (
                        <div style={popup.cwePopUp}>

                            <div style={popup.cwePopUpcontent}>
                            <div style={popup.closeButton}>
                                <img
                                    src={close}
                                    alt='close'
                                    style={popup.closeImage}
                                    onClick={handleShowAllReports}
                                />
                            </div>
                                <ShowReport reportID={reportID} handleShowAllReports={handleShowAllReports} />
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>

    )
}

export default ReportTab;

