import React, { useEffect } from 'react';
import '../../../Styles/DeveloperMode/Navbar/Navbar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchBar from './SearchBar';

import sunIconLight from '../../../Images/sun.svg';
import lightTimer from '../../../Images/refresh.svg';
import lightMenu from '../../../Images/notification.svg';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';

const Navbar = ({ minimize,cweInfoName }) => {
  const { flowName, setFlowName } = useWorkflowDetails('');
  const { runName, setRunName } = useWorkflowDetails('');
  const { appname, setAppname } = useWorkflowDetails('');
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const segments = path.split('/');
  const { reportApp } = useWorkflowDetails('');
  const { reportFlow } = useWorkflowDetails('');
  const { reportRun } = useWorkflowDetails('');

//capitalize the first letter 
  const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  // Decode URL segments
  const displayText1 = segments[1] ? decodeURIComponent(segments[1]) : '';
  const displayText2 = segments[2] ? decodeURIComponent(segments[2]) : '';
  const displayText3 = segments[3] ? decodeURIComponent(segments[3]) : '';
  const displayText4 = segments[4] ? decodeURIComponent(segments[4]) : '';

  useEffect(() => {
    const storedApp = localStorage.getItem('appname');
    const storedFlow = localStorage.getItem('flowName');
    const storedRun = localStorage.getItem('runName');

    if (storedApp) {
      setAppname(storedApp);
    }
    if (storedFlow) {
      setFlowName(storedFlow);
    }
    if (storedRun) {
      setRunName(storedRun);
    }
  }, [setAppname, setFlowName, setRunName]);

  const handleRoute = (text) => {
    let newPath = '/';
    if (path.startsWith('/')) {
      if (text === displayText1) {
        newPath += 'dashboard';
      } else if (text === appname) {
        newPath +=  `${displayText1}/${appname}`;
      } else if (text === flowName) {
        newPath += `${displayText1}/${appname}/${flowName}`;
      } else if (text === runName) {
        newPath += `${displayText1}/${appname}/${flowName}/${runName}`;
      }
    }
    navigate(newPath);
  };

  return (
    <div className={`navbar-content ${minimize ? 'minimized' : ''}`}>
      <div className='route-content'>
        <span id="d1" onClick={() => handleRoute(displayText1)}>{capitalizeFirstLetter(displayText1)}</span>
        {/* Check if the displayText1 matches the appname */}
        {displayText2 === appname && (
          <div style={{ display: 'flex' }}>
            <pre style={{ paddingBottom: '11px' }}> / </pre>
            <span id="d2" onClick={() => handleRoute(appname)} style={{ paddingTop: "11px" }}>  {cweInfoName ? reportApp : appname}</span>
          </div>
        )}

        {/* Check if the displayText2 matches the flowName */}
        {displayText3 === flowName && (
          <div style={{ display: 'flex' }}>
            <pre style={{ paddingBottom: '11px' }}> / </pre>
            <span id="d2" onClick={() => handleRoute(flowName)} style={{ paddingTop: "11px" }}>  {cweInfoName ? reportFlow : flowName}</span>
          </div>
        )}

        {/* Check if the displayText3 matches the runName */}
        {displayText4 === runName && (
          <div style={{ display: 'flex' }}>
            <pre style={{ paddingBottom: '11px' }}> / </pre>
            <span id="d2" onClick={() => handleRoute(runName)} style={{ paddingTop: "11px" }}>  {cweInfoName ? reportRun : runName}</span>
          </div>
        )}
        
         {
         cweInfoName && (
          <div style={{ display: 'flex' }}>
          <pre style={{ paddingBottom: '11px' }}> / </pre>
         <span id="d2" style={{ paddingTop: "11px" }}>{cweInfoName}</span>
         </div>
         )}
      </div>

      <div className='search-content'>
        <SearchBar />

        <img className='iconImg' style={{ cursor: 'pointer' }} src={sunIconLight} alt='sun' />

        <img className='iconImg' style={{ cursor: 'pointer' }} src={lightTimer} alt='timer' />

        <img className='iconImg' style={{ cursor: 'pointer' }} src={lightMenu} alt='menuitem' />

      </div>
    </div>
  );
}

export default Navbar;


