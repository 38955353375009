import React from 'react';
import ApplicationLeftBar from './ApplicationDashboard/ApplicationLeftBar';
import ApplicationNavbar from './ApplicationDashboard/ApplicatonNavbar';
import ListOfApplication from './ApplicationDashboard/ListOfApplication';
import ApplicationMinimizeLeftBar from './LeftSidebar/ApplicationMinimizeLeftBar';
import '../../Styles/DeveloperMode/AppDashboard/AppDashboard.css';

const DeveloperComponent = ({onLogout, onRefreshToken,setAllAppNames, allAppNames ,minimize, setMinimize}) => {
 
  return (
    <div className='appDashboard'>
    <div className='sidebar'>
        {minimize ?  <ApplicationLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <ApplicationMinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}

    </div>
    <div className='content'>
    <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
            <ApplicationNavbar />
        </div>
        <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
          <ListOfApplication onRefreshToken={onRefreshToken} setAllAppNames={setAllAppNames}  allAppNames={allAppNames} />
        </div>
    </div>
</div>
  );
};

export default DeveloperComponent;







