import React from 'react'
import '../../../Styles/DeveloperMode/AppDashboard/FlowsContainer.css';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import '../../../Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import Navbar from '../MainContent/Navbar';
import AllFlowsContainer from '../FlowContainer/AllFlowsContainer';
import MinimizeLeftBar from '../LeftSidebar/MinimizeLeftBar';

const FlowsContainer = ({ onLogout, onRefreshToken, minimize, setMinimize }) => {
    return (

        <div className='appDashboard'>
            <div className='sidebar'>
                {minimize ? <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}
            </div>
            <div className='content'>
                <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                    <Navbar minimize={minimize}/>
                </div>
                    <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
                        <AllFlowsContainer onRefreshToken={onRefreshToken} />
                    </div>
            </div>
        </div>

    )
}

export default FlowsContainer
