import React, { useEffect, useState } from 'react';
import '../../../Styles/DeveloperMode/FlowContainer/AllFlowsContainer.css';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
import vector from '../../../Images/Vector.svg';
import sorting from '../../../Images/Sorting (1).svg';

import dataNotFound from '../../../Images/notDatafound.svg';
import CWE_matric from '../Graph_content/CWE_matric';
import PaginationDots from '../../Pagination/PaginationDots';
import ChoosePreference from '../PopUpUtils/ChoosePreference';
import { useNavigate } from 'react-router-dom';

const AllFlowsContainer = ({ onRefreshToken }) => {
  const [workflows, setWorkflows] = useState([]);
  const [allRunsList, setAllRunsList] = useState([]);
  const { setRunId } = useWorkflowDetails();
  const [flowList, setFlowList] = useState();
  const [threatCount, setThreatCount] = useState();
  const { flowId, setFlowId } = useWorkflowDetails();
  const { setFlowName } = useWorkflowDetails();
  const [displayItems, setDisplayItems] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const runsPerPage = 5;
  const [runlist, setRunList] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_URL;
  const { app_id, setApp_id } = useWorkflowDetails();
  const [accessTokenUpdated, setAccessTokenUpdated] = useState('');

  if (!app_id ) {
    navigate('/')
  }

  useEffect(() => {
    const storedApp_id = localStorage.getItem('app_id');
    if (storedApp_id) {
      setApp_id(storedApp_id);
    }
  }, [setApp_id]);

  useEffect(() => {
    if (app_id) {
      fetchAllFlows();
    }
  }, [app_id]);

  useEffect(() => {
    if (accessTokenUpdated) {
      if (app_id) {
        fetchAllFlows();
      }
    }
  }, [accessTokenUpdated]);


  const localToken = localStorage.getItem('accessToken');

  const fetchAllFlows = async () => {
    try {
      const response = await fetch(`${apiUrl}/resources/apps/${app_id}/flows`, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setWorkflows(data);

        if (data && data.length > 0) {
          const hasFlowName = data.some(workflow => !!workflow.flow_name);
          setDisplayItems(hasFlowName);
        }

        if (data && data.length > 0) {
          const flownames = data.map(flow => flow.flow_name);
          setFlowName(flownames);

          const flowCount = flownames.length;
          const threatCount = data.map(threat => threat.total_threat_count);
          const totalThreatCount = threatCount.reduce((acc, count) => acc + count, 0);
          // const flow_id = data.map(flow_id => flow_id._id);
          // setFlowId(flow_id);
          setFlowList(flowCount);
          setThreatCount(totalThreatCount);
        }
      }
      else if (response.status === 400) {
        const result = await onRefreshToken();
        if (result === "success") {
          setAccessTokenUpdated(localStorage.getItem('accessToken'));
        }
        else {
          navigate('/');
        }
      } else if (response.status === 401) {
        const result = await onRefreshToken();
        if (result === "success") {
          setAccessTokenUpdated(localStorage.getItem('accessToken'));
        } else {
          navigate('/');
        }
      } else if (response.status === 404) {
        navigate('/');
      }
    }
    catch (error) {
      console.error("error in fetching workflows of application", error);
    }
  };

  useEffect(() => {
    fetchAllRuns();
  }, [flowId]);

  useEffect(() => {
    if (accessTokenUpdated) {
      fetchAllRuns();
    }
  }, [accessTokenUpdated]);

  const fetchAllRuns = async () => {
    try {
      const url = `${apiUrl}/resources/apps/${app_id}/flows/${flowId}/runs`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setAllRunsList(Array.isArray(data) ? data : []);
        setRunId(data._id);

        if (data && data.length > 0) {
          const runs = data.map(runs => runs.run_name);
          setRunList(runs);
        }
      }
      else if (response.status === 400) {
        const result = await onRefreshToken();
        if (result === "success") {
          setAccessTokenUpdated(localStorage.getItem('accessToken'));
        }
        else {
          navigate('/');
        }
      } else if (response.status === 401) {
        const result = await onRefreshToken();
        if (result === "success") {
            setAccessTokenUpdated(localStorage.getItem('accessToken'));
        } else {
            navigate('/');
        }
    }
    }
    catch (error) {
      console.log("error in fetching list of runs", error);
    }
  };


  const creationTime = timeStamp => {
    const currentTime = new Date();
    const dateTime = new Date(timeStamp);
    const differenceInSeconds = Math.floor((currentTime - dateTime) / 1000);

    if (differenceInSeconds < 60) {
      return 'Just now';
    }
    else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return `${minutes} minutes ago`;
    }
    else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      return `${hours} hours ago`;
    }
    else {
      const days = Math.floor(differenceInSeconds / 86400);
      return `${days} days ago`;
    }
  };

  const handleWorkflowClick = (fl_id, fl_name) => {
    setFlowId(fl_id);
    setFlowName(fl_name);
  };

  const renderRuns = () => {
    const startIndex = (currentPage - 1) * runsPerPage;
    const endIndex = startIndex + runsPerPage;
    const currentRuns = allRunsList.slice(startIndex, endIndex);

    return currentRuns && currentRuns.map((run, index) => (
      <ul key={index}>
        <li className='runs-Name'>
          <div>
            <div className='bulletpoint_run'>{run.run_name}</div>
            <p id='role-based-scan-2'> Manual{<>&nbsp;</>} /{<>&nbsp;</>} New filter added</p>
          </div>
          <div>
            <div className='threatborder1'>CVSS score</div>
            <div className='creation-time'>
              <span>Ran: {creationTime(run.creation_time)}  </span>
            </div>
          </div>
        </li>
      </ul>
    ));
  };

  const renderflowName = () => {
    return workflows && workflows.map((workflow, index) => (
      <ul key={index}>
        <li className='flowsName'>
          <div onClick={() => handleWorkflowClick(workflow._id, workflow.flow_name)}>
            <div className='bulletpoint'> {workflow.flow_name} </div>
            <p id='role-based-scan-1'> Admin | Regular user</p>
          </div>
          <div className='threat_count'>
            <div className='threatborder'>
              {workflow.total_threat_count} Occurrences
            </div>
            <div id='col2'>
              <label className="container">
                <input type="checkbox" />
                <span className="checkmark1"></span>
              </label>
              <label className="container">
                <input type="checkbox" />
                <span className="checkmark2"></span>
              </label>
              <label className="container">
                <input type="checkbox" />
                <span className="checkmark3"></span>
              </label>
            </div>
            <div className='keyarrow' onClick={() => handleWorkflowClick(workflow._id, workflow.flow_name)}>
              <img src={vector} alt='sorting' />
            </div>
          </div>
        </li>
      </ul>
    ));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const addNewFlow = () => {
    setOpenForm(!openForm)
  }

  return (
    <div>
      <div className='flowContainer'>
        <div className='row-1'>
          <div id='block'>
            <div>
              <p id='risk-level'>Total</p>
              <p id='risk_cwe'>CWEs: _</p>
            </div>
            <div>
              <p id='col1-t1'>
                {threatCount}
              </p>
              <pre id='risk_cwe1'>Workflows: {flowList}</pre>
            </div>
          </div>
          <div id='block1'>
            <div>
              <p id='risk-level'>Critical</p>
              <p id='risk_cwe'>CWEs: _</p>
            </div>
            <div>
              <p id='col2-t2'>_</p>
              <p id='risk_cwe1'>Workflows: _</p>
            </div>
          </div>
          <div id='block2'>
            <div>
              <p id='risk-level'>High</p>
              <p id='risk_cwe'>CWEs: _</p>
            </div>
            <div>
              <p id='col3-t3'>_</p>
              <p id='risk_cwe1'>Workflows: _</p>
            </div>
          </div>
          <div id='block3'>
            <div>
              <p id='risk-level'>Medium</p>
              <p id='risk_cwe'>CWEs: _</p>
            </div>
            <div>
              <p id='col4-t4'>_</p>
              <p id='risk_cwe1'>Workflows: _</p>
            </div>
          </div>
          <div id='block4'>
            <div>
              <p id='risk-level'>Low</p>
              <p id='risk_cwe'>CWEs: _</p>
            </div>
            <div>
              <p id='col5-t5'>_</p>
              <p id='risk_cwe1'>Workflows: _</p>
            </div>
          </div>
        </div>
        <div className='row-2'>
          <div className='flow-row-2'>
            <div className='flow_button'>
              <div id='flow_text'>Workflows</div>
              <div id='flow_button'>
                <button style={{ cursor: "pointer" }} onClick={addNewFlow}>Add workflow</button>
                <img src={sorting} alt='sorting' />
              </div>
            </div>
            <div className='adjust_aline'>
              <div></div>
              <div className='checks'>
                <div className='CheckRisk'>Run</div>
                <div className='CheckRisk'>Prob</div>
                <div className='CheckRisk'>Exploit</div>
              </div>
            </div>
            <div>
              {!displayItems ?
                <div className='flowdata-not-found'>
                  <img src={dataNotFound} alt='data not found' />
                </div> :
                <div className='inline_flows'>
                  <div>{renderflowName()}</div>
                </div>
              }
            </div>
          </div>
          <div className='sub-row-2'>
            <div id='col2-row1'>
              <div id='app-cwe'>
                {!displayItems ?
                  <div className='cwe-not-found'>
                    <p>CWE Metric</p>
                    <img src={dataNotFound} alt='data not found' />
                  </div> :
                  <div>
                    <CWE_matric />
                  </div>
                }
              </div>
            </div>
            <div id='col2-row2'>
              <div id='runlist'>
                <div>Runs</div>
                <img src={sorting} alt='sorting' />
              </div>
              {!displayItems ? <img src={dataNotFound} alt='data not found' style={{ margin: "80px" }} /> :
                <div id='lst-col2-row2'>
                  <div>{renderRuns()}</div>
                  <div>
                    <PaginationDots
                      currentPage1={currentPage}
                      totalPages={Math.ceil(runlist.length / runsPerPage)}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {openForm ? <ChoosePreference setOpenForm={setOpenForm} /> : ""}
    </div>
  );
};

export default AllFlowsContainer;
