import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../../Styles/DeveloperMode/AppDashboard/MinimizeLeftBar.css';
import logo from '../../../Images/apisecuristLogo.svg';
import vector from '../../../Images/overview1.svg';
import icon1 from '../../../Images/data.svg';
import icon2 from '../../../Images/note.svg';
import icon3 from '../../../Images/maximize-2.svg';
import icon4 from '../../../Images/align-left.svg';
import icon5 from '../../../Images/Icon (from Tabler.io) (4).svg';
import icon6 from '../../../Images/logout.svg';
import expand from '../../../Images/arrow button.svg';
import User from '../../UserAuthentication/User';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
import Cookies from 'js-cookie';

const ApplicationMinimizeLeftBar = ({ onLogout, setMinimize, minimize }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { appname, setAppname } = useWorkflowDetails();
    const { workflowId, runName } = useParams();
    const [activeItem, setActiveItem] = useState('Overview');


    useEffect(() => {
        const storeapp = localStorage.getItem('appname');
        if (storeapp) {
          setAppname(storeapp);
        }
      }, [setAppname]);
      useEffect(() => {
    
    
        // let activeItem = 'Overview';
        // if (pathname.includes(`/application/${appname}`)) {
        //   activeItem = 'Overview';
        // } else if (pathname.includes(`/${appname}/flows`)) {
        //   activeItem = 'Flows';
        // } else if (pathname.includes(`/${appname}/report`)) {
        //   activeItem = 'Reports';
        // } else if (pathname.includes(`/${appname}/activity-planner`)) {
        //   activeItem = 'Run Activity & Planner';
        // } else if (pathname.includes(`/${appname}/threat-matrix`)) {
        //   activeItem = 'Threat Matrix';
        // } else if (pathname.includes(`/${appname}/settings`)) {
        //   activeItem = 'Settings';
        // }
    
        // Set the active item class based on the updated active item
        const menuItems = document.querySelectorAll('.menu-item');
        menuItems.forEach(item => {
          if (item.textContent.trim() === activeItem) {
            item.classList.add('active');
          } else {
            item.classList.remove('active');
          }
        });
      }, [location.pathname]);
    
      const handleItemClick = (item) => {
        switch (item) {
          case 'Overview':
            // navigate(`/application/${appname}`);
            navigate(`/dashboard`)
            break;
          //   case 'Flows':
          //     navigate(`/${appname}/flows`);
          //     break;
          case 'Reports':
            navigate('/Reports');
            break;
          // case 'Run Activity & Planner':
          //   navigate('/github/activity-planner');
          //   break;
          // case 'Threat Matrix':
          //   navigate('/github/threat-matrix');
          //   break;
          // case 'Settings':
          //   navigate('/github/settings');minimize={minimize} setMinimize={setMinimize}
          // break;
          default:
            break;
        }
      };
    
      const handleLogout = () => {
        Cookies.remove('roles');
        Cookies.remove('firstName');
        // Cookies.remove('lastName');
        localStorage.removeItem('accessToken');
        onLogout();
        navigate('/');
        // window.location.href = '/';
      };
    
    const handleMinimize = () => {
        setMinimize(true);
    }

    return (

        <div className='minimize-menu'>
        <div className='minimize-menu-form'>
            <img className='WasLogo-min' src={logo} alt='logo'/>
            <div className='expand-arrow'>
            <img className='expand-sidebar' src={expand} alt='icon' onClick={handleMinimize} />
            </div>
            <div className='All-item-min'>
                <div>
                    <div className={`menu-item-min ${activeItem === 'Overview' ? 'active' : ''}`} onClick={() => handleItemClick('Overview')}>
                        <img className='WasImg' src={vector} alt='vector' />
                    </div>
                    <div className={`menu-item-min ${activeItem === 'Flows' ? 'active' : ''}`} onClick={() => handleItemClick('Flows')}>
                        <img className='WasImg' src={icon1} alt='icon1' />
                    </div>
                    <div className={`menu-item-min ${activeItem === 'Reports' ? 'active' : ''}`} onClick={() => handleItemClick('Reports')}>
                        <img className='WasImg' src={icon2} alt='icon2' />
                    </div>
                    <div className={`menu-item-min ${activeItem === 'Run Activity & Planner' ? 'active' : ''}`} onClick={() => handleItemClick('Run Activity & Planner')}>
                        <img className='WasImg' src={icon3} alt='icon3' />
                    </div>
                    <div className={`menu-item-min ${activeItem === 'Threat Matrix' ? 'active' : ''}`} onClick={() => handleItemClick('Threat Matrix')}>
                        <img className='WasImg' src={icon4} alt='icon4' />
                    </div>
                </div>
            </div>
            <div className='second-menu-min'>
                <div className='user-role1'>
                    <User minimize={minimize} />
                </div>
                <div className='user-role'>
                    <div className={`menu-item-min ${activeItem === 'Settings' ? 'active' : ''}`} onClick={() => handleItemClick('Settings')}>
                        <img className='WasImg' src={icon5} alt='icon5' />
                    </div>
                    <div className='menu-item-min' onClick={handleLogout}>
                        <img className='WasImg' src={icon6} alt='icon6' style={{ color: '#EB5050' }} />
                    </div>
                </div>
            </div>
        </div>
    </div>

    );
}

export default ApplicationMinimizeLeftBar;



