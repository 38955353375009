import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../../../Styles/DeveloperMode/ReportGeneration/CWEReport.css';
import arrowUp from '../../../Images/arrowup.svg';
import downArrow from '../../../Images/arrowDown.svg';
import ReportChart from './ReportChart';
import back from '../../../Images/Previous.svg';
import filter from '../../../Images/iconFilter.svg';
import CustomDropdown from './CustomDropdown';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';

const CWEReport = ({ onRefreshToken, minimize, setCWEInfoName }) => {
    const [reportID, setReportID] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [expandedApi, setExpandedApi] = useState(null);
    const [showOriginalFor, setShowOriginalFor] = useState(null);
    const apiUrl = process.env.REACT_APP_URL;
    const localToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const [accessTokenUpdated, setAccessTokenUpdated] = useState('');
    const [statusCodeCounts, setStatusCodeCounts] = useState({});
    const [curlURLs, setCurlURLs] = useState({});
    const [isDropdownStatusCode, setIsdropdownStatusCode] = useState(false);
    const [selectedStatusCode, setSelectedStatusCode] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedVulType, setSelectedVulType] = useState('Vulnerability');
    // const [selectedVulType, setSelectedVulType] = useState(['All']);
    const vulOptions = ['All', 'more_analysis', 'false_positive', 'confirm_vulnerability'];
    const { setReportAppId} = useWorkflowDetails();
    const { setReportFlowId} = useWorkflowDetails();
    const { setReportRunId} = useWorkflowDetails();
   

    useEffect(() => {
        const storedReportID = Cookies.get('combineCWE');
        if (storedReportID) {
            setReportID(storedReportID);
        } else {
            setFetchError(true);
            console.error('No report ID found in cookies');
        }
    }, []);


    useEffect(() => {
        if (reportID) {  // Check if reportID is not null
            fetchReportData();
        }
    }, [reportID]);


    useEffect(() => {
        if (accessTokenUpdated) {
            fetchReportData();
        }
    }, [accessTokenUpdated]);



    const url = `${apiUrl}/fuzzer/reports/${reportID}`;
    // const url = `${apiUrl}/fuzzer/reports/4b6b6b42-8958-46d2-8b74-7ed7687e48a2`;
    // b87ce0de-ae59-44b0-9f3e-e64aeb26681d gitlab
    // 4b6b6b42-8958-46d2-8b74-7ed7687e48a2 null
    // c55b1feb-1df7-459b-8866-b2844441c5bd flipcart

    const fetchReportData = async () => {
        if (!reportID) {
            setFetchError(true);
            console.error('Invalid report ID');
            return;
        }
        setIsLoading(true);
        try {

            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                
                if (Array.isArray(data) && data.length > 0) {
                    const reportData = data[0];  // Get the first element from the array
    
                    // Check if app_id, flow_id, and run_id are present, then store them
                    const { app_id, flow_id, run_id } = reportData;
                    // console.log(app_id, flow_id, run_id);
    
                    // Store the app_id, flow_id, and run_id in the corresponding state
                    if (app_id) {
                        setReportAppId(app_id);
                    }
                    if (flow_id){ 
                        setReportFlowId(flow_id);
                    }
                    if (run_id) {
                        setReportRunId(run_id);
                    }
                }
                // Normalize the cs_api and attack_api_data fields

                const normalizedData = data.filter(item => item.report !== null).map(item => ({
                    ...item,
                    report: {
                        ...item.report,
                        cs_data: {
                            ...item.report.cs_data,
                            cs_api: Array.isArray(item.report.cs_data.cs_api)
                                ? item.report.cs_data.cs_api
                                : [item.report.cs_data.cs_api],
                        },
                    },
                }));

                const cweInfoArray = data.map(item => item.cwe_info);
                // setCWEInfoName(cweInfoArray);
                // Check if the array contains only null values or is empty
                if (cweInfoArray.length === 0 || cweInfoArray.every(item => item === null)) {
                    setCWEInfoName(['All Risks']);
                } else {
                    setCWEInfoName(cweInfoArray);
                }


                setIsLoading(false);
                setReportData(normalizedData);
                setFilteredData(normalizedData);
                calculateStatusCodeCounts(normalizedData);
            } else if (response.status === 400) {
                setFetchError(true);
                console.error('Bad Request:', response.statusText);
            } else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            } else {
                console.error('Error fetching JSON data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching report data:', error);
            setFetchError(true);
            setIsLoading(false);
        }
    };




    const handleExpand = (index) => {
        setExpandedApi(expandedApi === index ? null : index);
    };

    const toggleShowOriginals = (index) => {
        setShowOriginalFor(showOriginalFor === index ? null : index);
    };


    const parseAttackVector = (vectorString) => {
        const attackType = vectorString.match(/Attack Type:\s*(.*)\n/)[1];
        const attackParam = vectorString.match(/Attack Parameter:\s*(.*)\n/)[1];
        const payload = decodeURIComponent(vectorString.match(/Payload:\s*(.*)/)[1]);
        const payloads = payload.replace(/[{}\\+]/g, '')
        return [attackType, attackParam, payloads];
    };

    //decode request and response and remove URL encoded parameters
    const decodeString = (encodedStr) => {
        return decodeURIComponent(encodedStr.replace(/\+/g, ' '));
    };

    const extractUrlFromCurl = (curlCommand) => {
        // Decode the URL-encoded cURL command
        const decodedCurl = decodeURIComponent(curlCommand);

        // Regex to extract the full URL, including the path
        const urlMatch = decodedCurl.match(/--data-binary.*?\$\s*'(http|https):\/\/[^\s'"]+'/i);
        const url = urlMatch ? urlMatch[0].match(/(http|https):\/\/[^\s'"]+/i)[0] : 'URL not found'
        // Log the extracted URL or a message for debugging
        // console.log("Extracted URL:", url);
        return url;
    };


    const calculateStatusCodeCounts = (data) => {
        const counts = {};

        data.forEach(item => {
            item.report.cs_data.cs_api.forEach(apiData => {
                const attackData = Array.isArray(apiData.attack_api_data) ? apiData.attack_api_data : apiData.attack_api_data ? [apiData.attack_api_data] : [];

                // Count original response codes
                if (apiData.orginal_res_code) {
                    counts[apiData.orginal_res_code] = (counts[apiData.orginal_res_code] || 0) + 1;
                }

                // Count attack response codes
                attackData.forEach(attack => {
                    if (attack.curl_res_code) {
                        counts[attack.curl_res_code] = (counts[attack.curl_res_code] || 0) + 1;
                    }
                });
            });
        });

        setStatusCodeCounts(counts);
    };

    // console.log(statusCodeCounts)
    const dropdownStatusCode = () => {
        setIsdropdownStatusCode(!isDropdownStatusCode);
    }

    // const handleStatusCodeSelection = (statusCode) => {
    //     setSelectedStatusCode(prevState => prevState.includes(statusCode) ? prevState.filter(code => code !== statusCode) : [...prevState, statusCode]);
    // }
    const handleStatusCodeSelection = (statusCode) => {
        if (statusCode === 'All') {
            setSelectedStatusCode(['All']);
        } else {
            setSelectedStatusCode(prevState =>
                prevState.includes(statusCode)
                    ? prevState.filter(code => code !== statusCode && code !== 'All')
                    : [...prevState.filter(code => code !== 'All'), statusCode]
            );
        }
    };

    const applyFilter = () => {
        if (selectedStatusCode.includes('All') || selectedStatusCode.length === 0) {
            setFilteredData(reportData);
        } else if (selectedStatusCode.length > 0) {
            const newFilteredData = reportData.map(reportItem => {
                const filteredApis = reportItem.report.cs_data.cs_api.map(apiData => {
                    const attackData = Array.isArray(apiData.attack_api_data) ? apiData.attack_api_data : apiData.attack_api_data ? [apiData.attack_api_data] : [];

                    // Filter attack data based on selected status codes
                    const filteredAttacks = attackData.filter(attack =>
                        selectedStatusCode.includes(attack.curl_res_code)
                    );

                    // Determine if original response should be displayed
                    const includeOriginal = selectedStatusCode.includes(apiData.orginal_res_code);

                    return {
                        ...apiData,
                        attack_api_data: filteredAttacks,
                        includeOriginal
                    };
                }).filter(data => data.attack_api_data.length > 0 || data.includeOriginal); // Include only data that match the selected status codes

                return {
                    ...reportItem,
                    report: {
                        ...reportItem.report,
                        cs_data: {
                            ...reportItem.report.cs_data,
                            cs_api: filteredApis
                        }
                    }
                };
            }).filter(item => item.report.cs_data.cs_api.length > 0); // Filter out reports with no data

            setFilteredData(newFilteredData);
        } else {
            setFilteredData(reportData); // Show all data if no status codes selected
        }
        setIsdropdownStatusCode(false); // Close the dropdown after applying the filter
    };


    const handleVulTypeSelection = (event) => {
     
        setSelectedVulType(event);
        filterByVulType(event);
    };

    const filterByVulType = (vulType) => {
        let filtered;

        if (vulType === 'All') {
            filtered = reportData;
        } else {
            filtered = reportData
                .map(item => {
                    const filteredCsApi = item.report.cs_data.cs_api.filter(apiData =>
                        apiData[vulType] && apiData[vulType].length > 0
                    );
                    // console.log('filteredCsApi', filteredCsApi)
                    if (filteredCsApi.length > 0) {
                        return {
                            ...item,  // Spread the original item
                            report: {
                                ...item.report,
                                cs_data: {
                                    ...item.report.cs_data,
                                    cs_api: filteredCsApi  // Replace cs_api with the filtered data
                                }
                            }
                        };
                    }
                    return null; // If no match, return null to exclude this item
                })
                .filter(item => item !== null); // Filter out null entries
        }

        setFilteredData(filtered); // Set the filtered data
    };


    // console.log(selectedStatusCode)
    return (
        <div>
            {fetchError ?
                <div className={`empty-report ${minimize ? 'img' : 'none'}`}><p className='empty-msg'>Report is not generated. Please start the fuzzer or wait for a response</p></div> :
                (
                    reportData && reportData.length > 0 ? (
                        <div className='report-container'>
                            <div className='report-header'>
                                <div>
                                    {reportData.map((item, idx) => (
                                        <div key={idx} style={{ display: 'flex', gap: '10px' }}>
                                            <img src={back} alt='backbutton' onClick={() => navigate(-1)} style={{ cursor: 'pointer', width: '10px', height: '20px' }} />
                                            {item.cwe_info ? <div>{item.cwe_info}</div> : <div>{"All Risks"}</div>}
                                        </div>
                                    ))}
                                </div>
                                <div className="dropdownVul">

                                    <CustomDropdown
                                        options={vulOptions}
                                        selectedOption={selectedVulType}
                                        onSelect={handleVulTypeSelection}
                                    />

                                </div>

                            </div>

                            <section className='report-section'>
                                <div className='graphical-data'>
                                    <div id='res-code'>Response Code</div>
                                    <div id='donut-graph'><ReportChart statusCodeCounts={statusCodeCounts} /></div>
                                    {/* <div id='status-code'>Code</div> */}
                                    <div id='status-code'>
                                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                            <thead>
                                                <tr style={{ borderBottom: '1px solid #E5E7EB' }}>
                                                    <th style={{ border: 'none', color: '#6B7280', backgroundColor: '#fff', borderRadius: '10px' }}>
                                                        Status Code
                                                        <img src={filter} alt='filter' style={{ margin: "5px 0px -3px 8px", cursor: 'pointer' }} onClick={dropdownStatusCode} />
                                                    </th>

                                                    <th style={{ border: 'none', color: '#6B7280', backgroundColor: '#fff', borderRadius: '10px' }}>
                                                        <p style={{ margin: '10px 0px 0px -8px' }}>Count</p>
                                                    </th>
                                                </tr>
                                                {
                                                    isDropdownStatusCode && (
                                                        <div className='dropdown-statusMenu' style={{ position: 'absolute', zIndex: 1, backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '4px', padding: '10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', marginRight: '5px' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedStatusCode.includes('All')}
                                                                    onChange={() => handleStatusCodeSelection('All')}
                                                                />
                                                                <label style={{ display: 'block', marginLeft: '10px' }}>  All</label>
                                                            </div>
                                                            {
                                                                Object.keys(statusCodeCounts).map(statusCode => (
                                                                    <div key={statusCode} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                                        <input
                                                                            type='checkbox'
                                                                            checked={selectedStatusCode.includes(statusCode)}
                                                                            onChange={() => handleStatusCodeSelection(statusCode)}
                                                                        />
                                                                        <label style={{ marginLeft: '8px' }}>{statusCode}</label>
                                                                    </div>
                                                                ))
                                                            }
                                                            <button onClick={applyFilter} style={{ marginTop: '10px', color: '#000', backgroundColor: '#FFCB2D', border: '1px solid #FFCB2D', borderRadius: '8px' }}>Apply</button>
                                                        </div>
                                                    )
                                                }
                                            </thead>
                                            <tbody>
                                                {Object.entries(statusCodeCounts).map(([statusCode, count], index) => (
                                                    <tr key={statusCode} style={{ borderBottom: '1px solid #E5E7EB' }}>
                                                        <td style={{ border: 'none', padding: '8px' }}>{statusCode}</td>
                                                        <td style={{ border: 'none', padding: '8px' }}>{count}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>


                                </div>

                                <div className='api-dropdown'>
                                    {filteredData.map((reportItem, index) => (
                                        <div key={index} className='cwe-container'>
                                            {reportItem.report.cs_data.cs_api.map((apiData, apiIndex) => {
                                                // const attackData = Array.isArray(apiData.attack_api_data) ? apiData.attack_api_data : apiData.attack_api_data ? [apiData.attack_api_data] : [];
                                                //    console.log(JSON.stringify(attackData),"att")

                                                let attackData = [];
                                                if (selectedVulType === 'false_positive') {
                                                    attackData = Array.isArray(apiData.false_positive) ? apiData.false_positive : apiData.false_positive ? [apiData.false_positive] : [];
                                                } else if (selectedVulType === 'confirm_vulnerability') {
                                                    attackData = Array.isArray(apiData.confirm_vulnerability) ? apiData.confirm_vulnerability : apiData.confirm_vulnerability ? [apiData.confirm_vulnerability] : [];
                                                } else if (selectedVulType === 'more_analysis') {
                                                    attackData = Array.isArray(apiData.more_analysis) ? apiData.more_analysis : apiData.more_analysis ? [apiData.more_analysis] : [];
                                                } else {
                                                    // Default case: use attack_api_data
                                                    attackData = Array.isArray(apiData.attack_api_data) ? apiData.attack_api_data : apiData.attack_api_data ? [apiData.attack_api_data] : [];

                                                }
                                                const hasAttackData = attackData && attackData.length > 0;
                                                const showOriginal = showOriginalFor === apiIndex;


                                                // Extract URL from original request
                                                const extractedUrl = extractUrlFromCurl(apiData.orginal_req || '');
                                                return (
                                                    <div key={apiIndex}>
                                                        {/* {console.log(apiIndex)} */}
                                                        <div className={`cwe-attack ${expandedApi === apiIndex ? 'expanded' : ''}`} style={{ backgroundColor: expandedApi === apiIndex ? '#FFCB2D' : '#fff', border: expandedApi === apiIndex ? '1px solid #FFCB2D' : '' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => handleExpand(apiIndex)}>
                                                                {/* {hasData ? */}
                                                                {(apiData.orginal_req || hasAttackData) && (
                                                                    <div className='dropdown-header' >
                                                                        <div style={{ paddingRight: '10px' }} >
                                                                            {expandedApi === apiIndex ? (
                                                                                <img src={downArrow} alt='down' />
                                                                            ) : (
                                                                                <img src={arrowUp} alt='up' />
                                                                            )}
                                                                        </div>
                                                                        <div className='dropdown-title'>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }} >
                                                                                <div id='api-headerName'>{"Attack API data"} #{apiIndex + 1}</div>
                                                                                {/* show original API */}
                                                                                {/* <div id='api-headerName'> {extractedUrl} </div> */}

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )}

                                                                {Array.isArray(apiData.attack_api_data) && apiData.attack_api_data.length > 0 && (
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 10px 0px 0px' }}>
                                                                        <span className='slider-content'>{showOriginal ? 'Show Attack Data' : 'Show Originals'}</span>
                                                                        <div style={{ paddingLeft: '10px' }}>
                                                                            <label className='switch' >
                                                                                <input
                                                                                    type='checkbox'
                                                                                    checked={showOriginal}
                                                                                    onChange={(e) => {
                                                                                        e.stopPropagation();
                                                                                        toggleShowOriginals(apiIndex);
                                                                                    }}
                                                                                />
                                                                                <span className='slider' ></span>
                                                                            </label>

                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {expandedApi === apiIndex && (
                                                                <div className='dropdown-content'>
                                                                    {!showOriginal ? (
                                                                        hasAttackData ? (
                                                                            attackData.map((attack, attackIndex) => {
                                                                                const [attackType, attackParam, payload] = parseAttackVector(attack.attack_vector || '');

                                                                                return (
                                                                                    <div key={attackIndex} className='request-response-container'>
                                                                                        <div className='vector-box'>
                                                                                            <div id='attack-header' className='attack-header'><div>Attack Vector</div ><div id='blanks'> </div></div>
                                                                                            <pre id='pre-tag'>
                                                                                                <div id='attack-name'>Attack Type:  {attackType || 'N/A'}</div> <br /><br />
                                                                                                <div id='attack-name'>Attack Parameter:  {attackParam || 'N/A'}</div> <br /><br />
                                                                                                <div id='attack-name'>Payload:  {payload || 'N/A'}</div>
                                                                                            </pre>
                                                                                        </div>
                                                                                        <div className='request-box'>
                                                                                            <h4 id='attack-header'>Attack Request</h4>
                                                                                            <pre id='pre-tag'>{decodeString(attack.curl_req || 'N/A')}</pre>
                                                                                        </div>
                                                                                        <div className='response-box'>
                                                                                            <h4 id='attack-header'>Attack Response</h4>
                                                                                            <pre id='pre-tag'>{decodeString(attack.curl_res || 'N/A')}</pre>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <div>No Attack Data Available</div>
                                                                        )
                                                                    ) : (
                                                                        selectedStatusCode.includes('All') || selectedStatusCode.length === 0
                                                                            ? (<div className='original-request-response-container'>
                                                                                <div className='original-request-box'>
                                                                                    <div id='attack-header'>Original Request</div>
                                                                                    <pre id='pre-tag'>{decodeString(apiData.orginal_req || 'N/A')}</pre>
                                                                                </div>
                                                                                <div className='original-response-box'>
                                                                                    <div id='attack-header'>Original Response</div>
                                                                                    <pre id='pre-tag'>{decodeString(apiData.orginal_res || 'N/A')}</pre>
                                                                                </div>
                                                                            </div>)
                                                                            : (<div className='original-request-response-container'>
                                                                                <div className='original-request-box'>
                                                                                    <div id='attack-header'>Original Request</div>
                                                                                    <pre id='pre-tag'>{apiData.includeOriginal ? decodeString(apiData.orginal_req || 'N/A') : 'N/A'}</pre>
                                                                                </div>
                                                                                <div className='original-response-box'>
                                                                                    <div id='attack-header'>Original Response</div>
                                                                                    <pre id='pre-tag'>{apiData.includeOriginal ? decodeString(apiData.orginal_res || 'N/A') : 'N/A'}</pre>
                                                                                </div>
                                                                            </div>)
                                                                    )}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ))}
                                </div>

                            </section>
                        </div>
                    ) : (
                        <div className={`No-report ${minimize ? 'img' : 'none'}`}> </div>
                    )
                )}
        </div>
    );
};

export default CWEReport;



