import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Components/UserAuthentication/Login';
import SignUp from './Components/UserAuthentication/SignUp';
import Role from './Components/UserAuthentication/Role';
import DeveloperComponent from './Components/DeveloperMode/DeveloperComponent';
import CisoMode from './Components/CisoMode/CisoMode';
import AppDashboard from './Components/DeveloperMode/ApplicationDashboard/AppDashboard';
import FlowLevelDashboard from './Components/DeveloperMode/ApplicationDashboard/FlowLevelDashboard';
// import OWASPDashboard from './Components/DeveloperMode/ApplicationDashboard/OWASPDashboard';
import FlowsContainer from './Components/DeveloperMode/ApplicationDashboard/FlowsContainer';
import AddApplication from './Components/DeveloperMode/ApplicationDashboard/AddApplication';
import AddFlow from './Components/DeveloperMode/ApplicationDashboard/AddFlow';
import ReportContainer from './Components/DeveloperMode/ReportContainer/ReportContainer';
import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';
import PreFuzzing from './Components/DeveloperMode/Fuzzer/PreFuzzing';
import ReportTab from './Components/DeveloperMode/ReportContainer/ReportTab';




function App() {
    const [formData, setFormData] = useState({});
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || '');
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') || '');
    const [role, setRole] = useState([]);
    const [allAppNames, setAllAppNames] = useState([]);
    const [minimize,setMinimize]=useState(true);

    const apiUrl = process.env.REACT_APP_URL;

    useEffect(() => {
        if (!refreshToken) {
            setRefreshToken(localStorage.getItem('refreshToken'));
        }
    }, [refreshToken]);


    const googlelogin = async () => {
        try {
            console.log(`${apiUrl}/auth/login/google`)
            const response = await fetch(`${apiUrl}/auth/login/google`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },

            });
         
            if (response.ok) {
                const data = await response.json();

                if (data.access_token && data.refresh_token) {
                    localStorage.setItem('accessToken', data.access_token);
                    localStorage.setItem('refreshToken', data.refresh_token);
                    setAccessToken(data.access_token);
                    setRefreshToken(data.refresh_token);
                    return 'success';
                } else {
                    console.error("Access token or refresh token is missing in the response data.");
                    return 'error';
                }
            } else {
                return 'error';
            }
        } catch (error) {
            console.log("login error :", error);
            return 'error';
        }
    };

    const login = async (username, password) => {
        try {
            const response = await fetch(`${apiUrl}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password })
            });

            // console.log("response", response)
            if (response.ok) {
                const data = await response.json();

                if (data.access_token && data.refresh_token) {
                    localStorage.setItem('accessToken', data.access_token);
                    localStorage.setItem('refreshToken', data.refresh_token);
                    setAccessToken(data.access_token);
                    setRefreshToken(data.refresh_token);

                    const decodeToken = jwtDecode(data.access_token);
                    const roles = decodeToken.resource_access?.APISecurist?.roles || [];
                    const firstName = decodeToken.given_name || '';
                    Cookies.set('roles', JSON.stringify(roles), { expires: 1 }); // 1 day expiration
                    Cookies.set('firstName', firstName, { expires: 1 });
                    // Cookies.set('lastName', lastName, { expires: 1 });
    
                    return 'success';
                } else {
                    console.error("Access token or refresh token is missing in the response data.");
                    return 'error';
                }
            } else {
                return 'error';
            }
        } catch (error) {
            console.log("login error :", error);
            return 'error';
        }
    };


    const refreshedToken = async () => {
        try {
          
            const response = await fetch(`${apiUrl}/auth/refresh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({"refresh_token": refreshToken})
            });
         
            if (response.ok) {
                const data = await response.json();
                setAccessToken(data.access_token);
                localStorage.setItem('accessToken', data.access_token);
                setRefreshToken(data.refresh_token);
                localStorage.setItem('refreshToken', data.refresh_token);

                return "success";
            } else {
                return 'error';
            }
        } catch (error) {
            console.log('token refresh error', error);
        }
    };





    const clearToken = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setAccessToken('');
        setRefreshToken('');
    }


    const logout = async () => {
        try {
            const response = await fetch(`${apiUrl}/auth/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ "refresh_token": refreshToken }),

            });
            if (response.ok) {
                clearToken();
            } else {
                return 'error'
            }

        } catch (error) {
            console.error('logout error:', error);
        }
    }



    const signup = async (userData) => {
        try {
            const response = await fetch(`${apiUrl}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (response.ok) {
                //   const data = await response.json();
                return "success"

            }
            else {
                if (response.status === 409) {
                    return 'user_present';
                } else {
                    return 'invalid_credentials'; // Set status to invalid_credentials
                }

            }
        } catch (error) {
            console.error('Signup error:', error);
        }
    };

    const rolesFromCookies = JSON.parse(Cookies.get('roles') || '[]');
 
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path='/' element={<Login onLogin={login} googlelogin={googlelogin}  />} />
                    <Route path='/signup' element={<SignUp onSignup={signup} role={role} setFormData={setFormData} />} />
                    <Route path='/role' element={<Role onSignup={signup} setRole={setRole} formData={formData} />} />
                    <Route path="/dashboard" element={rolesFromCookies[0] === 'CISO' ? <CisoMode onLogout={logout}  onRefreshToken={refreshedToken} minimize={minimize} setMinimize={setMinimize}/> : <DeveloperComponent onLogout={logout}  onRefreshToken={refreshedToken} setAllAppNames={setAllAppNames}  allAppNames={allAppNames} minimize={minimize} setMinimize={setMinimize}/>} />
                    <Route path='/application/:appId' element={<AppDashboard onLogout={logout} onRefreshToken={refreshedToken} accessToken={accessToken} minimize={minimize} setMinimize={setMinimize} />} />
                    <Route path='/application/:appId/:workflowId' element={<FlowLevelDashboard onLogout={logout} onRefreshToken={refreshedToken} minimize={minimize} setMinimize={setMinimize} />} />
                    {/* <Route path='/application/:appId/:workflowId/:runName' element={<OWASPDashboard onLogout={logout} onRefreshToken={refreshedToken} minimize={minimize} setMinimize={setMinimize} />} /> */}
                    <Route path='/:appId/Workflows' element={<FlowsContainer onLogout={logout} onRefreshToken={refreshedToken} minimize={minimize} setMinimize={setMinimize} />} />
                    {/* <Route path='/:appId/Reports' element={<ReportTab onLogout={logout} onRefreshToken={refreshedToken} minimize={minimize} setMinimize={setMinimize}/>} /> */}
                    <Route path='/add_application' element={<AddApplication onLogout={logout} accessToken={accessToken} allAppNames={allAppNames} minimize={minimize} setMinimize={setMinimize}/>} />
                    <Route path='/add_flow' element={<AddFlow onLogout={logout} accessToken={accessToken} minimize={minimize} setMinimize={setMinimize}/>} />
                    <Route path='/application/:appId/:workflowId/:runName' element={<PreFuzzing onLogout={logout} onRefreshToken={refreshedToken}  minimize={minimize} setMinimize={setMinimize}/>} /> 
                    <Route path='/application/:appId/:workflowId/:runName/report' element={<ReportContainer onLogout={logout} onRefreshToken={refreshedToken} minimize={minimize} setMinimize={setMinimize}/>} />
                    {/* Temp reports */}
                    <Route path='/Reports' element={<ReportTab onLogout={logout} onRefreshToken={refreshedToken} minimize={minimize} setMinimize={setMinimize}/>} />

                </Routes>

            </Router>
        </div>
    );
}

export default App;
