// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
    /* border: 1px solid; */
    margin-top: 20px;
    overflow-y: auto; 
    border-radius: 10px;

    margin-right: 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
}
`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/AppDashboard/MainContent.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;;IAEnB,kBAAkB;IAClB,oCAAoC;AACxC","sourcesContent":[".content-container {\n    /* border: 1px solid; */\n    margin-top: 20px;\n    overflow-y: auto; \n    border-radius: 10px;\n\n    margin-right: 20px;\n    box-shadow: 0 0 20px rgba(0,0,0,0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
