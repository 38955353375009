// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top10{
    display: flex;
    justify-content: space-between;
    font-family:'DM Sans' ;
    font-weight: 500;
    font-size: 20px;
    padding-top: 5px;
    line-height: 26.04px;
    letter-spacing: -0.2px;
}`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/Graph_content/Top10CWE.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;AAC1B","sourcesContent":[".top10{\n    display: flex;\n    justify-content: space-between;\n    font-family:'DM Sans' ;\n    font-weight: 500;\n    font-size: 20px;\n    padding-top: 5px;\n    line-height: 26.04px;\n    letter-spacing: -0.2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
