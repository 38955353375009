import React, { useState, useEffect } from 'react';
import '../../../Styles/DeveloperMode/PopUpUtils/Confirm_fuzz.css';
import close from '../../../Images/close.svg';
import whiteCross from '../../../Images/whiteCross.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack'; 
import '@fontsource/dm-sans';
import { useSnackbarContext } from '../../StateManagement/SnackbarContext';
import "../../../Styles/DeveloperMode/Notification/SnackbarMUI.css";

function ConfirmFuzz_Modal({ collectRawDataId, CWEName, CWEDesc, isOpen, setIsOpen, CWEUrl, combineCWE, collectAPI, OWASPName,setViewReport }) {
  const [selectRadio, setSelectRadio] = useState('prob');
  const [showApiDropdown, setShowApiDropdown] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [message, setMessage] = useState('');
  // const [showStatus, setShowStatus] = useState(false);
  const apiUrl = process.env.REACT_APP_URL;
  const [isFuzzButtonDisabled, setIsFuzzButtonDisabled] = useState(false);
  const {enqueueSnackbar, closeSnackbar } = useSnackbar(); //notification animation
  const {showSnackbar}=useSnackbarContext();

  useEffect(() => {
    if (!CWEName) {
      setMessage('Please select a Security Risk to check threats.');
    } else {
      setMessage('');
    }
  }, [CWEName]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = collectAPI;

  const handleChange = (event) => {
    const { value } = event.target;
    setPersonName(value);
  };

  const handleOptionChange = (e) => {
    const { value } = e.target;
    setSelectRadio(value);

    if (value === 'exploit') {
      setShowApiDropdown(true);
    } else {
      setShowApiDropdown(false);
    }
  };
  // console.log("threat idss",JSON.stringify({ threat_ids: collectRawDataId,cwe_info:combineCWE },null,2))

  const localToken = localStorage.getItem('accessToken');
  const url = `${apiUrl}/fuzzer/fuzz`;

  const handleFuzzing = async () => {
    // setViewReport(true);
    setTimeout(()=>{
      setIsOpen(false);
    },3000)
    if (selectRadio === 'prob') {
      //for light shade
      const snackbarId = enqueueSnackbar('Generating report...', {
        variant: 'info',
        action: <button onClick={() => closeSnackbar(snackbarId)}  style={{backgroundColor:'#ddffff',border:'none'}}>  
          <img src={close} alt='close' style={{width:'15px',height:'15px',cursor:'pointer'}} />
        </button>,
        persist: true, 
        className:'snackbar-info'
      });


      //for dark shade
      // const snackbarId = enqueueSnackbar('Generating report...', {
      //   variant: 'info',
      //   action: <button onClick={() => closeSnackbar(snackbarId)}  style={{backgroundColor:'#2196f3',border:'none'}}>  
      //     <img src={whiteCross} alt='close' style={{width:'15px',height:'15px',cursor:'pointer'}} />
      //   </button>,
      //   persist: true, 
      //   className:'snackbar-info'
      // });

      // setShowStatus(!showStatus);
      setIsFuzzButtonDisabled(true); // Disable the button after click
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localToken}`,
          },
          body: JSON.stringify({ threat_data_ids: collectRawDataId, cwe_info: combineCWE })
        })


        const result = await response.json();
        const reportId = result['report_id'];
        if (reportId) {
          // console.log("fuzzing result", result)
          Cookies.set("combineCWE", reportId, { expires: 7 });
          showSnackbar('Report is generated now. You can check it in the Reports tab.', { variant: 'success' ,className:'snackbar-success'});
          setViewReport(true);
        }
      if (!reportId) {
          showSnackbar('Report is not generated. Due to some technical error.', { variant: 'error' ,className:'snackbar-error'});
        }
      }
      catch (error) {
        console.log("Error during fuzzing ", error)
        showSnackbar('An error occurred during fuzzing.', { variant: 'error',className:'snackbar-error' }); 
      }
    } else {
      // case for exploit radio button
    }

  }


  return (
    <div>
      {message ? (
        <div className={`popup-container1 ${isOpen ? 'show' : ''}`} >
          <p style={{ marginRight: '20px' }}>{message}</p>

          <button className="close-btn" style={{ marginTop: "5px" }} onClick={() => {setIsOpen(false);}}>
            <img src={close} alt='close' />
          </button>
        </div>) : (

        <div className={`popup-container ${isOpen ? 'show' : ''}`}>
          <div className="popup">

            <div>
              <div className="popup-header">
                <div className='header'>Confirm Now</div>
                <button className="close-btn" onClick={() => setIsOpen(false)}>
                  <img src={close} alt='close' />
                </button>
              </div>

              <div className="popup-body">

                <div className='trimCWE'>
                  <div id='trimCWE'>{OWASPName}</div>
                  <p id='desc'>{CWEName} : {CWEDesc}</p>
                  <a href={CWEUrl} target="_blank" rel="noreferrer" >{CWEUrl}</a>
                </div>

              </div>
            </div>

            <div>
              {/* <form> */}
              <div className='radioForm'>
                <div className='prob'>
                  <input type='radio' id='prob' name='prob' value='prob'
                    onChange={handleOptionChange}
                    checked={selectRadio === 'prob'} />
                  Probe
                </div>

                <div className='exploit'>
                  <input type='radio' id='exploit' name='exploit' value='exploit'
                    onChange={handleOptionChange}
                    checked={selectRadio === 'exploit'} />
                  Exploit
                </div>
              </div>

              {showApiDropdown && CWEDesc && (
                <div className='formControl'>
                  <div >
                    <FormControl sx={{ width: 480, border: 'none' }} className='select' >
                      <InputLabel id="demo-multiple-checkbox-label">Select API</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Select API" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.includes(name)} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              <div className='submitButton'>
                <button type='submit' className='fuzzNowBtn' onClick={handleFuzzing} disabled={isFuzzButtonDisabled}>Verify Now</button>
              </div>
              {/* </form> */}
              {/* {showStatus ? "Fuzzing is in progress and report would be available in the Reports tab" : ""} */}
            </div>

          </div>
        </div>
      )}



    </div>
  );
}

export default ConfirmFuzz_Modal;
