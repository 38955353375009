
import React from 'react';
import '../../Styles/UserAuthentication/User.css';
import Avatar from '@mui/material/Avatar';
import image3 from '../../Images/profile.svg'
import Cookies from 'js-cookie';


const User = ({ minimize }) => {

    const rolesFromCookies = JSON.parse(Cookies.get('roles') || '[]');
    const firstNameFromCookies = Cookies.get('firstName') || '';
    // const lastNameFromCookies = Cookies.get('lastName') || '';

    return (
        <div className={`user`}>
            <div className={`icon`}>

                <div className='user-container' style={{ marginLeft: '0px' }}>
                    {minimize ?
                        <div className='userFromToken'>
                            <Avatar alt="s" src={image3} sx={{ width: 30, height: 30, marginTop: "5px" }} />
                            <div className='setuser' style={{ cursor: 'pointer' }} >
                                <p id='userName'>{firstNameFromCookies}  </p>
                                <p id='roleName'>{rolesFromCookies}</p>
                            </div>
                        </div>
                        :
                        <div className='userFromToken'>
                            <Avatar alt="s" src={image3} sx={{ width: 30, height: 30, marginTop: "5px" }} />
                        </div>
                    }


                </div>


            </div>

        </div>
    );
};

export default User;
