import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../../Styles/DeveloperMode/AppDashboard/LeftSidebar.css';
import logo from '../../../Images/Group 1000004408.svg';
import vector from '../../../Images/overview1.svg';
import icon1 from '../../../Images/data.svg';
import icon2 from '../../../Images/note.svg';
import icon3 from '../../../Images/maximize-2.svg';
import icon4 from '../../../Images/align-left.svg';
import icon5 from '../../../Images/Icon (from Tabler.io) (4).svg';
import icon6 from '../../../Images/logout.svg';
import expand from '../../../Images/arrow-circle-right.svg';
import User from '../../UserAuthentication/User';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';

const LeftSidebar = ({ onLogout,setMinimize, minimize}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { appname, setAppname } = useWorkflowDetails();
  const { workflowId, runName } = useParams();


  useEffect(() => {
    const storeapp = localStorage.getItem('appname');
    if (storeapp) {
      setAppname(storeapp);
    }
  }, [setAppname]);
  useEffect(() => {

    const pathname = decodeURIComponent(location.pathname);


    let activeItem = '';
    if (pathname.includes(`/application/${appname}`)|| pathname.includes(`report`)) {
      activeItem = 'Overview';
    } else if (pathname.includes(`/${appname}/Workflows`)) {
      activeItem = 'Workflows';
    // } else if (pathname.includes(`/${appname}/Reports`)) {
    } else if (pathname.includes(`/Reports`)) {
      activeItem = 'Reports';
    } else if (pathname.includes(`/${appname}/activity-planner`)) {
      activeItem = 'Run Activity & Planner';
    } else if (pathname.includes(`/${appname}/threat-matrix`)) {
      activeItem = 'Threat Matrix';
    } else if (pathname.includes(`/${appname}/settings`)) {
      activeItem = 'Settings';
    }

    // Set the active item class based on the updated active item
    const menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach(item => {
      if (item.textContent.trim() === activeItem) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  }, [location.pathname,appname]);

  const handleItemClick = (item) => {
    switch (item) {
      case 'Overview':
        navigate(`/dashboard`);
        break;
      case 'Workflows':
        navigate(`/${appname}/Workflows`);
        break;
      case 'Reports':
        // navigate(`/${appname}/Reports`);
        navigate(`/Reports`);
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  const handleMaximize =()=>{
    setMinimize(false);
  }

  return (
    <div className='menu'>
      <div className='menu-form'>
        <img className='WasLogo' src={logo} alt='logo'/>
        <div className='shrink-arrow'>
            <img className='shrink-sidebar' src={expand} alt='icon' onClick={handleMaximize} style={{marginRight:'-50px'}} />
            </div>
        <div className='All-item'>
          <div>
            <div className="menu-item" onClick={() => handleItemClick('Overview')}>
              <img className='WasImg' src={vector} alt='vector' />
              Overview
            </div>
          </div>

          <div>
            <div className="menu-item" onClick={() => handleItemClick('Workflows')}>
              <img className='WasImg' src={icon1} alt='icon1' />
              Workflows
            </div>
          </div>

          <div className="menu-item" onClick={() => handleItemClick('Reports')}>
            <img className='WasImg' src={icon2} alt='icon2' />
            Reports
          </div>
          <div className="menu-item" onClick={() => handleItemClick('Run Activity & Planner')}>
            <img className='WasImg' src={icon3} alt='icon3' />
            Run Activity & Planner
          </div>

          <div className="menu-item" onClick={() => handleItemClick('Threat Matrix')}>
            <img className='WasImg' src={icon4} alt='icon3' />
            Threat Matrix
          </div>
        </div>

        <div className='second-menu'>
          <div className='user-role1'>
            {/* <Stack> */}
            <User minimize={minimize}/>
            {/* </Stack> */}
          </div>
          <div className='user-role'>
            <div className="menu-item" onClick={() => handleItemClick('Settings')}>
              <img className='WasImg' src={icon5} alt='icon3' />
              Settings
            </div>

            <div className='menu-item' onClick={handleLogout} style={{ color: '#EB5050' }}>
              <img className='WasImg' src={icon6} alt='icon5' />
              Log Out
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSidebar;
