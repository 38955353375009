// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snackbar-warning{
    background-color: #ffffcc;
    color: #000;

    /* background-color: #ffeb3b;
    color: #000; */
  }
.snackbar-success{
    background-color: #ddffdd;
    color: #000;

    /* background-color: #4CAF50;
    color: #fff; */
  }
.snackbar-error{
    background-color: #ffdddd;
    color: #000;

    /* background-color: #f44336;
    color: #fff; */
}

.snackbar-info{
    background-color: #ddffff;
    color: #000;

    /* background-color: #2196F3;
    color: #fff;  */
}`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/Notification/SnackbarMUI.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;;IAEX;kBACc;EAChB;AACF;IACI,yBAAyB;IACzB,WAAW;;IAEX;kBACc;EAChB;AACF;IACI,yBAAyB;IACzB,WAAW;;IAEX;kBACc;AAClB;;AAEA;IACI,yBAAyB;IACzB,WAAW;;IAEX;mBACe;AACnB","sourcesContent":[".snackbar-warning{\n    background-color: #ffffcc;\n    color: #000;\n\n    /* background-color: #ffeb3b;\n    color: #000; */\n  }\n.snackbar-success{\n    background-color: #ddffdd;\n    color: #000;\n\n    /* background-color: #4CAF50;\n    color: #fff; */\n  }\n.snackbar-error{\n    background-color: #ffdddd;\n    color: #000;\n\n    /* background-color: #f44336;\n    color: #fff; */\n}\n\n.snackbar-info{\n    background-color: #ddffff;\n    color: #000;\n\n    /* background-color: #2196F3;\n    color: #fff;  */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
