import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import '../../../Styles/DeveloperMode/Graph_content/ThreatStatus.css'
import dataNotFound from '../../../Images/notDatafound.svg';
import '@fontsource/dm-sans'
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
import { useNavigate } from 'react-router-dom';


const ThreatStatus = ({ importThreatCount, displayItems, onRefreshToken }) => {
    const [accessTokenUpdated, setAccessTokenUpdated] = useState('');
    const [totalThreat, setTotalThreat] = useState('');
    const apiUrl = process.env.REACT_APP_URL;
    const { app_id } = useWorkflowDetails();
    const [selectedOption] = useState('Vulnerability');
    const navigate = useNavigate();
    const dropdownOptions = ['Critical', 'High', 'Medium', 'Low'];

    const data = {
        Vulnerability: [55, 41, 17, 15],
    };

    const series = data[selectedOption];

    const options = {

        chart: {
            width: 380,
            type: 'donut',
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 270
            },
        },
        dataLabels: {
            enabled: false

        },
        fill: {
            type: 'gradient',
        },
        legend: {

            formatter: function (val, opts) {
                const category = dropdownOptions[opts.seriesIndex];
                return category
            },

            itemMargin: {
                vertical: 4,
            },
            horizontalAlign: 'left',
            labels: {
                colors: '#7D7D7D'
            },
        },

        colors: ['#EB5050', '#FFA500', '#FFCB2D', '#5BA7FF'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        annotations: {
            points: [{
                x: '50%',
                y: '50%',
                // text: totalThreat.toString(),
                textAlign: 'center',
                offsetY: 0,
                style: {
                    fontSize: '24px',
                    color: '#000',
                }
            }]
        }
    };

    useEffect(() => {
        totalThreatCount();
    }, [app_id]);

    useEffect(() => {
        if (accessTokenUpdated) {
            totalThreatCount();
        }
    }, [accessTokenUpdated]);

    const totalThreatCount = async () => {
        const localToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${apiUrl}/resources/apps/${app_id}/flows`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                const totalThreatCount = data.reduce((acc, current) => {
                    return acc + (current.total_threat_count || 0);
                }, 0);

                setTotalThreat(totalThreatCount);
                importThreatCount(totalThreatCount)
            }
            else if (response.status === 400) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                }
                else {
                    navigate('/');
                }
            }
            else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                }
                else {
                    navigate('/');
                }
          }    
        } catch (e) {
            console.error(e, "threat count not present ")
        }
    }

    return (
        <div >
            {!displayItems ?
                <div className='graphdata-not-found'>
                    <p className={`chart-title1`}>Threat Status</p>
                    <img src={dataNotFound} alt='data not found' />
                </div> :
                <div>
                    <div className='threat-header'>
                        <p className={`chart-title`}>Threat Status</p>
                        <div className='scan-time'>
                            <p> Last Threat Scanning</p>
                            <div id='percent'>__:__ PM</div>
                        </div>
                    </div>

                    <div className="threatStatus"  >
                        <div id="legend-dropdown-container">
                            {/* <pre>threat:{totalThreat}</pre> */}
                            <ReactApexChart options={options} series={series} type="donut" width={310} />
                        </div>
                        <div className='totalThreat'>{totalThreat}</div>
                        <div className='totalThreat1'>Occurrences</div>
                    </div>
                    <div className='resolve-rate'>
                        <p> Average Resolve Rate</p>
                        <div id='percent'>__%</div>
                    </div>
                </div>
            }
        </div>
    );
};

export default ThreatStatus;