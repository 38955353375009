// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
    display: flex;
    padding-left: 25px;
    align-items: center;
    font-size: 14px;
    font-weight: regular;
    font-family: 'Inter', sans-serif;
    color:#1d1d1d ;
}

.icon p {
    padding-left: 10px;
}

.userFromToken {
    display: flex;
    margin: 3px;
}

.setuser {
    margin-top:-10px;
    margin-left: 0px;
    display: grid;
    grid-template-rows: auto auto;
    /* border: 1px solid; */
}

#userName {
    margin-right: 4px;
    display: flex;
    justify-content: space-between;
}

#roleName {
    margin-left: 5px;
    margin-top: -10px;
    padding-right: 5px;
    padding-left: 5px;
    background-color: #FFCD71;
    border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/UserAuthentication/User.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,oBAAoB;IACpB,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,6BAA6B;IAC7B,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".icon {\n    display: flex;\n    padding-left: 25px;\n    align-items: center;\n    font-size: 14px;\n    font-weight: regular;\n    font-family: 'Inter', sans-serif;\n    color:#1d1d1d ;\n}\n\n.icon p {\n    padding-left: 10px;\n}\n\n.userFromToken {\n    display: flex;\n    margin: 3px;\n}\n\n.setuser {\n    margin-top:-10px;\n    margin-left: 0px;\n    display: grid;\n    grid-template-rows: auto auto;\n    /* border: 1px solid; */\n}\n\n#userName {\n    margin-right: 4px;\n    display: flex;\n    justify-content: space-between;\n}\n\n#roleName {\n    margin-left: 5px;\n    margin-top: -10px;\n    padding-right: 5px;\n    padding-left: 5px;\n    background-color: #FFCD71;\n    border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
