import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import '../../Styles/UserAuthentication/Role.css';
import logo from '../../Images/Group 1000004408.svg';
import leftArrow from '../../Images/arrow-left.svg';
import mode from '../../Images/mode.svg';

const Role = ({ formData, setRole, onSignup }) => {
  const navigate = useNavigate();
  const [role, setLocalRole] = useState('');
  const [status, setStatus] = useState('');

  
  const selectMode = async () => {
    const updatedUserData = { ...formData, role };
    const result = await onSignup(updatedUserData);

    if (result === 'success') {
      navigate('/', { state: { signupSuccess: true } });
    } else if (result === 'user_present') {
      setStatus('user_present');
    } else {
      setStatus('invalid_credentials');
    }
  };

  const handleRoleSelection = (selectedRole) => {
    setLocalRole(selectedRole);
    setRole(selectedRole)
  }


  return (
    <div className='role'>

      <img src={logo} alt='logo' id='logoApi' />
      <div className='roleForm'>
        <div id='sign-arrow'>
          <img src={leftArrow} alt='left-arrow' onClick={() => navigate('/signup')} style={{ cursor: 'pointer' }} />
          <div id='role-signIn'>Sign Up</div>

        </div>
        <p id='role-txt'>Select Your Role</p>

        <Stack spacing={2} direction="row" className='roleWise'>
          <button style={{ backgroundColor: role === 'CISO' ? '#FFD042' : 'initial', color: role === 'CISO' ? 'black' : 'initial' }} className='roleCISO' onClick={() => handleRoleSelection('CISO')}>
            <img src={mode} alt='mode' />
            CISO</button>
          <button style={{ backgroundColor: role === 'Developer' ? '#FFD042' : 'initial', color: role === 'Developer' ? 'black' : 'initial' }} className='roleDev' onClick={() => handleRoleSelection('Developer')}>
            <img src={mode} alt='mode' />
            Developer</button>
        </Stack>

        <div className='wrap-role'>
          <button type='submit' className='btn-role' onClick={selectMode}>Next</button>
        </div>
        {status === 'user_present' && (
          <span style={{ color: 'red' }}>This email address is already registered.</span>
        )}
        {status === 'invalid_credentials' && (
          <span style={{ color: 'red' }}>An error has occurred. Please contact API Securist Administrator.</span>
        )}
        <p id='col-4'>Already a member ? <Link to='/' style={{ color: "black", fontWeight: "bold" }} >Login</Link></p>
      </div>
    </div>

  )
}

export default Role;

