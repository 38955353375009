// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBar {
    max-width: 240px; 
    margin: 0px;
    /* padding-top: 10px; */
    padding-bottom: 15px;
}

.searchInput {
    display: flex;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    height: 30px;
}

.searchInput.dark {
    background-color: #313131;
}

.searchInput.light {
    background-color: #f0efef;
}
  
.searchInput input {
    flex: 1 1;
    /* padding-top: 25px; */
    height: 15px;
    width: 130px;
    border: none;
    outline: none;
    background: transparent;
    color: #1d1d1d; /* Default text color */
}
  
.searchInput.dark input {
    color: #ffffff;
}
  
.searchInput button {
    border: none;
    background: transparent;
    /* padding-bottom: 10px; */
    cursor: pointer;
}
  
.searchInput button img {
    width: 15px; 
    height: 15px; 
    /* padding-bottom: 15px; */
    padding-right: 5px;
}
  
.searchInput p {
    /* padding-bottom: 15px; */
   /* margin-left: -10px; */
}

#textlight {
    padding-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/Navbar/SearchBar.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,SAAO;IACP,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,cAAc,EAAE,uBAAuB;AAC3C;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;GAC3B,wBAAwB;AAC3B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".searchBar {\n    max-width: 240px; \n    margin: 0px;\n    /* padding-top: 10px; */\n    padding-bottom: 15px;\n}\n\n.searchInput {\n    display: flex;\n    align-items: center;\n    border-radius: 10px;\n    overflow: hidden;\n    height: 30px;\n}\n\n.searchInput.dark {\n    background-color: #313131;\n}\n\n.searchInput.light {\n    background-color: #f0efef;\n}\n  \n.searchInput input {\n    flex: 1;\n    /* padding-top: 25px; */\n    height: 15px;\n    width: 130px;\n    border: none;\n    outline: none;\n    background: transparent;\n    color: #1d1d1d; /* Default text color */\n}\n  \n.searchInput.dark input {\n    color: #ffffff;\n}\n  \n.searchInput button {\n    border: none;\n    background: transparent;\n    /* padding-bottom: 10px; */\n    cursor: pointer;\n}\n  \n.searchInput button img {\n    width: 15px; \n    height: 15px; \n    /* padding-bottom: 15px; */\n    padding-right: 5px;\n}\n  \n.searchInput p {\n    /* padding-bottom: 15px; */\n   /* margin-left: -10px; */\n}\n\n#textlight {\n    padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
