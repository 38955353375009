import "../../../Styles/DeveloperMode/PopUpUtils/UploadScript.css"
import "../../../Styles/DeveloperMode/Notification/SnackbarMUI.css"
import React, { useRef, useState } from 'react';
import upload from "../../../Images/export.svg";
import Image from "../../../Images/File.svg";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "../../StateManagement/SnackbarContext";

const UploadScript = ({setCloseScriptForm,setOpenForm}) => {
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [fileName, setFileName] = useState('');
    const [nextClicked, setNextClicked] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadImage, setUploadImage] = useState(upload); 
    const [flag, setFlag] = useState(false); 
    const localToken = localStorage.getItem('accessToken');
    const apiUrl = process.env.REACT_APP_URL;
    const {showSnackbar}=useSnackbarContext();


    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        fileInputRef.current.files = event.dataTransfer.files;
        handleFiles(event.dataTransfer.files);
    };
    const handleFiles = (files) => {
        if (files.length > 0) {
            // setFileName(files[0].name);
            const file=files[0];
            const fileExtension=file.name.split('.').pop().toLowerCase();
            const validExtensions=['json'];

            if(!validExtensions.includes(fileExtension)){
                showSnackbar('Please upload a .json file.',{variant:'warning', className:'snackbar-warning'});
                return;
            }

            if(file.size ===0){
                showSnackbar('The file size should not be 0 bytes.',{variant:'warning',className:'snackbar-warning'});
                return;
            }

            const reader =new FileReader();
            reader.onload=(e)=>{
                try{
                    const json=JSON.parse(e.target.result);
                    if(!json){
                        throw new Error('Invalid JSON format.');
                    }
                    setFileName(file.name);
                    console.log(file.name)
                    setFile(file);
                    setUploadImage(Image);
                    setFlag(true);
                }catch (error){
                    showSnackbar('The file is not in valid JSON format.',{variant:'warning',className:'snackbar-warning'});
                    setFileName('');
                    setFile(null);
                    setUploadImage(upload);
                    setFlag(false);
                }
            };
            reader.onerror=()=>{
                showSnackbar('Error reading file.',{variant:'warning',className:'snackbar-warning'});
            };
            reader.readAsText(file);
        }
    };
//"/resources/plugin-upload"
    const handleSubmit=async(e)=>{
        e.preventDefault();
        if(!fileName){
            showSnackbar('Please upload a file.',{variant:'warning',className:'snackbar-warning'});
            return;
        }
        if(!file){
            showSnackbar('No file selected.',{variant:'warning',className:'snackbar-warning'});
            return;
        }
        const formData=new FormData();
        formData.append('file',file);
        // for (let [key, value] of formData.entries()) {
        //     console.log(`${key}:`, value);
        // }
        try {
            showSnackbar('Uploading...',{variant:'info',persist:true,className:'snackbar-info'})
            const response =await fetch(`${apiUrl}/resources/raw-data-file-upload`,{
                headers:{
                    Authorization:`Bearer ${localToken}`,
                },
                method:'POST',
                body:formData,
            });
            const responseBody=await response.json();
            console.log("Response body:", responseBody);
            if(response.ok){
                showSnackbar('File uploaded successfully. Information will be ready in just a few moments.',{variant:'success',className:'snackbar-success'});
                setTimeout(()=>{
                    navigate('/dashboard');
                },2000);
                console.log(responseBody);   
            }else{
                showSnackbar('Failed to upload file',{variant:'error',className:'snackbar-error'});
                setTimeout(()=>{
                    setOpenForm(false)
                },2000);
            }
        } catch (error) {
            console.error('Error:',error);
            
        }
    };


    const handleNextClick = (e) => {
        e.preventDefault();
        if(fileName){
            setNextClicked(true);
            handleSubmit(e);
            setCloseScriptForm(false);
            // navigate(`/dashboard`);
        }else {
            showSnackbar('Please provide the required inputs.',{variant:'warning',className:'snackbar-warning'});
        }
    }

    return (
        <div className='uploadScript'>
            <div className='extractScript'>
                <div id="uploadsc">Upload Raw Data</div>
                <div
                    className="upload-box"
                    onClick={handleClick}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <img src={uploadImage} alt="Upload Icon" />
                  {flag?  <p style={{color:"#595D62"}}>{fileName}</p>: <p style={{ color: "#595D62" }}>Click or drag to upload file</p>}
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFiles(e.target.files)}
                        required
                   />
                </div>

                {/* <div className='file-name-input'>
                    <div className="filename">Uploaded file</div>
                    <input type="text" value={fileName} readOnly />
                </div> */}

                <div className='choose-btn'>
                    <button className='choose-back' style={{ backgroundColor: 'initial', color: 'initial', border: '1px solid',cursor:"pointer" }} onClick={() => setCloseScriptForm(false)}>
                        Back
                    </button>
                    <button className='choose-nxt'  style={{ border: nextClicked ? 'none' : 'none', backgroundColor: nextClicked ? '#FFD042' : '#FFD042', color: nextClicked ? 'black' : 'initial',cursor:"pointer" }} onClick={handleNextClick}>
                        Upload
                    </button>
                </div>
            </div>

        </div>

    );
};

export default UploadScript;
