import '../../../Styles/DeveloperMode/Graph_content/CWEmatrix.css';

import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const AppCWEMatric = () => {
    const [chartData] = useState({
        series: [{
            name: 'Threats',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
                height: 250
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '25%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#25CD25'],
            xaxis: {
                categories: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10'],
                labels: {
                    show: true,
                    style: {
                        colors: '#868585'
                    }
                },
                grid: {
                    row: {
                        opacity: 0,
                    },
                    column: {
                        opacity: 0
                    },
                    padding: {
                        top: 0,
                        bottom: 0
                    }
                },
                fill: {
                    opacity: 1
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
            },
            stroke: {
                show: false,
                width: 2,
                colors: ['transparent']
            },
            // xaxis: {
            //     categories: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10'],
            // },
        }
    });

    return (
        <div>
            <div id="chart111">
                <div className={`cweMatriApp_CWE_matricc1`}> Top 10 Vulnerabilities</div>
                <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={260} style={{marginLeft:'30px'}} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default AppCWEMatric;
