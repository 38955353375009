
import React, { useEffect, useState } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import '../../Styles/CisoMode/FeedbackQue.css';
import close from '../../Images/close.svg';
import search from '../../Images/searchBlack.svg';

export default function FeedbackQue({ formData, setFormData, onComplete, backStep, setCompleteForm }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [selectedOptions1, setSelectedOptions1] = React.useState([]);
    const [selectedOptions2, setSelectedOptions2] = React.useState([]);
    const [selectedOptions4, setSelectedOptions4] = React.useState([]);
    const [selectedRadio, setSelectedRadio] = React.useState('');
    const [selectedOptions6, setSelectedOptions6] = React.useState([]);
    const [searchKeyword, setSearchKeyword] = React.useState([]);
    const [keywords, setKeywords] = React.useState([]);
    const [nextDisable, setNextDisable] = React.useState(true);

    const handleSearchChange = (e) => {
        
        setSearchKeyword(e.target.value);
        if (e.target.value.trim() !== '') {
            setKeywords(e.target.value.split(' '));
          
        } 
        else {
            setKeywords([]);
        }
       
    };

    const handleRemoveKeyword = (index) => {
        setKeywords(keywords.filter((_, i) => i !== index));
        setSearchKeyword('');
    };

    const handleNext = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 6) {
            updateFormData();
            onComplete(formData);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            backStep()
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (activeStep === 6) {
            updateFormData();
            onComplete(formData);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const toggleOption = (option) => {
        setSelectedOptions((prevSelectedOptions) => {
            if (option === 'None') {
                return ['None'];
            }
            else if (prevSelectedOptions.includes(option)) {
                return prevSelectedOptions.filter((selected) => selected !== option);
            } else {
                return prevSelectedOptions.includes('None') ? [option] : [...prevSelectedOptions, option];
            }
        });

    };


    const toggleOption1 = (option) => {
        setSelectedOptions1((prevSelectedOptions) => {
            if (option === 'None') {
                return ['None'];
            }
            else if (prevSelectedOptions.includes(option)) {
                return prevSelectedOptions.filter((selected) => selected !== option);
            } else {
                return prevSelectedOptions.includes('None') ? [option] : [...prevSelectedOptions, option];
            }
        });
    };

    const toggleOption2 = (option) => {
        setSelectedOptions2([option]);
    };


    const toggleOption4 = (option) => {
        setSelectedOptions4([option]);
    };



    const handleOptionChange = (e) => {
        const { value } = e.target;
        setSelectedRadio(value);
    }
    const toggleOption6 = (option) => {
        setSelectedOptions6([option]);
        
    };

    //validate answers
    const validateSteps = (step) => {
        let isValid = false;
        switch (step) {
            case 0:
                isValid = selectedOptions.length > 0;
                break;
            case 1:
                isValid = selectedOptions1.length > 0;
                break;
            case 2:
                isValid = selectedOptions2.length > 0;
                break;
            case 3:
                isValid = keywords.length > 0;
                break;
            case 4:
                isValid = selectedOptions4.length > 0;
                break;
            case 5:
                isValid = selectedRadio !== '';
                break;
            case 6:
                isValid = selectedOptions6.length > 0;
                break;

            default:
                isValid = false;

        }
        setNextDisable(!isValid);
    }
    useEffect(() => {
        validateSteps(activeStep);
    }, [activeStep, selectedOptions, selectedOptions1, selectedOptions2, selectedOptions4, selectedOptions6, selectedRadio, searchKeyword, keywords])

    //append other data in the form.
    const updateFormData = () => {
        setFormData((prevData) => ({
            ...prevData,
            que1: selectedOptions,
            que2: selectedOptions1,
            que3: selectedOptions2,
            que4: keywords,
            que5: selectedOptions4,
            que6: selectedRadio,
            que7: selectedOptions6,
        }));
        setCompleteForm(true)
    };

    const options = [
        'WAF', 'IDF', 'Firewall', 'IPS', 'API Gateway', 'Source-IP Whitelisting', 'None'
    ];
    const option1 = [
        'Source-IP Whitelisting', 'Jumpbox', 'VPN', 'Strong 2FA', 'None'
    ]
    const option2 = [
        'Quarterly', 'Twice a year', 'Once a year', 'Never'
    ]

    const option4 = [
        'More than 6 months', '6 months', '3 months', '1 months', 'Never'
    ]

    const option6 = [
        '4 or more systems', '1-3 systems', '0 systems'
    ]


    return (
        <div className='quesForm1'>
            <div className='answer1'>
                <MobileStepper
                    variant="progress"
                    steps={7}
                    position="static"
                    activeStep={activeStep}
                    sx={{

                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#FFCB2D',
                        },

                        '& .MuiMobileStepper-progress': {
                            backgroundColor: '#EDEEF1',
                            width: '370px',
                            height: '8px',
                            borderRadius: '20px'
                        }
                    }}
                    style={{ display: 'flex', justifyContent: "center" }}
                />
                <>
                    {activeStep === 0 ?
                        <div className='q1'>

                            <div className='que1'>
                                <p>
                                    Which of the following protection mechanisms
                                    are used in your IT set up including cloud?
                                    (Select all that apply)
                                </p>
                            </div>
                            <div className='options-container' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                {options.map((option, index) => (
                                    <p
                                        key={index}
                                        onClick={() => toggleOption(option)}
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: selectedOptions.includes(option) ? '#FFCB2D' : '#F6F6F3',
                                            padding: '10px',


                                        }}
                                    >
                                        {option}
                                    </p>
                                ))}
                            </div>
                        </div>
                        : ""}
                </>

                <>
                    {activeStep === 1 ?
                        <div className='q1'>

                            <div className='que1'>
                                <p>
                                    Which of the following protection mechanisms are used for protected
                                    administrator access (remote) (Select all that apply)
                                </p>
                            </div>
                            <div className='options-container' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                {option1.map((option, index) => (
                                    <p
                                        key={index}
                                        onClick={() => toggleOption1(option)}
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: selectedOptions1.includes(option) ? '#FFCB2D' : '#F6F6F3',
                                            padding: '10px',


                                        }}
                                    >
                                        {option}
                                    </p>
                                ))}
                            </div>
                        </div>
                        : ""}
                </>
                {activeStep === 2 ?
                    <div className='q1'>

                        <div className='que1'>
                            <p>
                                How frequently do you perform security audits and penetration testing?
                            </p>
                        </div>
                        <div className='options-container' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {option2.map((option, index) => (
                                <p
                                    key={index}
                                    onClick={() => toggleOption2(option)}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: selectedOptions2.includes(option) ? '#FFCB2D' : '#F6F6F3',
                                        padding: '10px',


                                    }}
                                >
                                    {option}
                                </p>
                            ))}
                        </div>
                    </div>
                    : ""}
                {activeStep === 3 && (
                    <div className='q1'>
                        {/* Search keywords */}
                        <div className='que1'>
                            <p>
                                What is your industry / your customer's industry
                            </p>
                        </div>
                        <button type='submit' className='searchbtn1'  >
                            <img src={search} alt='search' className='searchbtn' />
                        </button>

                        <div className='search-container'>

                            <input
                                type='text'
                                placeholder='    Search industry / keywords'
                                value={searchKeyword}
                                onChange={handleSearchChange}
                                className='search-input'
                                disabled={keywords.length >= 11}
                            />
                        </div>

                        <p style={{ color: "#595D62", fontSize: "12px", fontFamily: "DM Sans", marginLeft: "45px" }}>  Industry tags</p>
                        <div className='keywords-container' style={{ color: "#000", fontSize: "12px", fontFamily: "DM Sans", marginLeft: "45px", marginBottom: "40px" }}>
                            {keywords.map((keyword, index) => (
                                <div key={index} className='keyword-item'>
                                    {keyword}
                                    <img
                                        src={close}
                                        alt='remove keyword'
                                        className='close-icon'
                                        onClick={() => handleRemoveKeyword(index)}
                                        style={{cursor:'pointer'}}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {activeStep === 4 ?
                    <div className='q1'>

                        <div className='que1'>
                            <p>
                                Did you or your industry peer / customer report a security incident in the last:
                            </p>
                        </div>
                        <div className='options-container' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {option4.map((option, index) => (
                                <p
                                    key={index}
                                    onClick={() => toggleOption4(option)}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: selectedOptions4.includes(option) ? '#FFCB2D' : '#F6F6F3',
                                        padding: '10px',


                                    }}
                                >
                                    {option}
                                </p>
                            ))}
                        </div>
                    </div>
                    : ""}

                {activeStep === 5 && (
                    <div className='q1'>
                        {/* Select radio buttons */}
                        <div className='que1'>
                            <p>Who are the users?</p>
                        </div>

                        <div className='radio-container'>
                            {['Anyone on the internet',
                                'Consumers in large area (country)',
                                'Large number of other businesses',
                                'Small number of corporates / government departments',
                                'Limited set of customers'].map((label, index) => {
                                    // const value = `${index + 1}`;
                                    return (
                                        <div key={label} className='radio-item'>
                                            <input
                                                type='radio'
                                                id={label}
                                                name='users'
                                                value={label}
                                                onChange={handleOptionChange}
                                                checked={selectedRadio === label}
                                            />
                                            <label htmlFor={label}>{label}</label>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}


                {activeStep === 6 ?
                    <div className='q1'>

                        <div className='que1'>
                            <p>
                                How many other systems do you integrate to fetch and send data to your systems?
                            </p>
                        </div>
                        <div className='options-container' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {option6.map((option, index) => (
                                <p
                                    key={index}
                                    onClick={() => toggleOption6(option)}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: selectedOptions6.includes(option) ? '#FFCB2D' : '#F6F6F3',
                                        padding: '10px',


                                    }}
                                >
                                    {option}
                                </p>
                            ))}
                        </div>
                    </div>
                    : ""}
                <div className='quesbtn'>
                    <button id='b0' onClick={handleSkip} style={{ marginRight: "20px", border: '1px solid' }}>
                        Skip
                    </button>
                    <button id='b1' onClick={handleBack} style={{ marginRight: "20px" }}>
                        Back
                    </button>
                    <button id='b2' onClick={handleNext} disabled={nextDisable}>
                        {activeStep === 6 ? 'Finish' : 'Next'}
                    </button>
                    {/* <button  onClick={handleNext}>{activeStep === 6 ? 'Finish' : 'Next'}</button> */}
                </div>
            </div>
        </div>
    );
}
