import React from 'react';
import '../../../Styles/DeveloperMode/AppDashboard/MainContent.css';

import AppLevelDashboard from '../FlowDB/AppLevelDashboard';


const MainContent = ({onRefreshToken,accessToken}) => {
    return (
        <div className="main-container">
            <div className="content-container">
              <AppLevelDashboard onRefreshToken={onRefreshToken} />
            </div>
        </div>
    );
}

export default MainContent;
