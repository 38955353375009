
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../Styles/UserAuthentication/Login.css';
import logo from '../../Images/Group 1000004408.svg';
import eye from '../../Images/eye.svg';
import googleLogo from '../../Images/googleLogo.svg';


import '@fontsource/dm-sans';

const Login = ({ onLogin,googlelogin }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const apiUrl = process.env.REACT_APP_URL;
  
  //==================form submit===========================
  
  const location = useLocation();

  const [signupSuccess] = useState(location.state?.signupSuccess);

  useEffect(() => {
    // Clear the navigation state after displaying the success message
    if (signupSuccess) {
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [signupSuccess, navigate, location.pathname]);

  

  const handleSubmit = async (e) => {
    e.preventDefault();


    const loginStatus = await onLogin(username, password);
    if (loginStatus === 'success') {
      setStatus('Login');
      
      navigate(`/dashboard`)
    } else {
      setStatus('Incorrect Username or Password');
    }

  
  };

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    window.location.href = `${apiUrl}/auth/login/google`
    // try {
    //   const loginStatus = await googlelogin();
    //   console.log('Login status:', loginStatus);
    // } catch (error) {
    //   console.error('Error during Google login:', error);
    // }
  };


  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };




  return (
    <div className='background'>
        <div className='login'>
          <img src={logo} alt='logo'  />
          <div className='loginForm'>
          {signupSuccess && <p style={{ color: '#000',backgroundColor:'#d1e6f9',borderRadius:'8px',padding:'5px' }}>Signup successful! Please log in.</p>}
            <form onSubmit={handleSubmit}>

              <p id='login-txt' style={{ marginTop: "20px" }} >Log In</p>
              <div className='login-details'>
                <label htmlFor='email'>Your email </label>
                <input
                  type='text'
                  id='email'
                  name='email'
                  placeholder='name@example.com'
                  value={username}
                  onChange={(e) => { setUsername(e.target.value) }}
                  required />
              </div>

              <div className='login-details'>
                <label htmlFor='pass'>Password  </label>
                <div style={{ position: 'relative' }}>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id='pass'
                    name='pass'
                    placeholder='******'
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                    required
                  />
                  <img
                    src={eye}
                    alt='Show/Hide Password'
                    style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', paddingTop: "50px" }}
                    onClick={toggleShowPassword}
                    className='eye1'
                  />
                </div>
              </div>


              <p id='forgetPassword'>Forgot password?</p>
              <div className='wrap' style={{ marginTop: "20px" }}>
                <button type='submit' className='btn'  >Login</button>
              </div>

              
             

            </form>
            <div>
                {/* <p style={{ height: "20px", marginTop: "-30px" }}>or</p>
                <button style={{border:"none", backgroundColor:"#fff", cursor:"pointer"}} onClick={handleGoogleLogin}>
                  <img src={googleLogo} alt='goolelogo' style={{ height: "30px", marginBottom: "-60px" }} />
                </button> */}
              </div>
            <p>Don't have an account ? <Link to='/signup' style={{ color: "black", fontWeight: "bold" }}> Sign Up</Link> </p>
            <div>
                {status ? (
                  <span style={{ color: status === 'Login' ? '' : 'red', }}>
                    {status === 'Login' ? '' : 'Incorrect Username or Password'}
                  </span>
                ) : null}
              </div>
          </div>
        </div>

    </div>
  )
}

export default Login;
