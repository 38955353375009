import React, { useState } from 'react';
import ApplicationLeftBar from './ApplicationLeftBar';
import ApplicationNavbar from './ApplicatonNavbar';
import '../../../Styles/DeveloperMode/AppDashboard/AddApplication.css';
import ChoosePreference from '../PopUpUtils/ChoosePreference';
import ApplicationMinimizeLeftBar from '../LeftSidebar/ApplicationMinimizeLeftBar';


const AddFlow = ({ onLogout, accessToken,minimize,setMinimize }) => {
    const [openForm, setOpenForm] = useState(false);
    const [newflow,setNewFlow]=useState(false)
    const handlePreference = () => {
        setOpenForm(!openForm);
        setNewFlow(!newflow);
        localStorage.removeItem('flowName')
    }
    return (
        <div>
            {!accessToken ?<h1>This page is not found</h1>:
                (<div className='appDashboard'>
                    <div className='sidebar'>
                    {minimize ?  <ApplicationLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <ApplicationMinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}

                    </div>
                    <div className='content'>
                        <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                            <ApplicationNavbar />
                        </div>
                        <div className={`mainContentnew ${!minimize ? 'minimized' : ''}`}>
                        <div className={`addflow1 ${!minimize ? 'img' : ''}`} >
                            <div>
                                <p id='addnewFlow1_P1'>No workflows found</p>
                                <p id='addnewFlow1_P2'>Get started by adding a workflow to analyze security threats for</p>
                            </div>
                            <div className='addnewFlow1'>
                                <button className='btn' onClick={handlePreference}>Add workflow</button>
                            </div>
                        </div>
                        </div>
                        {openForm ? <ChoosePreference setOpenForm={setOpenForm} newflow={newflow}/> : ""}
                    </div>
                </div>)
            }
        </div>
    );
};

export default AddFlow;
