import React from 'react'
import '../../../Styles/DeveloperMode/Navbar/Navbar.css';
import { useNavigate } from 'react-router-dom';

import sunIconLight from '../../../Images/sun.svg';
import lightTimer from '../../../Images/refresh.svg';

import lightMenu from '../../../Images/notification.svg';
import SearchBar from '../MainContent/SearchBar';


const ApplicationNavbar = () => {
const navigate=useNavigate()

  return (
    <div className='navbar-content'>
      <div className='route-content'>
        <pre id="d1" style={{ backgroundColor:"#1C1C1C0D", borderRadius:"5px", padding:"5px", marginTop:"-5px"}} onClick={()=>navigate('/dashboard')} >Overall  </pre>
      
      </div>

      <div className='search-content'>
        <SearchBar />

        <img className='iconImg' style={{cursor:'pointer'}} src={sunIconLight} alt='sun' />

        <img className='iconImg' style={{cursor:'pointer'}} src={lightTimer}  alt='timer'/>

        <img className='iconImg' style={{cursor:'pointer'}} src={lightMenu} alt='menuitem'/>

      </div>

    </div>
  )
}

export default ApplicationNavbar

