import React, { useEffect, useState } from 'react';
import '../../../Styles/DeveloperMode/PopUpUtils/ConfirmAll.css';
import close from '../../../Images/close.svg'
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
import '@fontsource/dm-sans';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack'; 
import whiteCross from '../../../Images/whiteCross.png';
import { useSnackbarContext } from '../../StateManagement/SnackbarContext';
import "../../../Styles/DeveloperMode/Notification/SnackbarMUI.css";

function ConfirmAll_modal({ isOpen, setIsOpen, setViewReport }) {
  const [allrisk, setAllRisk] = useState([]);
  const { app_id, setApp_id } = useWorkflowDetails();
  const { flowId, setFlowId } = useWorkflowDetails();
  const { runId, setRunId } = useWorkflowDetails();
  const [isFuzzButtonDisabled, setIsFuzzButtonDisabled] = useState(false);

  const apiUrl = process.env.REACT_APP_URL;
  const {enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {showSnackbar}=useSnackbarContext();
  useEffect(() => {
    const storedApp_id = localStorage.getItem('app_id');
    const storedFlowId = localStorage.getItem('flowId');
    const storedRunId = localStorage.getItem('runId');
    if (storedApp_id) {
      setApp_id(storedApp_id);
    }
    if (storedFlowId) {
      setFlowId(storedFlowId);
    }
    if (storedRunId) {
      setRunId(storedRunId);
    }
  }, [setApp_id, setFlowId, setRunId]);

  useEffect(() => {
    if (app_id && flowId && runId) {
      fetchRisk();
    }
  }, [app_id, flowId, runId]);

  const fetchRisk = async () => {
    try {
      const localToken = localStorage.getItem('accessToken');
      const url = `${apiUrl}/resources/apps/${app_id}/flows/${flowId}/runs/${runId}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localToken}`
        },
      });

      if (response.ok) {
        try {
          const data = await response.json();
          const risk=data.OWASP_top_10_info||[] //mapping owasp data
          setAllRisk(risk); // Update state
          
        } catch (error) {
          console.error('Error parsing JSON response:', error);
        }
      } else {
        console.error('Failed to fetch data from the API',response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFuzzNowClick = async() => {
    setTimeout(()=>{
      setIsOpen(false);
    },3000)
    const snackbarId=enqueueSnackbar('Generating report...',{
      variant:'info',
      action:<button onClick={()=>closeSnackbar(snackbarId)} style={{backgroundColor:'#ddffff',border:'none'}}> 
        <img src={close} alt='close' style={{width:'15px',height:'15px',cursor:'pointer'}}/>
        </button>,
      persist:true,
      className:'snackbar-info',
    });

    //for dark shade
    // const snackbarId = enqueueSnackbar('Generating report...', {
    //   variant: 'info',
    //   action: <button onClick={() => closeSnackbar(snackbarId)}  style={{backgroundColor:'#2196f3',border:'none'}}>  
    //     <img src={whiteCross} alt='close' style={{width:'15px',height:'15px',cursor:'pointer'}} />
    //   </button>,
    //   persist: true, 
    //   className:'snackbar-info'
    // });
    setIsFuzzButtonDisabled(true);
    try {
      const localToken = localStorage.getItem('accessToken');
      const url=`${apiUrl}/fuzzer/fuzz/apps/${app_id}/runs/${runId}`;
      const response =await fetch(url,{
        headers:{
          Authorization:`Bearer ${localToken}`
        },
      });
      if(response.ok){
        const data =await response.json();
        if(data){
          Cookies.set("combineCWE", data.report_id, { expires: 7 });
          
          showSnackbar('Report is generated now. You can check it in the Reports tab.',{variant:'success',className:'snackbar-success'});
          setViewReport(true);

        } else{
          showSnackbar('Report is not generated.Due to some technical error.',{ variant: 'error',className:'snackbar-error' });

        }
      }
    } catch (error) {
      console.log("Error during fuzzing ", error)
      showSnackbar('An error occurred during fuzzing.', { variant: 'error',className:'snackbar-error' }); 
    }

  };



  return (
    <div className={`popup-container0 ${isOpen ? 'show' : ''}`}>
      <div className="popup0">
        <div className="popup-header0">
          <div className='header0'>Confirm All Risks</div>
          <button className="close-btn0" onClick={() => { setIsOpen(false); }}>
            <img src={close} alt='close0' />
          </button>
        </div>
        <div className="popup-body0">
        <div className='riskListContainer'>
          {Object.keys(allrisk).length > 0 ? (
              Object.entries(allrisk).map(([owaspCategory, value]) => (
                <div key={owaspCategory} className="RiskList">
                  <div className="riskGroup">{owaspCategory}</div>
                </div>
              ))
            ) : (
              <div>No risk data available.</div>
            )}
          </div>
          </div>
        <div>

          <div className='submitButton0'>
            <button type='submit' className='fuzzNowBtn0'disabled={isFuzzButtonDisabled} onClick={handleFuzzNowClick}>Verify Now</button>
          </div>


        </div>
      </div>

    </div>
  );
}

export default ConfirmAll_modal;


