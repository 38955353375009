
import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, StepContent, Typography, styled } from '@mui/material';
import apisecurist from '../../Images/Group 1000004408.svg';
import close from '../../Images/close.svg';
import '../../Styles/CisoMode/VerticalLinearStepper.css';
import FeedbackQue from './FeedbackQue';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../StateManagement/SnackbarContext';
import "../../Styles/DeveloperMode/Notification/SnackbarMUI.css";

const steps = [
    'Application details',
    'Risk assessment rating',
    'CI Integration',
];


export default function VerticalStepper({ setOpenForm, allAppNames }) {
    const [activeStep, setActiveStep] = useState(0);
    const apiUrl = process.env.REACT_APP_URL;
    const localToken = localStorage.getItem('accessToken');
    const [formData, setFormData] = useState({
        app_name: '',
        description: '',
        productType: '',
        productCategory: '',
        url: '',

    });
    const navigate = useNavigate();
    const [completeForm, setCompleteForm] = useState(false);
    const [errors, setErrors] = useState({});
    // const [message, setMessage] = useState('');
    const [statusCode, setStatusCode] = useState('');
    const {showSnackbar} =useSnackbarContext();
    const isValidUrl = (url) => {
        const urlPattern = /((http|https):\/\/)?(www\.)?[a-zA-Z0-9@:%._\+~#?&//=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%._\+~#?&//=]*)/;
        return urlPattern.test(url);
    };

    const appNameExists = (name) => {

        return allAppNames.some(app => app.app_name === name);
    };

    const trimFormData = (data) => {
        const trimmedData = {};
        for (const key in data) {
            if (typeof data[key] === 'string') {
                if (key === 'app_name') {
                    trimmedData[key] = data[key].toUpperCase().trim();
                } else {
                    trimmedData[key] = data[key].trim();
                }
            } else {
                trimmedData[key] = data[key];
            }
        }
        return trimmedData;
    };

    const handleNext = async (e) => {
        e.preventDefault();
        let tempErrors = {};

        const trimmedFormData = trimFormData(formData);
        if (activeStep === 0) {
            // Perform form validation
            if (!trimmedFormData.app_name) {
                showSnackbar('Please provide a title of the application',{variant:"warning",className:'snackbar-warning'});
                const titleBox = document.getElementById("app_name");
                titleBox.focus();
            }
            else if (trimmedFormData.app_name.length < 1 || trimmedFormData.app_name.length > 50) {
                tempErrors.app_name = "Title must be between 1 and 50 characters";
            }
            else if (appNameExists(trimmedFormData.app_name)) {
                tempErrors.app_name = "Application already exists";
            }
            else if (!trimmedFormData.description) {
                showSnackbar('Please provide a description of the application',{variant:"warning",className:'snackbar-warning'});
                const descriptionBox = document.getElementById("description");
                descriptionBox.focus();
            }
            else if (trimmedFormData.description.length < 5 || trimmedFormData.description.length > 200) {
                tempErrors.description = "Description must be between 5 and 200 characters";
            }

            else if (!trimmedFormData.productType) {
                showSnackbar('Please mention the type of the product',{variant:"warning",className:'snackbar-warning'});
                const productTypeBox = document.getElementById("productType");
                productTypeBox.focus();
            }
            else if (trimmedFormData.productType.length < 1 || trimmedFormData.productType.length > 50) {
                tempErrors.productType = "Product type must be between 1 and 50 characters";
            }

            else if (!trimmedFormData.productCategory) {
                showSnackbar('Please mention the category of the product',{variant:"warning",className:'snackbar-warning'});
                const productCategoryBox = document.getElementById("productCategory");
                productCategoryBox.focus();
            }
            else if (trimmedFormData.productCategory.length < 1 || trimmedFormData.productCategory.length > 50) {
                tempErrors.productCategory = "Product category must be between 1 and 50 characters";
            }

            else if (!trimmedFormData.url) {
                showSnackbar('Please provide the URL of the application',{variant:"warning",className:'snackbar-warning'});
                const urlBox = document.getElementById("url");
                urlBox.focus();
            }
            else if (trimmedFormData.url.length < 3 || trimmedFormData.url.length > 2048 || !isValidUrl(trimmedFormData.url)) {
                tempErrors.url = "URL must be between 3 and 2048 characters and be a valid format";

            }
            else {
                // No errors, move to the next step
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }

            setErrors(tempErrors);
            return Object.keys(tempErrors).length === 0;
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }



    };


    const handleBack = () => {
        navigate(0);
    }

    //remove leading space in form
    // const removeLeadingSpace = (value) =>{
    //     return value.replace(/^\s+/,'');
    // }

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
        setErrors({ ...errors, [id]: '' });
    };

    const backStep = () => {
        setActiveStep(0);
    }
    const handleFeedbackComplete = () => {
        setActiveStep(2);
    };

    // console.log(allAppNames.some(app => app.app_name))
    const skipQueston = async () => {

        if (completeForm) {
            try {
                const response = await sendData();
                if (response === "success") {
                    if (!allAppNames.some(app => app.app_name)) {
                        window.location.reload();

                    }

                    navigate('/dashboard');
                } else {
                    console.error("Failed to create app. Response was not 'success'.");
                    // setMessage('An error occurred while adding the application. Response code :')
                    setTimeout(()=>{
                        showSnackbar(`An error occurred while adding the application. Response code :${statusCode}`,{variant:'error',className:'snackbar-error'})
                    },100);
                    // navigate('/dashboard');
                }
            } catch (error) {
                console.error("Error in skipQueston:", error);
                // setMessage('An error occurred while adding the application. Response code :')
                setTimeout(()=>{
                    showSnackbar(`An error occurred while adding the application. Response code :${statusCode}`,{variant:'error',className:'snackbar-error'})
                },100);

                // navigate('/dashboard');
            }
        } else {
            console.log("Form is not complete.");
            // setMessage('An error occurred while adding the application. Response code : 400')
            setTimeout(()=>{
                showSnackbar(`An error occurred while adding the application. Response code :${statusCode}`,{variant:'error',className:'snackbar-error'})
            },100);
            // navigate('/dashboard');
        }
    };




    const CustomStepIcon = styled('div')(({ completed }) => ({
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        border: completed ? 'none' : '2px solid #ffcb2d',
        backgroundColor: completed ? '#FFCB2D' : '#ffffff',
        color: completed ? '#000000' : 'FFCB2D', // Black for tick icon, yellow for incomplete
        position: 'relative', // Position relative for absolute positioning of dot
        '&::before': { // Pseudo-element for yellow dot
            content: "''",
            position: 'absolute',
            width: completed ? 0 : 10, // Show dot only for incomplete steps
            height: completed ? 0 : 10, // Show dot only for incomplete steps
            borderRadius: '50%',
            backgroundColor: '#FFCB2D', // Yellow dot color
        },
        '& svg': {
            width: 25,
            height: 25,
        },
    }));

    const trimmedFormData = trimFormData(formData);

    const sendData = async () => {

        try {
            const response = await fetch(`${apiUrl}/resources/new-app`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localToken}`,
                },
                body: JSON.stringify(trimmedFormData)
            });

            const responseBody = await response.json();
            console.log("Response Body:", responseBody);

            if (response.ok) {
                // const newStatusCode = response.status.toString();
                // setStatusCode(newStatusCode); 
                return "success";
            } else {
                console.error("Failed to submit form:", responseBody);
                console.log('Setting status code to:', response.status);
                setStatusCode(response.status || '500');
                showSnackbar(`An error occurred while adding the application. Response code: ${response.status}`, { variant: 'error' ,className:'snackbar-error'});
                return "failed";
            }
        } catch (error) {
            console.error('An error occurred while adding application:', error);
            setStatusCode('500');
        }
    };


    return (
        <div className='background_stepper'>
            <img src={apisecurist} alt='logo' style={{ width: "150px", height: "50px", marginLeft: "40px", marginTop: "20px", marginBottom: "100px" }} />
            <img src={close} alt='logo' onClick={() => { setOpenForm(false) }} style={{ float: 'right', margin: "40px", cursor: 'pointer' }} />
            <div className='fix_stepper' style={{ marginLeft: '40px', marginTop: "30px" }}>
                <div className="custom-stepper">
                    <div>
                        <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            sx={{
                                '& .MuiStepIcon-root': {
                                    color: '#FFCB2D !important',
                                    width: '35px',
                                    height: '35px',
                                    borderColor: '#FFCB2D',

                                },
                                '& .MuiStepLabel-label': {
                                    fontSize: "24px",
                                    fontFamily: "DM Sans",
                                    fontWeight: "500",
                                    width: "400px",
                                },
                                '& .MuiStepConnector-line': {
                                    borderLeft: '1px solid #FFCB2D !important',
                                    height: "50px",
                                    marginTop: "0px",
                                    marginLeft: '0px',
                                    borderColor: '#FFCB2D',
                                },
                                '& .MuiStepConnector-completed': {
                                    borderColor: '#FFCB2D !important', // Change color of connector for completed step
                                },
                            }}
                        // connector={<CustomStepIcon />}
                        >
                            {steps.map((label, index) => (
                                <Step key={label} completed={index < activeStep}>
                                    <StepLabel StepIconComponent={({ completed }) => <CustomStepIcon completed={completed}>{completed && <CheckIcon />}</CustomStepIcon>} style={{ marginTop: "0px" }}>
                                        &nbsp; {label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography sx={{ fontSize: "14px", color: "#595D62", width: "400px", marginLeft: "22px" }}>
                                            {index === 0
                                                ? 'Provide details of the application to help us tailor your experience effectively.'
                                                : index === 1
                                                    ? 'Answer the following question to help us understand your risk assessment rating so we can provide more relevant experience.'
                                                    : 'Download and run the plugin to integrate with your CI pipeline.'}
                                        </Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    {activeStep === 0 && (
                        <div className='formpopup'>
                            <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
                                <label>Application  <span style={{ color: 'red' }} >*</span></label>
                                <input
                                    type='text'
                                    id='app_name'
                                    value={formData.app_name}
                                    onChange={handleChange}
                                    placeholder='application name'
                                    style={{ marginBottom: '20px' }}
                                />
                                {errors.app_name && <p className='valiation'>{errors.app_name}</p>}
                                <label>Description <span style={{ color: 'red' }} >*</span></label>
                                <textarea
                                    id='description'
                                    value={formData.description}
                                    onChange={handleChange}
                                    placeholder='about application'
                                    style={{ marginBottom: '20px', backgroundColor: "#F9F9F9", height: '100px', width: '335px', border: "1px solid #D1D5DB" }}
                                />
                                {errors.description && <p className='valiation'>{errors.description}</p>}
                                <label>Type of Product <span style={{ color: 'red' }} >*</span></label>
                                <input
                                    type='text'
                                    id='productType'
                                    value={formData.productType}
                                    onChange={handleChange}
                                    placeholder='web application / mobile application'
                                    style={{ marginBottom: '20px' }}
                                />

                                {errors.productType && <p className='valiation'>{errors.productType}</p>}
                                <label>Product category <span style={{ color: 'red' }} >*</span></label>
                                <input
                                    type='text'
                                    id='productCategory'
                                    value={formData.productCategory}
                                    onChange={handleChange}
                                    placeholder='inhouse / third party'
                                    style={{ marginBottom: '20px' }}
                                />
                                {errors.productCategory && <p className='valiation'>{errors.productCategory}</p>}
                                <label>URL of the application <span style={{ color: 'red' }} >*</span></label>
                                <input
                                    type='text'
                                    id='url'
                                    value={formData.url}
                                    onChange={handleChange}
                                    placeholder='URL / domain / IP address'
                                    style={{ marginBottom: '10px' }}
                                />
                                {errors.url && <p className='valiation'>{errors.url}</p>}
                            </form>
                        </div>
                    )}
                    {activeStep === 1 && (
                        <div>{<FeedbackQue
                            onComplete={handleFeedbackComplete}
                            backStep={backStep}
                            formData={formData}
                            setFormData={setFormData}
                            setCompleteForm={setCompleteForm}
                        />}
                        </div>
                    )}
                    {activeStep === 2 && (
                        <div className='skipButton'>
                            <button type='submit' id='skipButton' onClick={skipQueston}>Skip this step</button>
                            {/* <div style={{ color: 'red', marginLeft: '-130px', marginTop: '10px' }}>{message} {statusCode}</div> */}
                        </div>
                    )}
                    {/* {activeStep === 3 && (
                        <div >
                            {<ChoosePreference appUrl={appUrl} setOpenForm={setOpenForm} />}
                        </div>
                    )} */}
                </div>
                {activeStep === 0 && (
                    <div className="button-container">
                        <button onClick={handleBack} className="back-button">
                            Back
                        </button>
                        <button
                            style={{ cursor: 'pointer' }}
                            onClick={handleNext}
                            className="next-button"
                            disabled={activeStep === steps.length - 1}
                        >
                            {activeStep === steps.length - 1 ? 'Next' : 'Next'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
