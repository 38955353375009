
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import '@fontsource/dm-sans';
import '../../../Styles/DeveloperMode/Graph_content/CWEmatrix.css';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
import { useNavigate } from 'react-router-dom';

const CWE_matric = ({ onRefreshToken }) => {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState({
    series: [{
      name: "Threats",
      data: []
    }],
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          horizontal: false,
          columnWidth: '40%',
          endingShape: 'rounded'
        },
      },
      title: {
        align: 'left',
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true,
        markers: {
          fillColors: ['#FFCB2D', '#FF7917']
        },
        position: 'top'
      },
      colors: ['#FFCB2D', '#FF7917'],
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          style: {
            colors: '#868585'
          }
        },
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true
        },
      },
      grid: {
        row: {
          opacity: 0,
        },
        column: {
          opacity: 0
        },
        padding: {
          top: 0,
          bottom: 0
        }
      },
      fill: {
        opacity: 1
      },
    }
  });

  const apiUrl = process.env.REACT_APP_URL;
  const localToken = localStorage.getItem('accessToken');
  const { app_id } = useWorkflowDetails();

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/resources/apps/${app_id}/flows`, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const filteredData = data.filter(item => item.OWASP_top_10_info && Object.keys(item.OWASP_top_10_info).length > 0);

        if (filteredData.length > 0) {
          const aggregatedData = {};

          // Initialize all OWASP categories to 0
          Object.keys(filteredData[0].OWASP_top_10_info).forEach(category => {
            aggregatedData[category] = 0;
          });

          // Aggregate the data
          filteredData.forEach(item => {
            Object.entries(item.OWASP_top_10_info).forEach(([category, count]) => {
              aggregatedData[category] += count;
            });
          });

          const categories = Object.keys(aggregatedData);
          const seriesData = [{
            data: categories.map(category => aggregatedData[category])
          }];

          setChartData({
            ...chartData,
            series: seriesData,
            options: {
              ...chartData.options,
              xaxis: {
                ...chartData.options.xaxis,
                categories: categories
              }
            }
          });
        } else {
          // Handle case where no valid data is available
          setChartData({
            ...chartData,
            series: [{ data: [] }],
            options: {
              ...chartData.options,
              xaxis: {
                ...chartData.options.xaxis,
                categories: []
              }
            }
          });
        }

      } else if (response.status === 401) {
        const result = await onRefreshToken();
        if (result === "success") {
          fetchData();
        } else {
          navigate('/');
        }
      } else {
        console.error('Failed to fetch data');
        navigate('/');
      }
    } catch (e) {
      console.error('Error fetching data:', e);
    }
  }

  useEffect(() => {
    fetchData();
  }, [app_id]);

  return (
    <div>
      <div id="cweMatric">
        <div className={`cweMatric`}> CWE Metric</div>
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={160} width={540} />
      </div>
    </div>
  );
};

export default CWE_matric;
