import React, { useEffect, useState } from 'react'
import '../../../Styles/DeveloperMode/Fuzzer/PreProcessFuzzer.css'
import line from '../../../Images/Line 65.svg';
// import download from '../../../Images/frame.svg';
// import export1 from '../../../Images/export.svg';
import arrowUp from '../../../Images/arrowup.svg';
import downArrow from '../../../Images/arrowDown.svg';
import TagModalBox from '../PopUpUtils/TagModalBox';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
import { useNavigate } from 'react-router-dom';
import CWETagsPopUp from './CWETagsPopUp';
import dataNotFound from '../../../Images/notDatafound.svg';

const PreProcessFuzzer = ({ setOWAPSName, minimize, onRefreshToken, openModal, setCWEName, setCWEDesc, setUrl, setCollectRawDataId, setCombineCWE, openModal1, collectAPI, viewReport,setViewReport }) => {
  const [selectMode, setSelectMode] = useState('Security Risks');
  const [apiData, setApiData] = useState([]);
  const [expandedApi, setExpandedApi] = useState(null);
  const [methodCount, setMethodCount] = useState({
    GET: 0,
    POST: 0,
    PUT: 0,
    DELETE: 0
  });
  const [vcTagCounts, setVcTagCounts] = useState({});
  const [vcTagDetails, setVcTagDetails] = useState({});
  const [selectTag, setSelectTag] = useState('');
  const [openModalBox, setOpenModalBox] = useState(false);
  const [openModalBox1, setOpenModalBox1] = useState(false);
  const [CWEList, setCWEList] = useState({});
  const [Name, setName] = useState('')
  const [expandedCategory, setExpandedCategory] = useState(null); // State to track expanded category
  const [CWE, setCWE] = useState('Threat Model');
  const { app_id, setApp_id } = useWorkflowDetails();
  const { flowId, setFlowId } = useWorkflowDetails();
  const { runId, setRunId } = useWorkflowDetails();
  const [accessTokenUpdated, setAccessTokenUpdated] = useState('');
  const apiUrl = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const [trimmedCWEList, setTrimmedCWEList] = useState([]);
  // const [selectedCWEData, setSelectedCWEData] = useState('');
  const [idsFlag, setIdsFlag] = useState(true);
  // const [CWEUrl, setCWEUrl] = useState('');
  const { flowName, setFlowName } = useWorkflowDetails('');
  const { runName, setRunName } = useWorkflowDetails('');
  const { appname, setAppname } = useWorkflowDetails('');
  const [displayItems, setDisplayItems] = useState(false);
  // const { workflowDetails, setWorkflowDetails } = useWorkflowDetails();
  // const { workflowDetail, setWorkflowDetail } = useWorkflowDetails();
  // const { appId, workflowId, runName } = useParams();

  const { reportAppId} = useWorkflowDetails();
  const { reportFlowId} = useWorkflowDetails();

  const { reportRunId} = useWorkflowDetails();
  const {  setReportApp } = useWorkflowDetails('');
  const {  setReportFlow } = useWorkflowDetails('');
  const {  setReportRun } = useWorkflowDetails('');
  //------------fetch CWE list using http://localhost:8000/resources/apps/appId/flows/flowId/runs/runId-------------
  if (!app_id && !flowId) {
    navigate('/')
  }

  useEffect(() => {
    const storedApp = localStorage.getItem('appname');
    const storedFlow = localStorage.getItem('flowName');
    const storedRun = localStorage.getItem('runName');
    if (storedApp) {
      setAppname(storedApp);
    }
    if (storedFlow) {
      setFlowName(storedFlow);
    }
    if (storedRun) {
      setRunName(storedRun);
    }
  }, [setAppname, setFlowName, setRunName]);


  useEffect(() => {
    const storedFlowId = localStorage.getItem('flowId');
    const storedRunId = localStorage.getItem('runId');
    if (storedFlowId) {
      setFlowId(storedFlowId);
    }
    if (storedRunId) {
      setRunId(storedRunId);
    }
  }, [setFlowId, setRunId]);

  useEffect(() => {
    const storedApp_id = localStorage.getItem('app_id');
    if (storedApp_id) {
      setApp_id(storedApp_id);
    }
  }, [setApp_id]);

  useEffect(() => {
    fetchCWEList();

  }, [app_id, flowId]);

  useEffect(() => {
    if (accessTokenUpdated) {
      fetchCWEList();
    }

  }, [accessTokenUpdated]);


  const fetchCWEList = async () => {
    try {
      const localToken = localStorage.getItem('accessToken');
      // const url = 'runId.json';
      const url = `${apiUrl}/resources/apps/${app_id}/flows/${flowId}/runs/${runId}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localToken}`
        },
      });

      if (response.ok) {
        const data = await response.json();

        if (data && data.OWASP_top_10_info && data.run_name) {
          setCWEList(data.OWASP_top_10_info);
          setName(data.run_name);

          // console.log(data.threat_count)
          if (data.threat_count > 0) {
            setDisplayItems(true);
          }
          // const totalThreatIDs=data.map(item => item._id);
          // setAllIDs(totalThreatIDs);
          const trimmedString = extractTrimmedStrings(data.OWASP_top_10_info);
          setTrimmedCWEList(trimmedString);
        }
        else {
          console.error('Fetched data is not an array or is empty');
        }
      }
      else if (response.status === 400) {
        const result = await onRefreshToken();
        if (result === "success") {
          setAccessTokenUpdated(localStorage.getItem('accessToken'));
        }
        else {
          navigate('/');
        }
      }
      else if (response.status === 401) {
        const result = await onRefreshToken();
        if (result === "success") {
          setAccessTokenUpdated(localStorage.getItem('accessToken'));
        }
        else {
          navigate('/');
        }
      }
      else {
        console.error('Failed to fetch data from the API');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // console.log(Name,"list")
  const extractTrimmedStrings = (owaspTop10Info) => {
    const trimmedStrings = [];
    for (const owaspCategory in owaspTop10Info) {
      if (owaspTop10Info.hasOwnProperty(owaspCategory)) {
        const CWE_info = owaspTop10Info[owaspCategory].CWE_info;
        for (const cweKey in CWE_info) {
          if (CWE_info.hasOwnProperty(cweKey)) {
            const trimmedString = `${owaspCategory.split(' ')[0]}-${cweKey}`;
            trimmedStrings.push(trimmedString);
          }
        }
      }
    }
    return trimmedStrings;
  }

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };


  const handleCWE = async (cweKey, owaspCategory, cweData) => {
    setCollectRawDataId([]);
    setCWE(cweKey);
    setCWEName(cweKey);
    // setCWEUrl(cweData.description[0]);
    setCWEDesc(cweData.description[1]);
    setUrl(cweData.description[0]);
    const trimmedCWE1 = `${owaspCategory}`;
    setOWAPSName(trimmedCWE1);
    const trimmedCWE = `${owaspCategory.split(' ')[0]}-${cweKey}`;
    setCombineCWE(trimmedCWE);

    const matchedCWE = trimmedCWEList.find(cwe => cwe === trimmedCWE);
    if (matchedCWE) {
      const CWEId = matchedCWE;
      setIdsFlag(true);

      // console.log("match found",CWEId);
      try {
        const localToken = localStorage.getItem('accessToken');
        const url = `${apiUrl}/resources/apps/${app_id}/flows/${flowId}/runs/${runId}/threat/${CWEId}`;
        // console.log("CWEFlag", url)
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${localToken}`
          },
        });
        if (response.ok) {

          const data = await response.json();
          if (data) {
            const apis = data.map(item => item.api);
            const uniqueAPIs = [...new Set(apis)]; //remove duplicate api
            collectAPI(uniqueAPIs);
            setViewReport(false)
          }

          // setSelectedCWEData(data);
          // setCWEFlag(true);

          const extractedIds = data.map(item => item._id);
          // setThreatIds(extractedIds);
          // setCollectRawDataId(extractedIds)
          if(extractedIds){
            setIdsFlag(false);
          }
          if (Array.isArray(data) && data.length > 0) {
            setApiData(data);
            calcuateMethodCounts(data);
            extractVcTagInfo(data)
            setCollectRawDataId(extractedIds)
          }
          else {
            console.error('Fetched data is not an array or is empty');
            setApiData([]);
            setMethodCount({ GET: 0, POST: 0, PUT: 0, DELETE: 0 });
            setVcTagCounts({});
            setVcTagDetails({});
          }
        }
      } catch (error) {
        console.error('Fetched data is not an array or is empty');
        setApiData([]);
        setMethodCount({ GET: 0, POST: 0, PUT: 0, DELETE: 0 });
        setVcTagCounts({});
        setVcTagDetails({});
      }
    }
    else {
      console.error('Fetched data is not an array or is empty');
      setApiData([]);
      setMethodCount({ GET: 0, POST: 0, PUT: 0, DELETE: 0 });
      setVcTagCounts({});
      setVcTagDetails({});
      // setCWEFlag(false);
    }
  }
  // console.log("new api data",apiData)

  const renderCWEList = () => {
    return Object.entries(CWEList).map(([owaspCategory, owapsData], index) => (
      <div key={index} className='owasp-category' >
        <div className={`owasp-header ${expandedCategory === owaspCategory ? 'active' : ''}`} onClick={() => toggleCategory(owaspCategory)}>
          <div className='owasp-header-list' onClick={() => toggleCategory(owaspCategory)}>
            {
              expandedCategory === owaspCategory ?
                <div style={{marginTop:"-5px"}}> <img src={downArrow} alt='down' /></div>
                :
                <div  style={{marginTop:"0px"}}><img src={arrowUp} alt='up' /></div>
            }
            <span id='category'>{owaspCategory.replace(/_/g, ' ')}</span>
          </div>
          <span id='occurances'>{owapsData.total_count} </span>
          <span id='occurances-H'>Occurrences</span>
        </div>
        {expandedCategory === owaspCategory && (
          <div className='cwe-list'>
            {Object.entries(owapsData.CWE_info).map(([cweKey, cweData], index) => (
              <div key={index} onClick={() => handleCWE(cweKey, owaspCategory, cweData)} className={`cwe-item ${CWE === cweKey ? 'active' : ''}`}>
                <div className='cwe-header'>
                  <div>{cweKey}</div>
                  <div id='cweCount'>{cweData.count}</div>
                </div>
                <div className='cwe-description'>
                  <p>{cweData.description[1]}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    ))
  }

  //------------fetch CWE list using http://localhost:8000/resources/apps/appId/flows/flowId/runs/runId/threat-------------
  useEffect(() => {
    fetchAPIs();
  }, [app_id, flowId, runId]);

  useEffect(() => {
    if (accessTokenUpdated) {
      fetchAPIs();
    }
  }, [accessTokenUpdated]);

  // useEffect(() => {
  //   fetchAPIs();
  // }, []);

  const fetchAPIs = async () => {
    try {
      const localToken = localStorage.getItem('accessToken');
      // const url = 'output.json';
      const url = `${apiUrl}/resources/apps/${app_id}/flows/${flowId}/runs/${runId}/threat`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localToken}`
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setApiData(data);
          calcuateMethodCounts(data);
          extractVcTagInfo(data)
        }
        else {
          console.error('Fetched data is not an array or is empty');
          setApiData([]);
          setMethodCount({ GET: 0, POST: 0, PUT: 0, DELETE: 0 });
          setVcTagCounts({});
          setVcTagDetails({});
        }
      }

      else if (response.status === 400) {
        const result = await onRefreshToken();
        if (result === "success") {
          setAccessTokenUpdated(localStorage.getItem('accessToken'));
        }
        else {
          navigate('/');
        }
      }
      else if (response.status === 401) {
        const result = await onRefreshToken();
        if (result === "success") {
          setAccessTokenUpdated(localStorage.getItem('accessToken'));
        }
        else {
          navigate('/');
        }
      }
      else {
        console.error('Failed to fetch data from the API');
        setApiData([]);
        setMethodCount({ GET: 0, POST: 0, PUT: 0, DELETE: 0 });
        setVcTagCounts({});
        setVcTagDetails({});
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setApiData([]);
      setMethodCount({ GET: 0, POST: 0, PUT: 0, DELETE: 0 });
      setVcTagCounts({});
      setVcTagDetails({});
    }
  };


  const calcuateMethodCounts = (data) => {
    const counts = { GET: 0, POST: 0, PUT: 0, DELETE: 0 };
    data.forEach(api => {
      if (api && api.method && counts.hasOwnProperty(api.method)) {
        counts[api.method]++;
      }
    });
    setMethodCount(counts);
  };

  //extract the tags from data
  const extractVcTagInfo = (data) => {
    const tagCounts = {};
    const tagDetails = {};

    const processVcTags = (items) => {
      items.forEach(item => {
        if (item && item.vc_tags && Array.isArray(item.vc_tags)) {
          item.vc_tags.forEach(tag => {
            if (tag && tag.vc_tag_type) {
              const tagType = tag.vc_tag_type;

              if (!tagCounts[tagType]) {
                tagCounts[tagType] = 0;
                tagDetails[tagType] = [];
              }
              tagCounts[tagType]++;
              tagDetails[tagType].push({
                key: item.vc_key || 'N/A',
                value: item.vc_value || 'N/A',
              });
            }
          });
        }
      });
    };

    data.forEach(api => {
      if (api && api.context && api.context.request) {
        const request = api.context.request;
        if (Array.isArray(request.vc_body)) {
          processVcTags(request.vc_body);
        }
        if (Array.isArray(request.vc_headers)) {
          processVcTags(request.vc_headers);
        }
        if (request.vc_url && Array.isArray(request.vc_url.vc_params)) {
          processVcTags(request.vc_url.vc_params);
        }
      }
    });

    setVcTagCounts(tagCounts);
    setVcTagDetails(tagDetails);
  };


  // console.log(vcTagCounts,"vctagcount")
  // console.log(vcTagDetails,"vcTagDetails")

  const handleExpand = (apiId) => {
    // console.log("api is",apiId)
    setExpandedApi(expandedApi === apiId ? null : apiId);
  };

  const handleToggle = (mode) => {
    setSelectMode(mode);
  };
  const downloadPDF = () => {

  };

  const handleSelectChange = (e) => {
    setSelectTag(e.target.value);
    setOpenModalBox(true);
  }
  const handleCloseModal = () => {
    setOpenModalBox(false);
    setSelectTag(''); //reset dropdown box to select
  }
  // console.log(openModalBox)
  const handleViewMore = () => {
    setOpenModalBox1(!openModalBox1)
  }
  const filterApiData = (data) => {
    // List of keys to remove
    const keysToRemove = [
      '_id', 'app_id', 'flow_id', 'user_id', 'run_id', 'raw_data_id', 'api'
    ];

    // Function to recursively remove keys from an object
    const removeKeys = (obj) => {
      return Object.keys(obj).reduce((acc, key) => {
        if (keysToRemove.includes(key)) return acc; // Skip unwanted keys

        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          acc[key] = removeKeys(obj[key]); // Recursively clean nested objects
        }
        else if (Array.isArray(obj[key])) {
          if (key === 'vc_value') {
            // Handle truncation for vc_value array
            acc[key] = obj[key].map(item => {
              if (typeof item === 'object' && item.value && typeof item.value === 'string') {
                // Truncate value if it exceeds 80 characters
                return {
                  ...item,
                  value: item.value.length > 80 ? item.value.substring(0, 80) + '...' : item.value
                };
              }
              return item; // Keep other items as they are
            });
          } else {
          acc[key] = obj[key].map(item => typeof item === 'object' ? removeKeys(item) : item); // Clean array items
          }
        }
        else {
          // Truncate long vc_value strings
          if (key === 'vc_value' && typeof obj[key] === 'string' && obj[key].length > 100) {
            acc[key] = obj[key].substring(0, 80) + '...'; // Truncate and add ellipses
          }
          else {
            acc[key] = obj[key];
          }
        }
        return acc;
      }, {});
    };
    return removeKeys(data);
  };

  const resetThreatModal = () => {
    fetchAPIs();
    setCWE('Threat Model');
    setIdsFlag(true);
    setCollectRawDataId([]);
  }

  const showReport = () => {
    console.log('Current IDs:', app_id, flowId, runId);
    console.log('Report IDs:', reportAppId, reportFlowId, reportRunId);

    if (app_id === reportAppId && flowId === reportFlowId && runId === reportRunId) {
      setReportApp(appname);
      setReportFlow(flowName);
      setReportRun(runName);
    }
    // console.log(reportApp,reportFlow,)
    navigate(`/application/${appname}/${flowName}/${runName}/report`)
  }
  // console.log(viewReport)
  return (
    <div className='prefuzzing-box'>
      <div className='head-title'>
        <div className='run-title' onClick={resetThreatModal} style={{ cursor: 'pointer', textDecoration: 'underline', textDecorationColor: '#FFCB2D' }}>{Name}</div>
        <div className='mode-selection'>
          <button id='toggleAPIs' className={`toggle-button ${selectMode === 'Security Risks' ? 'active' : ''}`} onClick={() => handleToggle('Security Risks')}>Security Risks</button>
          <button id='toggleAPIs' className={`toggle-button ${selectMode === 'APIs' ? 'active' : ''}`} onClick={() => handleToggle('APIs')}>APIs</button>
        </div>
        <div className='button-selection'>
          {/* { viewReport? <button className='context' style={{marginRight:"10px"}} onClick={showReport}>View Report</button>:""} */}
          <button className='context' style={{ marginRight: "10px" }} onClick={showReport}>View Report</button>
          {/* disabled={!viewReport} */}
          {/* raw data download here */}
          {/* <div className='rawDataExport'>
            <button style={{ backgroundColor: '#F6F6F6' }} className='raw'  >RAW Data</button>
            <img src={export1} alt='export' />
          </div> */}
          <button className='context' onClick={openModal1} >Confirm all Risks </button>
        </div>
      </div>
      {selectMode === 'Security Risks' ?
        <div>
          {displayItems?
          <section className='main-container-fuzzer'>
            <div className='prefuzzing-C1'> {renderCWEList()} </div>
            <div className='prefuzzing-C2'>
              <div className='data-collection'>
                <div className='fuzz-header'>
                  <div>{CWE}</div>
                  <div>
                    <button className='confirmFuzz' onClick={openModal} disabled={idsFlag}>Confirm Now</button>
                    {/* threat data download here */}
                    {/* <button className='downloadThreat' onClick={downloadPDF} style={{ borderRadius: '5px' }}>
                      <img src={download} alt='download' style={{ marginTop: '-2px' }} />
                    </button> */}
                  </div>
                </div>
                <div className='data-column'>
                  <div className='method-collection'>
                    <div className='apiCount'>
                      <p id='apiheader'>APIs</p>
                      <p id='apiheader-count'>{apiData.length}</p>
                    </div>
                    <div className='methodCount'>
                      <div>
                        <p id='method-get'>GET</p>
                        <p id='method-count'>{methodCount.GET}</p>
                      </div>
                      <img src={line} alt='bar' className='bar' />
                      <div>
                        <p id='method-post'>POST</p>
                        <p id='method-count'>{methodCount.POST}</p>
                      </div>
                      <img src={line} alt='bar' className='bar' />
                      <div>
                        <p id='method-put'>PUT</p>
                        <p id='method-count'>{methodCount.PUT}</p>
                      </div>
                      <img src={line} alt='bar' className='bar' />
                      <div>
                        <p id='method-delete'>DELETE</p>
                        <p id='method-count'>{methodCount.DELETE}</p>
                      </div>
                    </div>
                  </div>

                  <div className='placeholder-collection'>
                    <div className='apiCount'>
                      <p id='tagheader'>Tags</p>
                      <div className='viewmore-button' onClick={handleViewMore}>
                        {openModalBox1 || Object.entries(vcTagCounts).filter(([key, value]) => value > 0).length <= (minimize ? 3 : 4) ? '' : 'View More'}
                      </div>
                    </div>

                    {minimize ?
                      <div className='tagsCount'>
                        {Object.entries(vcTagCounts).filter(([key, value]) => value > 0).slice(0, 3).map(([tagType, count]) => (
                          <div className='tagsCount1' key={tagType}>
                            <p id='tg'>{tagType}</p><p id='tg-count'>{count}</p>
                          </div>

                        ))}
                      </div>
                      :
                      <div className='tagsCount'>
                        {Object.entries(vcTagCounts).filter(([key, value]) => value > 0).slice(0, 4).map(([tagType, count]) => (
                          <div className='tagsCount1' key={tagType}>
                            <p id='tg'>{tagType}</p><p id='tg-count'>{count}</p>

                          </div>
                        ))}
                      </div>

                    }
                    {openModalBox1 && (
                      //if view more button if enable
                      <CWETagsPopUp
                        show={openModalBox1}
                        onClose={() => setOpenModalBox1(false)}
                        data={Object.entries(vcTagCounts).filter(([key, value]) => value > 0)}
                      />
                    )}

                  </div>
                </div>
                <div className='filter'>
                  <p id='listOfUrl'>APIs</p>
                  <div className='filterToken'>
                    {/* <div id='token'>select</div> */}
                    <select id='token' value={selectTag} onChange={handleSelectChange}>
                      <option value=''>Tag Details</option>
                      {
                        Object.keys(vcTagCounts).map((tagType, idx) => (
                          <option key={idx} value={tagType}>{tagType}</option>
                        ))
                      }

                    </select>
                  </div>
                  {openModalBox && selectTag !== '' &&
                    <TagModalBox
                      tagType={selectTag}
                      tagDetails={vcTagDetails[selectTag]}
                      onClose={handleCloseModal}
                      count={vcTagCounts[selectTag]}
                    />}
                </div>
              </div>

              <div className='api-collection'>
                {apiData.length > 0 ? (
                  apiData.map((api, index) => (
                    <div key={index} className={`api-item ${expandedApi === api ? 'expanded' : ''}`}>
                      <button id='dropdownapi' onClick={() => handleExpand(api)} className={`toggle-button ${expandedApi === api ? 'active' : ''}`} >
                        <div>
                          {
                            expandedApi === api ?
                              <div> <img src={downArrow} alt='down' /></div>
                              :
                              <div><img src={arrowUp} alt='up' /></div>
                          }
                        </div>
                        {minimize ? (<div className='api-info'>
                          <span className='api-index'>{index + 1} </span>
                          <img src={line} alt='bar' className='apibar' />
                          <span className='api-url' title={api.api}>
                            {api.api.length > 82 ? `${api.api.substring(0, 82)}...` : api.api}
                          </span>
                        </div>) :
                          (<div className='api-info'>
                            <span className='api-index'>{index + 1}  </span>
                            <img src={line} alt='bar' className='apibar' />
                            <span className='api-url' title={api.api}>
                              {api.api.length > 100 ? `${api.api.substring(0, 100)}...` : api.api}
                            </span>
                          </div>)}

                      </button>
                      {expandedApi === api && (
                        <div className='api-details'>
                          <pre>{JSON.stringify(filterApiData(api), null, 2)}</pre>
                        </div>
                      )}
                    </div>
                  )))
                  : (<div>No data Found</div>)
                }
              </div>
            </div>
          </section>
          :
          <div style={{margin:'200px 400px 200px 400px'}}>
          <img src={dataNotFound} alt='data not found' />
        </div>
}

        </div>
        :
        (  <div className='blank-page' style={{margin:'200px 400px 200px 400px'}}>
          <img src={dataNotFound} alt='data not found' />
        </div>)
      }
    </div>
  )
}

export default PreProcessFuzzer
