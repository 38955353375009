// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nextPageDot {
  display: flex;
  justify-content: center;
}

.nextPageDot button {
  border: none;
  background-color: #fff;
}

.dot {
  padding: 2px;
  height: 12px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".nextPageDot {\n  display: flex;\n  justify-content: center;\n}\n\n.nextPageDot button {\n  border: none;\n  background-color: #fff;\n}\n\n.dot {\n  padding: 2px;\n  height: 12px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
