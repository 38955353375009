// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blur-background-run_application{
    position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   /* backdrop-filter: blur(2px); */
    background-color: #F6F6F3B2;
}

.run_application{
    
    position: absolute;
    top: 230px;
    left: 900px;
    width: 459px;
    height: 230px;
    border-radius: 24px;
    padding: 30px;
    padding-top: 20px;
    background-color: #FFFFFF;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.run-header{
    display: flex;
    justify-content: space-between;
}
.run-header p{
    font-family: 'DM Sans';
    font-size: 24px;
    font-weight: 600;
    line-height: 31.25px;
    letter-spacing: -1px;
    text-align: left;

}
.closeRun{
    border: none;
    background-color: #FFFFFF;
    cursor: pointer;
}

.input-run_app input{
    background-color: #F9F9F9;
    border: 1px solid #D1D5DB;
}

.run-now{
    background-color:#FFCB2D ;
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    margin-top: 40px;

}`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/PopUpUtils/Run_application.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;GACnB,MAAM;GACN,OAAO;GACP,WAAW;GACX,YAAY;GACZ,gCAAgC;IAC/B,2BAA2B;AAC/B;;AAEA;;IAEI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,yBAAyB;IACzB,yBAAyB;IACzB,0CAA0C;AAC9C;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,oBAAoB;IACpB,gBAAgB;;AAEpB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,gBAAgB;;AAEpB","sourcesContent":[".blur-background-run_application{\n    position: absolute;\n   top: 0;\n   left: 0;\n   width: 100%;\n   height: 100%;\n   /* backdrop-filter: blur(2px); */\n    background-color: #F6F6F3B2;\n}\n\n.run_application{\n    \n    position: absolute;\n    top: 230px;\n    left: 900px;\n    width: 459px;\n    height: 230px;\n    border-radius: 24px;\n    padding: 30px;\n    padding-top: 20px;\n    background-color: #FFFFFF;\n    border: 1px solid #f5f5f5;\n    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);\n}\n.run-header{\n    display: flex;\n    justify-content: space-between;\n}\n.run-header p{\n    font-family: 'DM Sans';\n    font-size: 24px;\n    font-weight: 600;\n    line-height: 31.25px;\n    letter-spacing: -1px;\n    text-align: left;\n\n}\n.closeRun{\n    border: none;\n    background-color: #FFFFFF;\n    cursor: pointer;\n}\n\n.input-run_app input{\n    background-color: #F9F9F9;\n    border: 1px solid #D1D5DB;\n}\n\n.run-now{\n    background-color:#FFCB2D ;\n    border: none;\n    border-radius: 10px;\n    width: 100%;\n    height: 40px;\n    margin-top: 40px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
