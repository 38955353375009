import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ReportChart = ({ statusCodeCounts }) => {
    // Convert statusCodeCounts to arrays for the chart
    const series = Object.values(statusCodeCounts);
    const labels = Object.keys(statusCodeCounts);

    // Define colors for specific status codes
    const statusCodeColors = {
        200: '#4CAF50', // Green for 200 OK
        201: '#8BC34A', //(Light Green) Created
        204: '#CDDC39',//(Lime) No Content
        206: '#00BCD4', // Cyan (Teal) for Partial Content 
        301: '#FFC107',//(Amber) moved Permanently
        302: '#FF9800',//(Orange) Found (Redirect)
        304: '#FFEB3B',//(Yellow) Not Modified
        400: '#FF5722',//(Deep Orange) Bad Request
        401: '#F44336',//(Red) Unauthorized
        403: '#E91E63',// (Pink) Forbidden
        404: '#9C27B0', //  (Purple) Not Found
        406: '#FF4500', //(orange red) not acceptable
        422: '#FF7043', //(coral)  Unprocessable Entity:
        500: '#F44336',//(Dark Red) Internal Server Error
        502: '#3F51B5',// (Indigo) Bad Gateway
        503: '#2196F3', // (Blue)  Service Unavailable
        533: '#2F4F4F',  //( Dark Slate Gray) Custom, often Load Balancer Timeout
    };

    // Map the labels to their respective colors
    const colors = labels.map(label => statusCodeColors[label] || '#CCCCCC'); // Default color if not specified

    const options = {
        chart: {
            width: 300,
            type: 'donut',
        },
        plotOptions: {
            pie: {
               
                startAngle: -90,
                endAngle: 270,
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'gradient',
        },    
        legend: {
            show: true,
            position: 'bottom',
            formatter: function (val, opts) {
                return `${val} : ${opts.w.globals.series[opts.seriesIndex]}`;
            },
        },
        labels: labels,
        colors: colors, // Assign the colors array here
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 50,
                },
                legend: {
                    position: 'bottom',
                },
            },
        }],
        
    };

    return (
        <div id="report-chart" >
            <ReactApexChart options={options} series={series} type="donut" width={300} height={250}/>
        </div>
    );
};

export default ReportChart;
