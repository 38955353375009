
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import '../../../Styles/DeveloperMode/FlowDB/listOfFlows.css';

import blackSetting from '../../../Images/Sorting (1).svg';
import '@fontsource/dm-sans'
import vector from '../../../Images/Vector.svg';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
import dataNotFound from '../../../Images/notDatafound.svg';
import ChoosePreference from '../PopUpUtils/ChoosePreference';

function ListOfFlows({ onRefreshToken, setOpenForm }) {
  const [workflows, setWorkflows] = useState([]);
  const navigate = useNavigate();
  const { appId } = useParams();
  const [viewAll, setViewAll] = useState(false)
  const { flowId, setFlowId } = useWorkflowDetails();
  const apiUrl = process.env.REACT_APP_URL;
  const { setFlowName } = useWorkflowDetails();
  const flowPerPage = 5;
  const { app_id, setApp_id } = useWorkflowDetails();
  const [displayItems, setDisplayItems] = useState(false);
  const [accessTokenUpdated, setAccessTokenUpdated] = useState('');


  useEffect(() => {
    const storedApp_id = localStorage.getItem('app_id');
    if (storedApp_id) {
      setApp_id(storedApp_id);
    }
  }, [setApp_id]);

  useEffect(() => {
    const storedFlowId = localStorage.getItem('flowId');
    if (storedFlowId) {
      setFlowId(storedFlowId);
    }
  }, [setFlowId]);

  const localToken = localStorage.getItem('accessToken');

  useEffect(() => {
    fetchFlows();
  }, [app_id]);

  useEffect(() => {
    if (accessTokenUpdated) {
      fetchFlows();
    }
  }, [accessTokenUpdated]);

  const fetchFlows = async () => {
    try {
      const response = await fetch(`${apiUrl}/resources/apps/${app_id}/flows`, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setWorkflows(data)
        setFlowName(data.flow_name)
        if (data && data.length > 0) {
          const hasFlowName = data.some(workflow => !!workflow.flow_name);
          setDisplayItems(hasFlowName);
        }
      } else if (response.status === 400) {
        const result = await onRefreshToken();
        if (result === "success") {
          console.log("refresh", result)
          setAccessTokenUpdated(localStorage.getItem('accessToken'));
        } else {
          navigate('/');
        }
      } else if (response.status === 401) {
        const result = await onRefreshToken();
        if (result === "success") {
          setAccessTokenUpdated(localStorage.getItem('accessToken'));
        } else {
          navigate('/');
        }
      }

    } catch (error) {
      console.error("error in fetching workflows of application", error)
    }
  }


  const handleWorkflowClick = (workflowId, flowName) => {
    setFlowId(workflowId)
    navigate(`/application/${appId}/${flowName}`);
  };


  const handleViewAll = () => {
    setViewAll(true);

  }

  const renderFlowItems = () => {
    if (!Array.isArray(workflows)) {
      return null;
    }

    const visibleFlow = viewAll ? workflows : workflows.slice(0, flowPerPage);

    return visibleFlow.map((workflow, index) => (
      <li className="workflow-item" key={index} onClick={() => handleWorkflowClick(workflow._id, workflow.flow_name)}>
        <div className="flow-item">
          <div className="flow-name">{workflow.flow_name}</div>
          <div className="role-based-scan">Admin | Regular User</div>
        </div>
        <div className="threat-list">
          <div className="scan">{workflow.total_threat_count} Occurrences</div>
          <img id='vectorArrow' src={vector} alt='sorting' />
        </div>
      </li>
    ));
  };

  const addNewFlow = () => {
    setOpenForm(true)
  }


  return (
    <div>
      <div>
        <div className='autoflow'>
          <div className={`workflow-title`}>
            <div style={{ paddingLeft: "10px" }}>Workflows</div>

            <div className='adddlow-img'>
              <button type='submit' className='addflow' style={{ marginRight: "10px" }} onClick={addNewFlow}>Add Workflow</button>
              <img className={`imgSetting`} src={blackSetting} alt="software" style={{ marginRight: "10px" }} />
            </div>
          </div>

          <div className={`list-container `} >
            <div className={`workflow-container `}>
              <div>
                {!displayItems ?
                  <div className='flowdata-not-found'>
                    <img src={dataNotFound} alt='data not found' />
                  </div> :
                  // <ul className="workflow-list">
                  //   <li className="left-column">{renderflowName()}</li>
                  //   <li className="right-column">{renderThreatCount()}</li>
                  // </ul>
                  <ul className="workflow-list">
                    {renderFlowItems()}
                  </ul>
                }
              </div>
            </div>
          </div>
        </div>
        {!viewAll && workflows.length > flowPerPage && (
          <div className='viewbutton'>
            <div></div>
            <button className='viewAll' style={{ cursor: "pointer" }} onClick={handleViewAll}>View All</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListOfFlows;
