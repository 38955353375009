import { useSnackbar } from "notistack";
import React from "react";
import {createContext,useContext,useState} from 'react';

const SnackbarContext =createContext();

export const SnackbarProvider =({children})=>{
    const {enqueueSnackbar,closeSnackbar}=useSnackbar();
    const showSnackbar =(message,options)=>{
        const snackbarId=enqueueSnackbar(message,{
            ...options,
            // persist:true,
            onExited:()=>{
                closeSnackbar(snackbarId);
            },
        });
    };

    const value={showSnackbar};
    return(
        <SnackbarContext.Provider value={value}>
            {children}
        </SnackbarContext.Provider>
    )
}

export const useSnackbarContext =()=> useContext(SnackbarContext);