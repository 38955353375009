// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -5px;
    /* width: 1204px; */
}
  
.route-content {
    display: flex;
    align-items: center; 
    margin-right: auto; 
}

#d1 {
    font-size: 14px;
    color: #1E1600;
    font-weight: regular;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    padding-bottom: 15px;
}

#d2 {
    font-size: 14px;
    color: #1E1600;
    font-weight: regular;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    padding-bottom: 15px;
}

.search-content {
    display: flex;
    align-items: center; 
}

.iconImg {
    padding-bottom: 15px;
    padding-left: 15px;
    width: 18px;
    height: 20px;
}


`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,oBAAoB;IACpB,gCAAgC;IAChC,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,oBAAoB;IACpB,gCAAgC;IAChC,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".navbar-content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 76px;\n    padding-left: 15px;\n    padding-right: 15px;\n    margin-top: -5px;\n    /* width: 1204px; */\n}\n  \n.route-content {\n    display: flex;\n    align-items: center; \n    margin-right: auto; \n}\n\n#d1 {\n    font-size: 14px;\n    color: #1E1600;\n    font-weight: regular;\n    font-family: 'Inter', sans-serif;\n    cursor: pointer;\n    padding-bottom: 15px;\n}\n\n#d2 {\n    font-size: 14px;\n    color: #1E1600;\n    font-weight: regular;\n    font-family: 'Inter', sans-serif;\n    cursor: pointer;\n    padding-bottom: 15px;\n}\n\n.search-content {\n    display: flex;\n    align-items: center; \n}\n\n.iconImg {\n    padding-bottom: 15px;\n    padding-left: 15px;\n    width: 18px;\n    height: 20px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
