import React, { useEffect, useState } from 'react';
import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
import { Link, useNavigate } from 'react-router-dom';
import '../../../Styles/DeveloperMode/AppDashboard/ListOfApplication.css';
import up from '../../../Images/up.svg';
import up1 from '../../../Images/up1.svg';
import down from '../../../Images/down1.svg';
import AppCVSSTrend from '../Graph_content/AppCVSSTrend';
import AppCWEMatric from '../Graph_content/AppCWEMatric';
import '../../../Styles/DeveloperMode/DeveloperMode.css';
import arrow from '../../../Images/Vector (2).svg';
import VerticalStepper from '../../CisoMode/VerticalStepper';

const ListOfApplication = ({ onRefreshToken, setAllAppNames, allAppNames }) => {
    const [activeItem, setActiveItem] = useState('9.5');
    const [applications, setApplications] = useState([]);
    const navigate = useNavigate();
    const { setApp_id } = useWorkflowDetails();
    const { setAppname } = useWorkflowDetails();
    const [displayItems, setDisplayItems] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [accessTokenUpdated, setAccessTokenUpdated] = useState('');
    const [refresh, setRefresh] = useState(false);

    const handleItemClick = (item) => {
        setActiveItem(item);
    }


    useEffect(() => {
        fetchApplications();
    }, [setApp_id, refresh]);

    useEffect(() => {
        if (accessTokenUpdated) {
            fetchApplications();
        }
    }, [accessTokenUpdated]);

    const apiUrl = process.env.REACT_APP_URL;
    const localToken = localStorage.getItem('accessToken');

    const fetchApplications = async () => {
        try {
            const response = await fetch(`${apiUrl}/resources/apps`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                }
            });

            if (response.ok) {
                const data = await response.json();
                const reverseData = data.reverse()
                setApplications(reverseData);
                setAllAppNames(reverseData);
                //check application is present or not
                const show_name = reverseData.some(data => data.app_name);
                setDisplayItems(show_name);
                setAllAppNames(data);
            } else if (response.status === 400) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            } else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            } else if (response.status === 404) {
                navigate('/');
            }

        } catch (error) {
            console.error('Error fetching applications:', error);
        }
    };

    // const handleApplicationClick = (appId, appname, url) => {
    //     setApp_id(appId);
    //     setAppname(appname);
    //     localStorage.setItem('url', url);
    //     setRefresh(prev => !prev);
    // };
    const handleApplicationClick = async (appId, appname, url) => {
        try {
            // Update the state immediately
            setApp_id(appId);
            setAppname(appname);
            localStorage.setItem('url', url);
    
            const response = await fetch(`${apiUrl}/resources/apps/${appId}/flows`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            });
    
            if (response.ok) {
                const data = await response.json();
                const hasFlowName = data.some(flow => flow.flow_name);
    
                // Ensure the state is set before navigating
                setRefresh(prev => !prev);
    
                if (hasFlowName) {
                    navigate(`/application/${appname}`);
                } else {
                    navigate('/add_flow');
                }
            } else if (response.status === 400 || response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            } else if (response.status === 404) {
                navigate('/');
            }
        } catch (error) {
            console.error('Error fetching flows:', error);
        }
    };
    

    const handleAddApplication = () => {
        navigate("/add_application");
    }

    const handleAddApp = () => {
        setOpenForm(true);
    }

    return (
        <div>
            {displayItems ? (
                <div className='applevel'>
                    <div className='applevel-row1'>
                        <div className={`col-1t ${activeItem === 'New Threat' ? 'active' : ''}`} onClick={() => handleItemClick('New Threat')}>
                            <p id='col-txt'> New Threats</p>
                            {/* <p id='col-num'>24
                               <img src={up} alt='up' style={{ padding: "5px", marginLeft: "5px" }} />
                            # </p> */}
                        </div>
                        <div className={`col-2t ${activeItem === 'Total Threat' ? 'active' : ''}`} onClick={() => handleItemClick('Total Threat')}>
                            <p id='col-txt'> Total Threats</p>
                            {/*  <p id='col-num'>24
                                <img src={down} alt='up' style={{ padding: "0px", marginLeft: "5px" }} />
                            </p>  */}
                        </div>
                        <div className={`col-3t ${activeItem === 'Resolved' ? 'active' : ''}`} onClick={() => handleItemClick('Resolved')}>
                            <p id='col-txt'> Resolved</p>
                            {/*} <p id='col-num'>24
                                <img src={up1} alt='up' style={{ padding: "0px", marginLeft: "5px" }} />
                            </p>  */}
                        </div>
                        <div className={`col-4t ${activeItem === 'Compliance Issues' ? 'active' : ''}`} onClick={() => handleItemClick('Compliance Issues')}>
                            <p id='col-txt'> Compliance Issues</p>
                            {/*} <p id='col-num'>24</p>  */}
                        </div>

                        <div className={`col-5t ${activeItem === '9.5' ? 'active' : ''}`} onClick={() => handleItemClick('9.5')}> </div>
                    </div>
                    <div className='applevel-row2'>Performance</div>
                    <div className='applevel-row3'>
                        <div className='row3-grp1'><AppCVSSTrend /> </div>
                        <div className='row3-grp2'><AppCWEMatric /></div>
                    </div>

                    <div className='applevel-row4' style={{ backgroundColor: "#F6F6F3" }}>
                        <div id='newApp'>
                            <div style={{ marginLeft: "10px", marginTop: "10px" }}> Applications</div>
                            <button style={{ padding: "8px", backgroundColor: "#fff", border: "none", borderRadius: "5px", cursor: "pointer" }} onClick={handleAddApplication}>Add Application</button>
                        </div>
                        <div className='appnames'>
                            <div className="app-grid">
                                {applications.map(application => (
                                    <div key={application._id}>
                                        {/* if only application name is present with no workflows */}
                                        {!application.OWASP_top_10_info && application.app_name ? (
                                            <div>
                                                <Link className="applist" to={`/add_flow`} onClick={() => handleApplicationClick(application._id, application.app_name, application.url)} >
                                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                                        {application.app_name} <img src={arrow} alt='arrow' style={{ marginRight: "10px",width:'15px' }} />
                                                    </div>
                                                    <div id='grp_count'>

                                                        <p id='noRuns' style={{fontSize:"12px",fontFamily:'DM Sans',display:'flex',justifyContent:'left',marginRight:'100px',color:'#4E4E4E'}}>Perform Runs</p>
                                                        {/* <pre id='Alist'>None</pre>
                                                        <pre id='Alist'>None</pre> */}
                                                        {/* <img src={arrow} alt='arrow' style={{ marginRight: "-10px" }} /> */}
                                                    </div>
                                                </Link>
                                            </div>
                                        ) : (
                                            // application names with workflows or data
                                            <div>
                                                <Link className="applist" to={`/application/${application.app_name}`} onClick={() => handleApplicationClick(application._id, application.app_name, application.url)} >
                                                    <div style={{display:'flex',justifyContent:'space-between'}}>
                                                        {application.app_name} <img src={arrow} alt='arrow' style={{ marginRight: "10px",width:'15px'  }} />
                                                    </div>
                                                    <div id='grp_count'>
                                                        {Object.entries(application.OWASP_top_10_info)
                                                            .filter(([key, value]) => value > 0).slice(0, 3)
                                                            .map(([key, value], index) => (
                                                                <pre key={index} id='Alist'>{`${key}(${value})`}</pre>
                                                            ))}
                                                        {/* {Object.entries(application.OWASP_top_10_info)
                                                            .filter(([key, value]) => value > 0)
                                                            .length === 1 && (
                                                                <pre id='Alist'>None</pre>
                                                            )
                                                        } */}

                                                        {/* <img src={arrow} alt='arrow' style={{ marginRight: "10px" }} /> */}
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='applevel2'>
                        <div id='addnewApp_d1'>To see Organisational Security Landscape please add Applications</div>
                        <div className='addnewApp'>
                            <button className='add_app_btn' onClick={handleAddApp}>Add Application</button>
                        </div>
                        {/* <button type='button' onClick={openform} >open</button>
                     {openForm?<CreateForm />: ""} */}
                    </div>
                    <div>
                        {openForm && <VerticalStepper setOpenForm={setOpenForm} allAppNames={allAppNames} />}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ListOfApplication;
