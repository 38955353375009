import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import '../../../Styles/DeveloperMode/Graph_content/CVSS-trends.css'

const CVSS_Trends = () => {
  const [options] = useState({
    chart: {
      type: 'area',
      toolbar:{
        show:false
      },
    },
    dataLabels: {
      enabled: false
    },

    legend: {
        show: true,
        labels: {
            colors: ['#FFCB2D', '#FF7917'] 
          },
        markers: {
            fillColors: ['#FFCB2D', '#FF7917']
          },
    },
  
    stroke: {
      curve: 'straight'
    },
    // title: {
    //   text: 'Area with Negative Values',
    //   align: 'left',
    //   style: {
    //     fontSize: '14px'
    //   }
    // },
   
    xaxis: {
      // type: 'datetime',
      labels: {
        show: false,  
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      tickAmount: 4,
      floating: false,
      labels: {
        style: {
          colors: '#8e8da4',
        },
        offsetY: -7,
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false
      }
    },
    colors:['#FFCB2D'],
    fill: {
      opacity: 0.5
    },
    tooltip: {
      x: {
        format: "yyyy",
      },
      fixed: {
        enabled: false,
        position: 'topRight'
      }
    },
    grid: {
      yaxis: {
        lines: {
          offsetX: -30
        }
      },
      padding: {
        left: 20
      },
    }
  });

  const [series] = useState([
    {
      name: 'CVSS-score',
      data: [
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 4, y: 0 },
        { x: 5, y: 0 },
        { x: 6, y: 0 },
        { x: 7, y: 0 },
        { x: 8, y: 0 },
        { x: 9, y: 0 },
        { x: 10, y: 0 }
      ]
    }
  ]);

  return (
    <div>
        <div className={`cvss-trend`}>Threat Metric</div>
      <div >
        <ReactApexChart options={options} series={series} type="area" height={210}  />
      </div>
    </div>
  );
};

export default CVSS_Trends;
