import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../../../Styles/DeveloperMode/PopUpUtils/ChoosePreference.css';
import upload from "../../../Images/export.svg"
import plugin from '../../../Images/maximize-3.svg'
import UploadScript from './UploadScript';
import UploadHAR from './UploadHAR';


const ChoosePreference = ({ setOpenForm,newflow }) => {
    const navigate = useNavigate();
    const [preference, setPreference] = useState(null);
    const [nextClicked, setNextClicked] = useState(false);
    const [closeScriptForm, setCloseScriptForm] = useState(false);
    const [inputUrl, setInputUrl] = useState('');
    const [isChrome,setIsChrome] =useState(false);
    const [message,setMessage] =useState('');

    useEffect(() => {
        // Load URL from local storage 
        const savedUrl = localStorage.getItem('url');
        if (savedUrl) {
            setInputUrl(savedUrl);
        }
    }, []);

    useEffect(()=>{
        const validBrowser=navigator.userAgent.toLowerCase();
        if(validBrowser.indexOf('chrome')> -1){
            setIsChrome(!isChrome);
        }else{
            setIsChrome(isChrome);
        }
    },[]);

    const handlePreferenceSelection = (selectedRole) => {
        setPreference(selectedRole);
        setNextClicked(false); // Reset nextClicked when preference changes
        setMessage('')
    }

    const handleNextClick = () => {
        if (preference) {
            setNextClicked(true);
            setCloseScriptForm(!closeScriptForm);
            if (preference === 'runPlugin' && isChrome) {
                const normalizedUrl = normalizeUrl(inputUrl);
                localStorage.setItem('url', normalizedUrl); // Save URL to local storage
                openNewTab(normalizedUrl);
                setTimeout(() => {
                    navigate('/dashboard');
                }, 300)

            }else if (preference === 'runPlugin' && !isChrome){
                setMessage('Plugin is only suitable for chrome browser.')
            }
           
        }else{
            
        }
    }

    
    const handleBackClick = () => {
        setOpenForm(false)
    }

    const normalizeUrl = (inputUrl) => {
        let normalizedUrl = inputUrl.trim();
        if (!/^https?:\/\//i.test(normalizedUrl)) {
            normalizedUrl = 'https://' + normalizedUrl;
        }
        normalizedUrl = normalizedUrl.replace(/^www\./i, '');
        return normalizedUrl;
    };

    const openNewTab = (url) => {
        setTimeout(() => {
            window.open(url, '_blank');
        }, 300); // Add delay for transition effect
    };

    return (
        <div className='prefenceBackground'>
            <div className='choosePreference'>
                <div id='prehead'>Choose Your Preference</div>
                <Stack spacing={2} direction="row" className='preferenceWise'>
                    <button
                        style={{ border: preference === 'uploadHAR' ? 'none' : '', backgroundColor: preference === 'uploadHAR' ? '#FFD042' : 'initial', color: preference === 'uploadHAR' ? 'black' : 'initial' }}
                        className='uploadHAR'
                        onClick={() => handlePreferenceSelection('uploadHAR')}
                    >
                        <img src={upload} alt='upload' />
                        <p style={{ margin: '0px', padding: '5px' }}>Upload HTTP Archive file</p>
                    </button>
                    <button
                        style={{ border: preference === 'rawData' ? 'none' : '', backgroundColor: preference === 'rawData' ? '#FFD042' : 'initial', color: preference === 'rawData' ? 'black' : 'initial' }}
                        className='rawData'
                        onClick={() => handlePreferenceSelection('rawData')}
                    >
                        <img src={upload} alt='upload' />
                        <p style={{margin:"5px 15px"}}>Upload Raw data</p>
                    </button>
                    <button
                        style={{ border: preference === 'runPlugin' ? 'none' : '', backgroundColor: preference === 'runPlugin' ? '#FFD042' : 'initial', color: preference === 'runPlugin' ? 'black' : 'initial' }}
                        className='runPlugin'
                        onClick={() => handlePreferenceSelection('runPlugin')}
                    >
                        <img src={plugin} alt='plugin' />
                        Run plugin in Chrome browser
                    </button>
                </Stack>
                <div className='choose-btn'>
                    <button
                        className='choose-back'
                        style={{ backgroundColor: 'initial', color: 'initial', cursor: "pointer", border: '1px solid' }}
                        onClick={handleBackClick}
                    >
                        Back
                    </button>
                    <button
                        className='choose-nxt'
                        style={{ border: nextClicked ? 'none' : 'none', backgroundColor: nextClicked ? '#FFD042' : '#FFD042', color: nextClicked ? 'black' : 'initial', cursor: "pointer" }}

                        onClick={handleNextClick}
                    >
                        Next
                    </button>
                   
                </div>
                {!isChrome? <p style={{color:'red',display:'flex',justifyContent:'center',marginBottom:'0px'}}>{message}</p>:''}
            </div>
            {closeScriptForm && nextClicked && preference === 'uploadHAR' ? <UploadHAR setCloseScriptForm={setCloseScriptForm} newflow={newflow} setOpenForm={setOpenForm} /> : closeScriptForm && nextClicked && preference === 'rawData' ? <UploadScript setCloseScriptForm={setCloseScriptForm} setOpenForm={setOpenForm}/> : null}
        </div>
    )
}

export default ChoosePreference;
