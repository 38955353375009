import React from 'react';
import '../../../Styles/DeveloperMode/PopUpUtils/TagModalBox.css';
import close from '../../../Images/close.svg';

const TagModalBox = ({ tagType, tagDetails, onClose, count }) => {
    // console.log(JSON.stringify(tagDetails));

    // Filter and group tag details ensuring uniqueness for both keys and values
    const groupedTagDetails = tagDetails.reduce((acc, tagDetail) => {
        const lowaerCaseKeys = tagDetail.key.toLowerCase().replace(':', ''); // Normalize key to lowercase
        if (!acc[lowaerCaseKeys]) {
            acc[lowaerCaseKeys] = new Set(); // Ensuring unique keys with Set for values
        }
        
        if (lowaerCaseKeys === 'cookie' && Array.isArray(tagDetail.value)) {
            // Add only unique key-value pairs for 'cookie'
            tagDetail.value.forEach(cookieDetail => {
                const cookieEntry = `${cookieDetail.key}:${cookieDetail.value}`;
                acc[lowaerCaseKeys].add(cookieEntry); // Unique based on key:value pair
            });
        } else {
            // Add non-cookie value directly if unique
            acc[lowaerCaseKeys].add(tagDetail.value);
        }

        return acc;
    }, {});

    // Function to render cookie key-value pairs as a nested table
    const renderCookieTable = (cookieDetails) => (
        <table className='cookie-table'>
            <thead>
                <tr>
                    <th>Cookie Key</th>
                    <th>Cookie Value</th>
                </tr>
            </thead>
            <tbody>
                {cookieDetails.map((cookie, idx) => {
                    // console.log(JSON.stringify(cookie))
                     const [cookieKey, cookieValue] =cookie.split(/:(.+)/);
                    return (
                        <tr key={idx}>
                            <td>{cookieKey}</td>
                            <td className='cookie-wrap'>{cookieValue}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );

    return (
        <div className='modal-overlay'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h2>{tagType}: {count}</h2>
                    <img src={close} alt='close' onClick={onClose} className='close-button' />
                </div>
                <div className='modal-body'>
                    <table className='tag-table'>
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(groupedTagDetails).map(([key, values], idx) => (
                                key === 'cookie' ? (
                                    <React.Fragment key={idx}>
                                        <tr>
                                        <td>{key}</td>
                                            <td colSpan="2">
                                                {renderCookieTable([...values])}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ) : (
                                    // For non-Cookie keys, render each value in a separate row
                                    [...values].map((value, valueIdx) => (
                                        <tr key={valueIdx}>
                                            {valueIdx === 0 && (
                                                <td rowSpan={[...values].length}>{key}</td>
                                            )}
                                            <td>{value}</td>
                                        </tr>
                                    ))
                                )
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TagModalBox;


