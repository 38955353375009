import React from 'react';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import MainContent from '../MainContent/MainContent';
import Navbar from '../MainContent/Navbar';
import '../../../Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import MinimizeLeftBar from '../LeftSidebar/MinimizeLeftBar';

const AppDashboard = ({ onLogout,onRefreshToken,accessToken, minimize, setMinimize }) => {
    return (
        <div className='appDashboard'>
            <div className='sidebar'>
            {minimize ?  <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}
            </div>
            <div className='content'>
            <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                    <Navbar minimize={minimize}/>
                </div>
                <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
                    <MainContent onRefreshToken={onRefreshToken} accessToken={accessToken} />
                </div>
            </div>
        </div>
    );
}

export default AppDashboard;
