// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all_flows {
    margin:20px 0px 0px 0px ;
    box-sizing: border-box;
}

.all_report {
    margin:30px 0px 0px 0px ;
    box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/AppDashboard/FlowsContainer.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,wBAAwB;IACxB,sBAAsB;AAC1B","sourcesContent":[".all_flows {\n    margin:20px 0px 0px 0px ;\n    box-sizing: border-box;\n}\n\n.all_report {\n    margin:30px 0px 0px 0px ;\n    box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
