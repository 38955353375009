import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../Styles/UserAuthentication/SignUp.css';
import logo from '../../Images/Group 1000004408.svg';
import '@fontsource/dm-sans';
import eye from '../../Images/eye.svg';

const SignUp = ({ setFormData }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [Status, setStatus] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [inputValidation, setInputValidation] = useState({
        firstName: true,
        lastName: true,
        email: true,
        password: true,
        confirmPassword: true
    });

    const [passwordRequirements, setPasswordRequirements] = useState({
        lengthRequirement: false,
        lowerCaseRequirement: false,
        upperCaseRequirement: false,
        numberRequirement: false,
        specialCharRequirement: false,
    });
    const navigate = useNavigate();
    const [errorMessage,setErrorMessage]=useState('');
    const validatePassword = (password) => {
        const lengthRequirement = /.{12,32}/;
        const lowerCaseRequirement = /[a-z]/;
        const upperCaseRequirement = /[A-Z]/;
        const numberRequirement = /[0-9]/;
        const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/;
        const newRequirements = {
            lengthRequirement: lengthRequirement.test(password),
            lowerCaseRequirement: lowerCaseRequirement.test(password),
            upperCaseRequirement: upperCaseRequirement.test(password),
            numberRequirement: numberRequirement.test(password),
            specialCharRequirement: specialCharRequirement.test(password),
        };

        setPasswordRequirements(newRequirements);

        return Object.values(newRequirements).every(req => req);
    };

    const validateName=(name)=>{
        const lengthRequirement = name.length >= 1 && name.length <= 30;
        const specialCharRequirement = /^[a-zA-Z]+$/.test(name);
        // return lengthRequirement && specialCharRequirement;
        if (!lengthRequirement || !specialCharRequirement) {
            return 'Username should not contain special characters';
        }

        return '';

    }
    const handleFirstNameChange = (e) => {
        const newFirstName = e.target.value;
        setFirstName(newFirstName);
        const firstNameError = validateName(newFirstName);

        setInputValidation((prevState) => ({...prevState,firstName: !firstNameError,}));
        if (!firstNameError && !validateName(lastName)) {
            setErrorMessage('');
        } else {
            setErrorMessage(firstNameError);
        }
    };

    // special charecter present --> print message 
    // special charecter remove --> remove print message
    const handleLastNameChange = (e) => {
        const newLastName = e.target.value;
        setLastName(newLastName);
        const lastNameError = validateName(newLastName);
        setInputValidation((prevState) => ({...prevState,lastName: !lastNameError,}));
        if (!lastNameError && !validateName(firstName)) {
            setErrorMessage('');
        } else {
            setErrorMessage(lastNameError);
        }
    };

    const formSubmit = async (e) => {
        e.preventDefault();
        const firstNameError =validateName(firstName);
        const lastNameError =validateName(lastName);
        const newInputValidation = {
            //converting variable into a boolean 
            //if empty then false 
            // if input box is not empty then true
            firstName: !firstNameError,
            lastName: !lastNameError,
            email: !!email && email.length <= 320,
            password: !!password && password.length >= 12 && password.length <= 32,
            confirmPassword: !!confirmPassword
        };
       

        setInputValidation(newInputValidation);
        setErrorMessage(firstNameError || lastNameError);
        //check condition if false then return
        if (Object.values(newInputValidation).includes(false)) {
            return;
        }

        if (password !== confirmPassword) {
            setValidationMessage('Passwords do not match.');
            setStatus('error');
            return;
        }

        if (!validatePassword(password)) {
            setValidationMessage('');
            setStatus('error');
            return;
        }

        const userData = {
            firstName,
            lastName,
            email,
            password,
        };

        setFormData(userData);
        navigate('/role');
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    return (
        <div className='signup'>
            <img src={logo} alt='logo' />
            <div className='signupForm'>
                <form>
                    <p id='signup-txt'>Sign Up</p>
                    <div className='form-details'>
                        <label htmlFor='userName'>Username <span style={{ color: 'red' }} >*</span></label>
                        <div className='username'>
                            <input
                                type='text'
                                id='firstName'
                                name='firstName'
                                value={firstName}
                                placeholder='First name'
                                onChange={handleFirstNameChange}
                                className={inputValidation.firstName ? '' : 'error'}
                                required
                            />
                        
                            <input
                                type='text'
                                id='lastName'
                                name='lastName'
                                value={lastName}
                                placeholder='Last name'
                                onChange={handleLastNameChange}
                                className={inputValidation.lastName ? '' : 'error'}
                                required
                            />
                            
                        </div>
                    </div>
                  
                    <div className='form-details'>
                        <label htmlFor='email'>Your email <span style={{ color: 'red' }} >*</span></label>
                        <input
                            type='email'
                            id='email'
                            name='email'
                            value={email}
                            placeholder='Enter email'
                            onChange={(e) => setEmail(e.target.value)}
                            className={inputValidation.email ? '' : 'error'}
                            required
                        />
                    </div>
                    <div className='form-details'>
                        <label htmlFor='pass'>Password <span style={{ color: 'red' }} >*</span></label>
                      
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id='pass'
                            name='pass'
                            value={password}
                            placeholder='******'
                            onChange={(e) => {
                                setPassword(e.target.value);
                                validatePassword(e.target.value);
                            }}
                            className={inputValidation.password ? '' : 'error'}
                            required
                        />

                        <img
                            src={eye}
                            alt='Show/Hide Password'
                            style={{ transform: 'translateY(-50%)', cursor: 'pointer', width: "15px", marginLeft: "440px", marginTop: "-20px", height: "15px" }}
                            onClick={toggleShowPassword}
                            className='eye'
                        />
                    </div>
                    <div className='form-details1'>
                        <label htmlFor='confirmPass'>Confirm Password <span style={{ color: 'red' }} >*</span></label>
                        <input
                            type={showPassword1 ? 'text' : 'password'}
                            id='confirmPass'
                            name='confirmPass'
                            value={confirmPassword}
                            placeholder='******'
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={inputValidation.confirmPassword ? '' : 'error'}
                            required
                        />
                        <img
                            src={eye}
                            alt='Show/Hide Password'
                            style={{ transform: 'translateY(-50%)', cursor: 'pointer', width: "15px", marginLeft: "440px", marginTop: "-20px", height: "15px" }}
                            onClick={toggleShowPassword1}
                            className='eye'
                        />
                    </div>
                    <div>
                        {Status === 'error' && (
                            <span style={{ color: 'black' }}>{validationMessage}</span>
                        )}
                    </div>
                  
                    <div className='message_shown'>
                        <div id='message'>Your password must meet the following requirements:</div>
                        <section className='message_row1'>
                            <p>
                                <span className={`dot ${passwordRequirements.lowerCaseRequirement ? 'green-dot' : 'red-hollow-dot'}`}></span>
                                At least 1 lowercase letter
                            </p>
                            <p>
                                <span className={`dot ${passwordRequirements.upperCaseRequirement ? 'green-dot' : 'red-hollow-dot'}`}></span>
                                At least 1 uppercase letter
                            </p>
                            <p>
                                <span className={`dot ${passwordRequirements.numberRequirement ? 'green-dot' : 'red-hollow-dot'}`}></span>
                                At least 1 number
                            </p>
                        </section>
                        <section className='message_row2'>
                            <p>
                                <span className={`dot ${passwordRequirements.specialCharRequirement ? 'green-dot' : 'red-hollow-dot'}`}></span>
                                At least 1 special character
                            </p>
                            <p>
                                <span className={`dot ${passwordRequirements.lengthRequirement ? 'green-dot' : 'red-hollow-dot'}`}></span>
                                At least 12 characters long
                            </p>
                        </section>
                    </div>
                    {errorMessage && (
                    <p className='error-message' style={{marginBottom:'-30px',color:'red'}}>Username should not contains special characters</p>
                    )}
                  
                    <div className='wrap1'>
                        <button type='submit' className='btn1' onClick={formSubmit}>
                            Next
                        </button>
                    </div>
                    <p>
                        Already have an account? <Link to='/' style={{ color: "black", fontWeight: "bold" }}>Login</Link>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default SignUp;


